import { PostJobEditable, PostJobNavigation } from 'components/post-job';
import EditablePreferences from 'components/post-job/PostJobEditable/EditablePreferences';
import EditableResumeChannel from 'components/post-job/PostJobEditable/EditableResumeChannel';
import EditableSalary from 'components/post-job/PostJobEditable/EditableSalary';
import EditableSkills from 'components/post-job/PostJobEditable/EditableSkills';
import EditableTeam from 'components/post-job/PostJobEditable/EditableTeam';
import { JOB_FUNCTIONS, JOB_TYPES, SENIORITY_LEVELS, WORKSPACE_TYPES } from 'constants/postJob';
import { useCompanies, usePostJob } from 'hooks/usePostJob';
import { PostJobData, PostJobItem, PostJobStep } from 'types/post-job';
import { mapToAutoCompleteItem, mapToAutoCompleteList, mapToCompany } from 'utils/postJob';

import './postJob.scss';
import { Divider } from '.';

function PostJobReview() {
  const { jobData, companyData, updateCompanyData, updateJobData, updateStepIndex } = usePostJob();
  const { data: companyList, error, isLoading } = useCompanies();

  console.log(jobData);

  const onUpdateJobData = (key: keyof PostJobData, data: PostJobItem | string | null) => {
    if (!jobData) return;
    updateJobData({ ...jobData, [key]: data });
  };

  return (
    <div className="post-job-review">
      <h1 className="post-job-title">Review the Job Post</h1>

      <Divider />
      <h6 className="post-job-heading">Job Details</h6>

      <PostJobEditable
        label="Company Name"
        list={companyList ? companyList.map(mapToAutoCompleteItem) : []}
        item={companyData ? mapToAutoCompleteItem(companyData) : null}
        onSaveItem={(item) => updateCompanyData(item ? mapToCompany(item) : null)}
      />

      <PostJobEditable
        label="Job Title"
        text={jobData?.jobTitle}
        onSaveText={(value) => onUpdateJobData('jobTitle', value)}
      />

      <PostJobEditable
        label="Location"
        text={jobData?.location}
        onSaveText={(value) => onUpdateJobData('location', value)}
      />

      <PostJobEditable
        label="Job Function"
        list={mapToAutoCompleteList(JOB_FUNCTIONS)}
        item={jobData?.jobFunction}
        onSaveItem={(item) => onUpdateJobData('jobFunction', item)}
      />

      <PostJobEditable
        label="Workspace Type"
        list={mapToAutoCompleteList(WORKSPACE_TYPES)}
        item={jobData?.workspaceType}
        onSaveItem={(item) => onUpdateJobData('workspaceType', item)}
      />

      <PostJobEditable
        label="Job Type"
        list={mapToAutoCompleteList(JOB_TYPES)}
        item={jobData?.jobType}
        onSaveItem={(item) => onUpdateJobData('jobType', item)}
      />

      <Divider />

      <EditableSkills />

      <PostJobEditable
        label="Seniority Levels"
        list={mapToAutoCompleteList(SENIORITY_LEVELS)}
        item={jobData?.seniorityLevel}
        onSaveItem={(item) => onUpdateJobData('seniorityLevel', item)}
      />

      <EditableSalary />

      <Divider />

      <PostJobEditable
        label="Job Description"
        text={jobData?.description}
        onSaveText={(value) => onUpdateJobData('description', value)}
        multiline
      />

      <Divider />
      <EditableResumeChannel />

      <Divider />
      <EditableTeam />

      <Divider />
      <EditablePreferences />

      <PostJobNavigation onBack={() => updateStepIndex(PostJobStep.JOB_DESCRIPTION)} />
    </div>
  );
}

export default PostJobReview;
