import { EditableText } from '@blueprintjs/core';
import { AutoComplete } from 'components/common';
import { CURRENCIES, SALARY_TYPES } from 'constants/postJob';
import { usePostJob } from 'hooks/usePostJob';
import { useMemo, useState } from 'react';
import { mapToAutoCompleteList } from 'utils/postJob';
import EditableContainer from './EditableContainer';

function EditableSalary() {
  const { jobData, updateJobData } = usePostJob();

  const [initMaxSalary, initSalaryType, initCurrency] = useMemo(() => {
    if (!jobData) return ['', null, null];

    return [
      jobData.maxSalary ? jobData.maxSalary.toString() : '',
      jobData.salaryType ?? null,
      jobData.currency ?? null
    ];
  }, [jobData]);

  const [maxSalary, setMaxSalary] = useState(initMaxSalary);
  const [salaryType, setSalaryType] = useState(initSalaryType);
  const [currency, setCurrency] = useState(initCurrency);

  const handleCancel = () => {
    setMaxSalary(initMaxSalary);
    setSalaryType(initSalaryType);
    setCurrency(initCurrency);
  };

  const handleSave = () => {
    if (!jobData) return;

    updateJobData({
      ...jobData,
      maxSalary: Number(maxSalary),
      salaryType: salaryType ?? undefined,
      currency: currency ?? undefined
    });
  };

  const renderContent = (isEditMode: boolean) => {
    return (
      <div className="editable-row editable-salary">
        <div>
          <label className="editable-label">Max Salary</label>

          <EditableText
            type="number"
            className="editable-value"
            value={maxSalary}
            onChange={setMaxSalary}
            disabled={!isEditMode}
            isEditing={isEditMode}
            placeholder={isEditMode || Boolean(initMaxSalary) ? '' : 'None'}
          />
        </div>

        <div>
          <label className="editable-label">Salary Type</label>

          {isEditMode ? (
            <AutoComplete
              placeholder=""
              list={mapToAutoCompleteList(SALARY_TYPES)}
              selectedItem={salaryType}
              updateSelectedItem={setSalaryType}
              // error={isSubmitted && !salaryType ? 'Missing salary type' : undefined}
            />
          ) : (
            <span className="editable-value">{salaryType?.value || 'None'}</span>
          )}
        </div>

        <div>
          <label className="editable-label">Currency</label>

          {isEditMode ? (
            <AutoComplete
              placeholder=""
              list={mapToAutoCompleteList(CURRENCIES)}
              selectedItem={currency}
              updateSelectedItem={setCurrency}
              // error={isSubmitted && !currency ? 'Missing currency' : undefined}
            />
          ) : (
            <span className="editable-value">{currency?.value || 'None'}</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <EditableContainer onCancel={handleCancel} onSave={handleSave} renderContent={renderContent} />
  );
}

export default EditableSalary;
