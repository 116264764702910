import React from 'react';
import { useTranslation } from 'react-i18next';

import { Talent } from 'types/talent';
import { SocialLinks, TagsCollection } from '@brightsource/brightsource-ui-lib';
import { MailIcon, PhoneIcon } from 'components/svg';
import { isBase64 } from 'utils';
import EducationCard from './EducationCard';
import ExperienceCard from './ExperienceCard';

import './profileTab.scss';

export interface ProfileTabProps {
  talent: Talent;
}

export function ProfileTab({ talent }: ProfileTabProps) {
  const { t } = useTranslation();

  return (
    <>
      {talent.about ? (
        <div className="talent-card-tab profile-tab">
          <div className="talent-card-tab-head">
            <h3 className="talent-card-tab-head-title">{t('talent.profile.about', 'Summary')}</h3>
          </div>
          <p className="about-text">
            {isBase64(talent.about)
              ? decodeURIComponent(window.escape(window.atob(talent.about)))
              : talent.about}
          </p>
        </div>
      ) : null}
      <ExperienceCard talent={talent} />
      <EducationCard talent={talent} />
      <div className="talent-card-tab">
        <div className="talent-card-tab-head">
          <h3 className="talent-card-tab-head-title">
            {t('talent.profile.languages', 'Languages')}
          </h3>
        </div>
        {talent.languages?.length ? (
          <TagsCollection items={talent.languages} removable={false} />
        ) : (
          '-'
        )}
      </div>
      <div className="talent-card-tab">
        <div className="talent-card-tab-head">
          <h3 className="talent-card-tab-head-title">{t('talent.profile.skills', 'Skills')} </h3>
        </div>
        {talent.skills?.length ? <TagsCollection items={talent.skills} removable={false} /> : '-'}
      </div>
      <div className="talent-card-tab">
        <div className="talent-card-tab-head">
          <h3 className="talent-card-tab-head-title">{t('talent.profile.contacts', 'Contacts')}</h3>
        </div>
        {talent.email ? (
          <div className="row">
            <MailIcon /> {talent.email}
          </div>
        ) : null}
        {talent.phone?.number ? (
          <div className="row">
            <PhoneIcon />
            <span>
              {talent.phone?.dialCode} - {talent.phone?.number}
            </span>
          </div>
        ) : null}
        <SocialLinks editable={false} links={talent.networks} />
      </div>
    </>
  );
}
