import axios, { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { VARS } from 'constants/config';

const axiosClient = axios.create({
  baseURL: VARS.serverUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const customHeaders = {} as AxiosRequestHeaders;

    // const accessToken = localStorage.getItem('access_token');
    // if (accessToken) {
    //   customHeaders.Authorization = `Bearer ${accessToken}`;
    // }

    // temp
    customHeaders.Authorization = `Bearer ${VARS.demoToken}`;

    return {
      ...config,
      headers: {
        ...customHeaders, // auto attach token
        ...config.headers // can override for some requests
      } as AxiosRequestHeaders
    };
  },

  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
