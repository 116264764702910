import {
  PostJobCompanyData,
  PostJobPayload,
  PostJobUserData,
  PostJobUserValidate,
  PostJobUserVerify
} from 'types/post-job';
import axiosClient from './axiosClient';

const postJobApi = {
  create(data: PostJobPayload) {
    return axiosClient.post('/post-job', data);
  },

  validateAccount(data: PostJobUserValidate) {
    return axiosClient.post('/post-job/account/validate', data);
  },

  verifyAccount(data: PostJobUserVerify) {
    return axiosClient.post('/post-job/account/verify', data);
  },

  createAccount(data: PostJobUserData) {
    return axiosClient.post('/post-job/account', data);
  },

  resendCode(email?: string): Promise<{ code: string }> {
    return axiosClient.get('/post-job/account/resend-code', {
      params: { email }
    });
  },

  getCompanies(): Promise<PostJobCompanyData[]> {
    return axiosClient.get('/companies');
  },

  createCompany(data: PostJobCompanyData) {
    return axiosClient.post('/companies', data);
  }
};

export default postJobApi;
