import React from 'react';
import { t } from 'i18next';
import { EditableControlValue } from '@brightsource/brightsource-ui-lib';

import { Company } from '../../../../types/company';

interface ContactTabProps {
  company?: Company;
}

function ContactTab({ company }: ContactTabProps) {
  return (
    <div className="settings-card-tab">
      <div className="settings-card-tab-inner">
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.contact.email', 'Email')}
            value={company?.email || ''}
            onEdit={() => console.log('edit email')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.contact.phone', 'Phone Number')}
            value={`${company?.phone?.dialCode ? company.phone.dialCode : ''}${
              company?.phone?.number ? company.phone.number : ''
            }`}
            onEdit={() => console.log('edit phone')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.contact.website', 'Website')}
            value={company?.networks?.website || ''}
            onEdit={() => console.log('edit website')}
          />
        </div>
      </div>
    </div>
  );
}

export default ContactTab;
