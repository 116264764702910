import { Button, Collapse, Divider } from '@blueprintjs/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoComplete, AutoCompleteItem } from 'components/common';
import { InputField } from 'components/form';
import { AccountIcon, LockIcon, UnlockIcon } from 'components/svg';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { PostJobMember } from 'types/post-job';
import { formSchema } from 'utils/validation';
import * as yup from 'yup';

import './postJobTeam.scss';

const ROLE_LIST = [
  { id: 1, value: 'Hiring manager' },
  { id: 2, value: 'Recruiter' },
  { id: 3, value: 'Coordinator' }
];

export interface PostJobTeamProps {
  jobMembers: PostJobMember[];
  setMembers: React.Dispatch<React.SetStateAction<PostJobMember[]>>;
}

function PostJobTeam({ jobMembers, setMembers }: PostJobTeamProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<AutoCompleteItem | null>(null);

  const onChangeRole = (item: AutoCompleteItem | null, memberId: number | string) => {
    if (!item) return;

    const newMemberList = jobMembers.map((member) => {
      if (member.id === memberId) return { ...member, role: item.value };
      return member;
    });

    setMembers(newMemberList);
  };

  const onRemoveMember = (memberId: number | string) => {
    const newMemberList = jobMembers.filter((member) => member.id !== memberId);
    setMembers(newMemberList);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitted }
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: formSchema.name,
        email: formSchema.email
      })
    )
  });

  const handleAddMember = () => {
    handleSubmit((data) => {
      if (!selectedRole) return;

      const newMember = {
        name: data.name,
        email: data.email,
        id: Math.floor(Math.random() * 100),
        role: selectedRole.value
      };

      setMembers([newMember, ...jobMembers]);
      reset();
    })();
  };

  const renderContent = () => {
    return (
      <div className="post-job-team">
        <div className="team-row">
          <InputField label="Name" name="name" control={control} placeholder="Hau" />

          <InputField
            label="Email"
            name="email"
            control={control}
            placeholder="hau@brightsource.com"
          />

          <AutoComplete
            label="Role"
            list={ROLE_LIST}
            selectedItem={selectedRole}
            updateSelectedItem={setSelectedRole}
            placeholder="Recruiter"
            error={isSubmitted && !selectedRole ? 'Missing role' : undefined}
          />

          <Button minimal onClick={handleAddMember} className="team-add">
            Add
          </Button>
        </div>

        {/* {jobMembers.length > 0 && <Divider />} */}

        {jobMembers.map((member) => (
          <div key={member.id} className="team-row">
            <span className="team-field">
              <AccountIcon /> {member.name}
            </span>

            <span className="team-field">{member.email}</span>

            <AutoComplete
              list={ROLE_LIST}
              selectedItem={{ id: member.id, value: member.role }}
              updateSelectedItem={(item) => onChangeRole(item, member.id)}
              onRemove={() => onRemoveMember(member.id)}
              disabledSearch
              placeholder=""
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="post-job-toggle">
        <h6 className="post-job-heading">
          Team
          {isOpen ? <UnlockIcon /> : <LockIcon />}
        </h6>

        <Button
          minimal
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsOpen((x) => !x)}
        />
      </div>

      <Collapse isOpen={isOpen} keepChildrenMounted>
        {renderContent()}
      </Collapse>
    </React.Fragment>
  );
}

export default PostJobTeam;
