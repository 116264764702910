import { Header } from 'components/common';
import { ScrollToTop, ToastWrapper } from 'components/wrapper';
import ContextProvider from 'context';
import { ReactNode } from 'react';

export function MainLayout({ children }: { children: ReactNode }) {
  return (
    <ContextProvider>
      <ScrollToTop>
        <div className="main-frame">
          <Header />
          {children}
        </div>
      </ScrollToTop>

      <ToastWrapper />
    </ContextProvider>
  );
}
