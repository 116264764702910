import { Progressbar } from '@brightsource/brightsource-ui-lib';
import AssistantAvatar from 'assets/images/assistant-avatar.png';
import {
  PostJobAdvance,
  PostJobBasic,
  PostJobCompany,
  PostJobDescription,
  PostJobNavigation,
  PostJobUser,
  PostJobVerification
} from 'components/post-job';
import { usePostJob } from 'hooks/usePostJob';
import { PostJobStep, PostJobVerificationModal } from 'types/post-job';
import PostJobReview from './PostJobReview';

import './postJob.scss';

export const Divider = () => <div className="post-job-divider" />;

function PostJobPage() {
  const { stepIndex, stepData, verificationModal, updateVerificationModal, updateStepIndex } =
    usePostJob();

  if (stepIndex === PostJobStep.JOB_REVIEW) return <PostJobReview />;

  return (
    <div className="post-job">
      <div className="post-job-wrapper">
        <div className="post-job-progress">
          <Progressbar value={stepData.percentage} />
        </div>

        <div className="post-job-content">
          <div className="post-job-assistant">
            <img src={AssistantAvatar} alt="" />
            <span className="post-job-description">{stepData.description}</span>
          </div>

          <h1
            className="post-job-title"
            style={{
              maxWidth: stepIndex === PostJobStep.JOB_DESCRIPTION ? 500 : undefined
            }}
          >
            {stepData.title}
          </h1>

          {stepIndex === PostJobStep.CREATE_ACCOUNT && <PostJobUser />}
          {stepIndex === PostJobStep.FIND_COMPANY && <PostJobCompany />}
          {stepIndex === PostJobStep.JOB_BASIC && <PostJobBasic />}
          {stepIndex === PostJobStep.JOB_ADVANCE && <PostJobAdvance />}
          {stepIndex === PostJobStep.JOB_DESCRIPTION && <PostJobDescription />}
        </div>

        <PostJobNavigation
          disabledBack={stepIndex === PostJobStep.CREATE_ACCOUNT}
          onBack={() => updateStepIndex(stepIndex - 1)}
        />
      </div>

      {/* Modals */}

      <PostJobVerification
        isOpen={verificationModal === PostJobVerificationModal.VERIFY_EMAIL}
        modal={PostJobVerificationModal.VERIFY_EMAIL}
        onClose={() => updateVerificationModal(null)}
      />

      <PostJobVerification
        isOpen={verificationModal === PostJobVerificationModal.CONGRATS}
        modal={PostJobVerificationModal.CONGRATS}
      />

      <PostJobVerification
        isOpen={verificationModal === PostJobVerificationModal.EXISTING_USER}
        modal={PostJobVerificationModal.EXISTING_USER}
      />
    </div>
  );
}

export default PostJobPage;
