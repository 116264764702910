import { Button, ModalDialog } from '@brightsource/brightsource-ui-lib';
import imageCompression from 'browser-image-compression';
import { AddImgIcon } from 'components/svg';
import { t } from 'i18next';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import './editProfilePictureModal.scss';

export interface EditProfilePictureModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (imageFile?: File) => void;
  currentImgUrl: string;
  isUploadImageLoading: boolean;
  setIsUploadImageLoading: (isLoading: boolean) => void;
}

function EditProfilePictureModal({
  isOpen,
  onClose,
  onSave,
  currentImgUrl,
  isUploadImageLoading,
  setIsUploadImageLoading
}: EditProfilePictureModalProps) {
  const [imgFile, setImgFile] = useState<File>();
  const [imageURI, setImageURI] = useState<string | ArrayBuffer | null | undefined>(
    currentImgUrl || ''
  );

  const imgCompressOptions = {
    maxSizeMB: 0.8,
    maxWidthOrHeight: 400,
    useWebWorker: true
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsUploadImageLoading(true);

    onSave(imgFile);
  }

  function buildImg(file: File) {
    setImgFile(file);
    let reader = new FileReader();
    reader.onload = function (ev: ProgressEvent<FileReader>) {
      setImageURI(ev.target?.result);
    };
    reader.readAsDataURL(file);
  }

  function onImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length && event.target.files[0]) {
      const fileSize: number = event.target.files[0].size;

      if (fileSize < 100000) {
        toast.error(
          t(
            'settings.profile.header.profile-picture-modal.error-small',
            'Image is too small, minimum size is 100kb'
          )
        );
      } else if (fileSize > 1500000) {
        imageCompression(event.target.files[0], imgCompressOptions)
          .then((compressedFile: File) => {
            buildImg(compressedFile);
          })
          .catch((error) =>
            toast.error(
              t(
                'settings.profile.header.profile-picture-modal.error-general',
                'Unable to upload image'
              )
            )
          );
      } else {
        buildImg(event.target.files[0]);
      }
    }
  }

  return (
    <ModalDialog
      title={t('settings.profile.header.profile-picture-modal.title', 'Edit Image') || 'Edit Image'}
      onClose={() => onClose()}
      isOpen={isOpen}
      className={'edit-profile-pictue-modal'}
    >
      <form onSubmit={handleSubmit} className={'modal-form'}>
        <div className="row">
          <div className="edit-img-container">
            <div className="editable-img-wrapper">
              {imageURI ? (
                <img src={typeof imageURI === 'string' ? imageURI : ''} alt="" />
              ) : (
                <div className="editable-img-placeholder">
                  <AddImgIcon />
                </div>
              )}
              {imageURI && (
                <a
                  className="remove-image-btn"
                  role={'button'}
                  onClick={() => {
                    setImageURI('');
                    setImgFile(undefined);
                  }}
                >
                  {t('settings.profile.header.profile-picture-modal.remove-btn', 'Remove Image')}
                </a>
              )}
            </div>
            <label htmlFor="edit-profileimg-input">
              {t('settings.profile.header.profile-picture-modal.choose-btn', 'Choose Image')}
            </label>
            <input
              id="edit-profileimg-input"
              type="file"
              accept={'.png, .jpg, .jpeg'}
              onChange={onImageChange}
            />
          </div>
        </div>
        <div className={'modal-form-actions'}>
          <Button
            variant="primary"
            type="submit"
            isLoading={isUploadImageLoading}
            disabled={isUploadImageLoading}
          >
            {t('settings.profile.header.profile-picture-modal.save-btn', 'Save')}
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
}

export default EditProfilePictureModal;
