import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '@brightsource/brightsource-ui-lib';

import { ArrowLeftIcon, ArrowRightIcon } from 'components/svg';
import { Talent } from 'types/talent';

import './talentNavPanel.scss';

interface TalentNavPanelProps {
  onClose: () => void;
  talent: Talent;
  totalTalents?: number;
  goNextTalent: () => void;
  goPrevTalent: () => void;
}

function TalentNavPanel({
  onClose,
  talent,
  totalTalents,
  goNextTalent,
  goPrevTalent
}: TalentNavPanelProps) {
  const isMobile: boolean = useIsMobile();
  const { t } = useTranslation();

  return (
    <div className="talent-nav-panel">
      <div className="talent-nav-panel-inner">
        <a onClick={onClose} className="talent-nav-panel-inner-back">
          <ArrowLeftIcon />
          {isMobile ? null : t('talent.back', 'Back')}
        </a>
        <div className="talent-nav-panel-inner-position">{talent.process?.job?.title}</div>
      </div>
      <div className="talent-nav-panel-nav">
        {talent.searchIndex === 0 ? null : (
          <a role={'button'} onClick={goPrevTalent}>
            <ArrowLeftIcon />
          </a>
        )}
        {talent.searchIndex != undefined && totalTalents ? (
          <span>
            {talent.searchIndex + 1}/{totalTalents}
          </span>
        ) : null}
        {talent.searchIndex && talent.searchIndex + 1 === totalTalents ? null : (
          <a role={'button'} onClick={goNextTalent}>
            <ArrowRightIcon />
          </a>
        )}
      </div>
    </div>
  );
}

export default TalentNavPanel;
