import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from 'components/form';
import { useForm } from 'react-hook-form';
import { PostJobCompanyData } from 'types/post-job';
import * as yup from 'yup';
import { NewCompanyStepProps } from './NewCompany';

const schema = yup.object().shape({
  name: yup.string().required('Missing company name')
});

function NewCompanyName({ formId, formData, onSubmit }: NewCompanyStepProps) {
  const { control, handleSubmit } = useForm<PostJobCompanyData>({
    defaultValues: formData ?? undefined,
    resolver: yupResolver(schema)
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label="Name"
        name="name"
        control={control}
        placeholder="Enter your company name"
      />
    </form>
  );
}

export default NewCompanyName;
