import { Button, Collapse, EditableText } from '@blueprintjs/core';
import { ToggleButton } from '@brightsource/brightsource-ui-lib';
import { LockIcon, UnlockIcon } from 'components/svg';
import { usePostJob } from 'hooks/usePostJob';
import React, { useState } from 'react';
import EditableContainer from './EditableContainer';

function EditablePreferences() {
  const { jobPreferences, updateJobPreferences } = usePostJob();

  const [isOpen, setIsOpen] = useState(true);
  const [isDiscreet, setIsDiscreet] = useState(jobPreferences.discreet);
  const [code, setCode] = useState(jobPreferences.positionCode);

  const renderContent = (isEditMode: boolean) => {
    return (
      <div className="editable-preferences">
        <ToggleButton
          label="Discreet"
          checked={isDiscreet}
          onChange={(event) => setIsDiscreet(event.target.checked)}
          disabled={!isEditMode}
        />

        <label className="editable-label">Position Code</label>

        <EditableText
          className="editable-value"
          value={code}
          onChange={setCode}
          disabled={!isEditMode}
          isEditing={isEditMode}
          placeholder={``}
        />
      </div>
    );
  };

  const handleCancel = () => {
    setIsDiscreet(jobPreferences.discreet);
    setCode(jobPreferences.positionCode);
  };

  const handleSave = () => {
    updateJobPreferences({ discreet: isDiscreet, positionCode: code });
  };

  return (
    <React.Fragment>
      <div className="post-job-toggle">
        <h6 className="post-job-heading">
          Job Post Preferences
          {isOpen ? <UnlockIcon /> : <LockIcon />}
        </h6>

        <Button
          minimal
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsOpen((x) => !x)}
        />
      </div>

      <Collapse isOpen={isOpen} keepChildrenMounted>
        <EditableContainer
          renderContent={renderContent}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      </Collapse>
    </React.Fragment>
  );
}

export default EditablePreferences;
