import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { Loader, useIsMobile } from '@brightsource/brightsource-ui-lib';

import { User } from 'types/user';
import { Company } from 'types/company';
import { MetadataItem } from 'types/common';
import UserManagementFiltersPanel from './UserManagementFiltersPanel';
import UserManagementItem from './UserManagementItem';

import './userManagementCard.scss';

export interface userManagementFilters {
  searchTerm?: string;
  roles?: MetadataItem[];
  statuses?: MetadataItem[];
}

export interface UserManagementCardProps {
  companyUsers?: User[];
  toggleNavbar: () => void;
  company?: Company;
  companyUsersRequestStaus: string;
}

export function UserManagementCard({
  companyUsers,
  toggleNavbar,
  company,
  companyUsersRequestStaus
}: UserManagementCardProps) {
  const isMobile: boolean = useIsMobile();
  const [filters, setFilters] = useState<userManagementFilters>();
  const [FilteredCompanyUsers, setFilteredCompanyUsers] = useState<User[] | undefined>(
    companyUsers
  );

  useEffect(() => {
    if (!filters) {
      setFilteredCompanyUsers(companyUsers);
    }

    const updatedUsers = companyUsers?.filter((user: User) => {
      //match searchTerm
      const matchTerm = filters?.searchTerm
        ? `${user.firstName} ${user.lastName}`
            .toLocaleLowerCase()
            .includes(filters.searchTerm.toLocaleLowerCase().trim())
        : true;

      // match roles
      const matchRoles = filters?.roles?.length
        ? filters.roles.find((role) => role.elId === user.role.elId)
        : true;

      // match status
      const matchStatus = filters?.statuses?.length
        ? filters.statuses.find((status) => status.elId === user.status?.elId)
        : true;

      return matchTerm && matchRoles && matchStatus;
    });

    setFilteredCompanyUsers(updatedUsers);
  }, [filters, companyUsers]);

  function onFiltersChange(newFilters: userManagementFilters) {
    setFilters(newFilters);
  }

  return (
    <div className="settings-card user-management-card">
      <UserManagementFiltersPanel
        toggleNavbar={toggleNavbar}
        filters={filters}
        onFiltersChange={onFiltersChange}
        company={company}
      />
      <div className="users-table-wrapper">
        {!FilteredCompanyUsers ||
        FilteredCompanyUsers?.length === 0 ||
        companyUsersRequestStaus === 'loading' ? (
          <div className="no-results-card">
            {companyUsersRequestStaus === 'loading' ? (
              <Loader />
            ) : (
              t('settings.users-management.no-results', 'No results')
            )}
          </div>
        ) : null}

        {isMobile && FilteredCompanyUsers?.length ? (
          <ul className="users-list-mobile">
            {FilteredCompanyUsers.map((user: User) => (
              <UserManagementItem key={`company-user-${user.id}`} user={user} />
            ))}
          </ul>
        ) : FilteredCompanyUsers?.length ? (
          <table>
            <tbody>
              <tr>
                <th></th>
                <th>{t('settings.users-management.name', 'Name')}</th>
                <th>{t('settings.users-management.email', 'Email')}</th>
                <th>{t('settings.users-management.activity', 'Last Activity')}</th>
                <th>{t('settings.users-management.role', 'Role')}</th>
                <th>{t('settings.users-management.status', 'Status')}</th>
                <th></th>
              </tr>

              {FilteredCompanyUsers.map((user: User) => (
                <UserManagementItem key={`company-user-${user.id}`} user={user} />
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
}
