import { Button, Collapse, EditableText } from '@blueprintjs/core';
import { ToggleButton } from '@brightsource/brightsource-ui-lib';
import { LockIcon, UnlockIcon } from 'components/svg';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { PostJobPreferences } from 'types/post-job';

import './postJobPreferences.scss';

export interface PostJobPreferencesProps {
  postJobPreferences: PostJobPreferences | null;
  setPostJobPreferences: Dispatch<SetStateAction<PostJobPreferences | null>>;
}

function PostJobPreference({ postJobPreferences, setPostJobPreferences }: PostJobPreferencesProps) {
  const [isOpen, setIsOpen] = useState(false);

  const renderContent = () => {
    return (
      <div className="post-job-preferences">
        <ToggleButton
          label="Discreet"
          checked={postJobPreferences?.discreet}
          onChange={(event) =>
            setPostJobPreferences({
              ...postJobPreferences,
              discreet: event.target.checked
            } as PostJobPreferences)
          }
        />

        <label>Position Code</label>

        <EditableText
          value={postJobPreferences?.positionCode}
          onChange={(value) =>
            setPostJobPreferences({
              ...postJobPreferences,
              positionCode: value
            } as PostJobPreferences)
          }
          placeholder={''}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="post-job-toggle">
        <h6 className="post-job-heading">
          Job Post Preferences
          {isOpen ? <UnlockIcon /> : <LockIcon />}
        </h6>

        <Button
          minimal
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsOpen((x) => !x)}
        />
      </div>

      <Collapse isOpen={isOpen} keepChildrenMounted>
        {renderContent()}
      </Collapse>
    </React.Fragment>
  );
}

export default PostJobPreference;
