import React from 'react';
import { EditableControlValue } from '@brightsource/brightsource-ui-lib';
import { Company } from '../../../../types/company';

interface SocialTabProps {
	company?: Company;
}

function SocialTab({ company }: SocialTabProps) {
	return (
		<div className='settings-card-tab'>
			<div className='settings-card-tab-inner'>
				<div className='row'>
					<EditableControlValue
						label='Twitter'
						value={company?.networks?.twitter || ''}
						onEdit={() => console.log('edit twitter')}
					/>
				</div>
				<div className='row'>
					<EditableControlValue
						label='Linkedin'
						value={company?.networks?.linkedin || ''}
						onEdit={() => console.log('edit linkedin')}
					/>
				</div>
				<div className='row'>
					<EditableControlValue
						label='Facebook'
						value={company?.networks?.facebook || ''}
						onEdit={() => console.log('edit facebook')}
					/>
				</div>
			</div>
		</div>
	);
}

export default SocialTab;
