import React, { useState } from 'react';
import { Button } from '@brightsource/brightsource-ui-lib';
import { useTranslation } from 'react-i18next';

import { EditIcon } from 'components/svg';
import { Talent } from 'types/talent';
import ReactQuill from 'react-quill';

export function SummaryTab({ talent }: { talent: Talent }) {
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [summaryHtml, setSummaryHtml] = useState<string>(talent.process?.summary || '');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveButtonText, setSaveButtonText] = useState<string>(
    t('talent.profile.save-summary', 'Save') || 'Save'
  );

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    saveSummary(summaryHtml);
    setEditMode(false);
  }

  function saveSummary(summary: string) {
    //TODO: save summary mutation
    setIsSaving((prev) => !prev);

    //
    setTimeout(() => {
      //Demonstrate saving mutation
      setIsSaving((prev) => !prev);
    }, 500);

    setSaveButtonText(t('talent.profile.saved-summary', 'Saved!') || 'Saved!');

    setTimeout(() => {
      //timeout to notify users  - will be inside the success callback function of the mutation
      setSaveButtonText(t('talent.profile.save-summary', 'Save') || 'Save');
    }, 900);
  }

  return (
    <div className="talent-card-tab">
      <div className="talent-card-tab-head">
        <h3 className="talent-card-tab-head-title">{t('talent.profile.summary', 'Summary')}</h3>
        <a
          role={'button'}
          onClick={() => setEditMode(true)}
          className="talent-card-tab-head-edit-btn"
        >
          <EditIcon />
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <ReactQuill
            className={`quill-editor ${editMode || isSaving ? 'edit-mode' : 'readonly-mode'}`}
            value={summaryHtml}
            theme={'snow'}
            onChange={setSummaryHtml}
            readOnly={editMode ? false : true}
            onBlur={(previousSelection, source, editor) => {
              saveSummary(editor.getHTML());
            }}
          />
        </div>
        <div className={'form-actions'} aria-hidden={!editMode && !isSaving}>
          <Button variant="primary" type="submit" isLoading={isSaving}>
            {saveButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}
