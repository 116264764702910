import { Button } from '@brightsource/brightsource-ui-lib';
import { usePostJob } from 'hooks/usePostJob';
import React from 'react';

function Congratulations() {
  const { onCongratsContinue } = usePostJob();

  return (
    <React.Fragment>
      <h6 className="verification-title">Congratulations!</h6>

      <p className="verification-description multi-line">
        <span>
          Your email address has been successfully verified and your account is now fully activated
          on Brightsource.com
        </span>
        <span>Click the button bellow if you’re not automatically directed</span>
      </p>

      <Button classNames="verification-button center" onClick={onCongratsContinue}>
        Continue
      </Button>
    </React.Fragment>
  );
}

export default Congratulations;
