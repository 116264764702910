import { Loader, PaginationComponent } from '@brightsource/brightsource-ui-lib';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  SubheaderPanel,
  TalentItemCard,
  TalentPanel,
  TalentSearchFiltersPanel
} from 'components/talent';
import { useSearchTalent } from 'hooks/useTalent';
import { Talent } from 'types/talent';

import './talentSearchPage.scss';

export interface TalentSearchFilters {
  job?: { id: string; title: string };
  talent?: { id: string; name: string };
  pipelineStatuses?: number[];
  sortBy?: string;
}

function TalentListPage() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<TalentSearchFilters>(getInitialFilters());
  const [currentPage, setCurrentPage] = useState<number>(getInitialCurrentPage());
  const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState<boolean>(false);
  const [selectedTalents, setSelectedTalents] = useState<string[]>([]);
  const [isTalentPanelOpen, setIsTalentPanelOpen] = useState<boolean>(false);
  const [currentTalentIndex, setCurrentIndex] = useState<number>(0);

  const { data: talentsList, isLoading } = useSearchTalent(filters, currentPage, 10);

  useEffect(() => {
    const initialPage = getInitialCurrentPage();
    setCurrentPage(initialPage);
  }, []);

  function toggleFiltersPanel() {
    setIsFiltersPanelOpen((prev) => !prev);
  }

  function getInitialCurrentPage() {
    const initialPage = searchParams.get('page');
    if (initialPage) {
      return +initialPage;
    } else {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
      return 1;
    }
  }

  function onPageChange(page: number) {
    setCurrentPage(page);
    searchParams.set('page', `${page}`);
    setSearchParams(searchParams);
  }

  function getInitialFilters() {
    let initialFiltersObject: TalentSearchFilters = {};

    const initialPiplineStatuses: string[] = searchParams.getAll('pipelineStatuses');
    const initialTalentName: string | null = searchParams.get('talentName');
    const initialTalentId: string | null = searchParams.get('talentId');
    const initialJobId: string | null = searchParams.get('jobId');
    const initialJobTitle: string | null = searchParams.get('jobTitle');

    if (initialPiplineStatuses) {
      initialFiltersObject.pipelineStatuses = initialPiplineStatuses.map((pStat) => +pStat);
    }

    if (initialTalentId && initialTalentName) {
      initialFiltersObject.talent = {
        id: decodeURIComponent(initialTalentId),
        name: decodeURIComponent(initialTalentName)
      };
    }
    if (initialJobId && initialJobTitle) {
      initialFiltersObject.job = {
        id: decodeURIComponent(initialJobId),
        title: decodeURIComponent(initialJobTitle)
      };
    }

    return initialFiltersObject;
  }

  function onFiltersChange(filters: TalentSearchFilters) {
    let searchParamsObject: any = {};

    if (filters.pipelineStatuses) {
      searchParamsObject.pipelineStatuses = filters.pipelineStatuses.map((pStatus) => `${pStatus}`);
    }
    if (filters.talent) {
      searchParamsObject.talentName = encodeURIComponent(filters.talent.name);
      searchParamsObject.talentId = encodeURIComponent(filters.talent.id);
    }
    if (filters.job) {
      searchParamsObject.jobId = encodeURIComponent(filters.job.id);
      searchParamsObject.jobTitle = encodeURIComponent(filters.job.title);
    }

    setSearchParams(searchParamsObject);
    setFilters(filters);
  }

  function onTalentPanelClose() {
    setIsTalentPanelOpen((prev: boolean) => !prev);
  }

  function goNextTalent() {
    if (currentTalentIndex + 1 === talentsList?.data.talents.length) {
      onPageChange(currentPage + 1);
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prev) => prev + 1);
    }
  }

  function goPrevTalent() {
    if (currentTalentIndex === 0) {
      onPageChange(currentPage - 1);
      talentsList?.data.talents.length && setCurrentIndex(talentsList?.data.talents.length - 1);
    } else {
      setCurrentIndex((prev) => prev - 1);
    }
  }

  return (
    <main className="talent-search-page">
      <div className="talent-search-wrapper">
        <SubheaderPanel
          talentsCount={talentsList?.data?.totalTalents}
          isFiltersPanelOpen={isFiltersPanelOpen}
          toggleFiltersPanel={toggleFiltersPanel}
          selectedTalents={selectedTalents}
        />

        <div className="talent-search-page-inner">
          <TalentSearchFiltersPanel
            isFiltersPanelOpen={isFiltersPanelOpen}
            onFiltersPannelClose={toggleFiltersPanel}
            filters={filters}
            pipelineStatusesCounter={talentsList?.data.pipelineStatusesCounter}
            onFiltersChange={onFiltersChange}
          />
          <div className="talent-search-list-wrapper">
            <ul className="talent-search-list">
              {isLoading ? <Loader /> : null}
              {talentsList?.data?.talents?.length ? (
                talentsList.data.talents.map((talent, index) => {
                  return (
                    <TalentItemCard
                      key={`talent-item-${index}`}
                      talent={talent}
                      isTalentSelected={selectedTalents.includes(talent.id)}
                      onTalentSelect={() => {
                        if (selectedTalents?.includes(talent.id)) {
                          setSelectedTalents(selectedTalents.filter((tal) => tal !== talent.id));
                        } else {
                          setSelectedTalents([...selectedTalents, talent.id]);
                        }
                      }}
                      onTalentClick={(talentToView: Talent) => {
                        setIsTalentPanelOpen((prev: boolean) => !prev);
                        setCurrentIndex(index);
                      }}
                    />
                  );
                })
              ) : (
                <li className="process-item no-results-item">
                  {t('talent-search.no-results', 'No results')}
                </li>
              )}
            </ul>
            <PaginationComponent
              current={currentPage}
              onPageChange={onPageChange}
              total={talentsList?.data.totalPages || 0}
            />
          </div>
        </div>
      </div>
      <TalentPanel
        talentId={talentsList?.data.talents[currentTalentIndex].id}
        isOpen={isTalentPanelOpen && currentTalentIndex != undefined}
        onClose={onTalentPanelClose}
        totalTalents={talentsList?.data.totalTalents}
        goNextTalent={goNextTalent}
        goPrevTalent={goPrevTalent}
      />
    </main>
  );
}

export default TalentListPage;
