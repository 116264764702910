import React from 'react';
import { t } from 'i18next';
import {
  useIsMobile,
  DefaultImage,
  formatDateToString,
  DropDownMenu
} from '@brightsource/brightsource-ui-lib';

import { User } from 'types/user';
import { EllipsisIcon } from 'components/svg';
import { Status } from 'components/common';

interface UserManagementItemProps {
  user: User;
}

function UserManagementItem({ user }: UserManagementItemProps) {
  const isMobile: boolean = useIsMobile();

  const userLastrSignIn = user.lastLoggedin
    ? formatDateToString(user.lastLoggedin, 'MMM DD,YYYY')
    : '';

  return isMobile ? (
    <li className="user-management-list-item">
      <div className="menu-wrapper">
        <UserManagementMenu />
      </div>
      <div className="user-image">
        <DefaultImage
          url={user.profileImgUrl}
          profileName={`${user?.firstName?.charAt(0)} ${user?.lastName?.charAt(0)}`}
          isProfileImage={true}
          isCompanyLogo={false}
        />
      </div>
      <div className="user-item-inner">
        <h3>
          {user.firstName} {user.lastName}
        </h3>
        <p>{user.role?.value}</p>
        <p>{user.email}</p>
        <ul className="user-details">
          <li className="user-status">
            <Status status={user.status?.value} />
          </li>
          <li className="user-last-avtivity">{userLastrSignIn}</li>
        </ul>
      </div>
    </li>
  ) : (
    <tr className="user-management-item">
      <td className="image">
        <DefaultImage
          url={user.profileImgUrl}
          profileName={`${user?.firstName?.charAt(0)} ${user?.lastName?.charAt(0)}`}
          isProfileImage={true}
          isCompanyLogo={false}
        />
      </td>
      <td className="user-name">
        {user.firstName} {user.lastName}
      </td>
      <td>{user.email}</td>
      <td>{userLastrSignIn}</td>
      <td>{user.role?.value}</td>
      <td className="user-status">
        <Status status={user.status?.value} />
      </td>
      <td className="user-management-menu">
        <UserManagementMenu />
      </td>
    </tr>
  );
}

export default UserManagementItem;

function UserManagementMenu() {
  return (
    <DropDownMenu
      triggerElement={
        <a className="user-management-menu-trigger">
          <EllipsisIcon />
        </a>
      }
      items={[
        { text: t('settings.users-management.edit', 'Edit'), onClick: () => console.log('Edit') },
        {
          text: t('settings.users-management.revoke', 'Revoke'),
          onClick: () => console.log('Revoke')
        },
        {
          text: t('settings.users-management.invite', 'Invite'),
          onClick: () => console.log('Invite')
        }
      ]}
      position="bottom"
    />
  );
}
