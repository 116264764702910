import { yupResolver } from '@hookform/resolvers/yup';
import { AutoComplete } from 'components/common';
import { InputField } from 'components/form';
import { JOB_FUNCTIONS, JOB_TYPES, WORKSPACE_TYPES } from 'constants/postJob';
import { usePostJob } from 'hooks/usePostJob';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PostJobData, PostJobItem, PostJobStep } from 'types/post-job';
import { mapToAutoCompleteList } from 'utils/postJob';
import * as yup from 'yup';

import './postJobBasic.scss';

const schema = yup.object().shape({
  jobTitle: yup.string().required('Missing job title'),
  location: yup.string().required('Missing location')
});

export function PostJobBasic() {
  const [jobFunction, setJobFunction] = useState<PostJobItem | null>(null);
  const [workspaceType, setWorkspaceType] = useState<PostJobItem | null>(null);
  const [jobType, setJobType] = useState<PostJobItem | null>(null);

  const { stepIndex, jobData, updateJobData, updateStepIndex } = usePostJob();

  useEffect(() => {
    if (!jobData) return;

    setJobFunction(jobData.jobFunction);
    setWorkspaceType(jobData.workspaceType);
    setJobType(jobData.jobType);
  }, [jobData]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitted }
  } = useForm<PostJobData>({
    defaultValues: jobData ?? undefined,
    resolver: yupResolver(schema)
  });

  const handleFormSubmit = (payload: PostJobData) => {
    if (!jobFunction || !workspaceType || !jobType) return;

    updateJobData({
      ...payload,
      jobFunction,
      workspaceType,
      jobType
    });

    updateStepIndex(PostJobStep.JOB_ADVANCE);
  };

  return (
    <form id="postJobBasic" className="post-job-basic" onSubmit={handleSubmit(handleFormSubmit)}>
      <InputField
        name="jobTitle"
        control={control}
        label="Job Title"
        placeholder="eg. Software development team leader"
      />

      <InputField name="location" control={control} label="Location" placeholder="eg. Tel Aviv" />

      <AutoComplete
        label="Job Function"
        placeholder="Software"
        list={mapToAutoCompleteList(JOB_FUNCTIONS)}
        selectedItem={jobFunction}
        updateSelectedItem={setJobFunction}
        error={isSubmitted && !jobFunction ? 'Missing job function' : undefined}
      />

      <AutoComplete
        label="Workspace Type"
        placeholder="On site"
        list={mapToAutoCompleteList(WORKSPACE_TYPES)}
        selectedItem={workspaceType}
        updateSelectedItem={setWorkspaceType}
        error={isSubmitted && !workspaceType ? 'Missing workspace type' : undefined}
      />

      <AutoComplete
        label="Job Type"
        placeholder="Full-time"
        list={mapToAutoCompleteList(JOB_TYPES)}
        selectedItem={jobType}
        updateSelectedItem={setJobType}
        error={isSubmitted && !jobType ? 'Missing job type' : undefined}
      />
    </form>
  );
}
