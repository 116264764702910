import React, { useEffect, useState } from 'react';
import { Button, ModalDialog, Select } from '@brightsource/brightsource-ui-lib';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';

import { EditIcon } from 'components/svg';
import { MetadataItem, Note } from 'types/common';
import { useMetadata } from 'hooks/useMetadata';

interface AddEditNoteModalProps {
  title: string;
  isOpen: boolean;
  note?: Note;
  onClose: () => void;
}

function AddEditNoteModal({ title, isOpen, note, onClose }: AddEditNoteModalProps) {
  const { t } = useTranslation();
  const { data: metadata, error, status } = useMetadata();

  const [noteHtmlState, setNoteHtmlState] = useState<string>(note?.textHtml || '');
  const [selectedNoteType, setSelectedNoteType] = useState<MetadataItem | undefined>(
    note?.type ? note.type : undefined
  );
  const [noteTypeError, setNoteTypeError] = useState<string>('');

  useEffect(() => {
    if (note?.textHtml && note.textHtml !== noteHtmlState) {
      setNoteHtmlState(note.textHtml);
    }
  }, [note]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (validateForm()) {
      saveNote(noteHtmlState, true);
    }
  }

  function validateForm() {
    let isValid: boolean = true;

    if (!selectedNoteType) {
      isValid = false;
      setNoteTypeError('Required');
    }

    return isValid;
  }

  function saveNote(html: string, closeModal: boolean = true) {
    //TODO: save
    //is new or edit?
  }

  return (
    <ModalDialog title={title} titleIcon={<EditIcon />} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit} className={'modal-form'}>
        <div className="row">
          <Select
            items={metadata?.data.noteTypes || []}
            label={t('', 'Note type') || ''}
            selectedItem={selectedNoteType}
            placeholder={t('', 'Select...') || ''}
            onItemSelect={(item) => {
              setSelectedNoteType(item);
              setNoteTypeError('');
            }}
            error={noteTypeError}
          />
        </div>
        <div className="row">
          <ReactQuill
            className={`quill-editor edit-mode`}
            value={noteHtmlState}
            theme={'snow'}
            onChange={setNoteHtmlState}
            readOnly={false}
            onBlur={(previousSelection, source, editor) => {
              saveNote(editor.getHTML(), false);
            }}
          />
        </div>
        <div className={'modal-form-actions'}>
          <Button type="submit">{t('talent.profile.notes.save-note', 'Savee')}</Button>
        </div>
      </form>
    </ModalDialog>
  );
}

export default AddEditNoteModal;
