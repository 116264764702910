import React from 'react';
import { DefaultImage, formatDateToString } from '@brightsource/brightsource-ui-lib';
import { EditIcon, PinIcon, TrashIcon } from 'components/svg';

import { Note } from 'types/common';

interface NoteItemProps {
  note: Note;
  noteIndex: number;
  onEdit: (idx: number) => void;
  deleteNote: (idx: number) => void;
  pinNote: (idx: number) => void;
}

function NoteItem({ note, noteIndex, onEdit, deleteNote, pinNote }: NoteItemProps) {
  const noteDate = new Date(note.lastUpdated.date);

  return (
    <li>
      <div className="note-item-header">
        <span className={`note-item-type type-${note.type.value}`}>{note.type.value}</span>
        <div className="note-cations">
          <a role="button" onClick={() => onEdit(noteIndex)}>
            <EditIcon />
          </a>
          <a role="button" onClick={() => pinNote(noteIndex)}>
            <PinIcon fillColor={note.pinned ? '#2f80ed' : '#404040'} />
          </a>
          <a role="button" onClick={() => deleteNote(noteIndex)}>
            <TrashIcon />
          </a>
        </div>
      </div>
      <div className="note-item-content" dangerouslySetInnerHTML={{ __html: note.textHtml }}></div>
      <div className="note-item-date">
        <div className="note-updated-user">
          <DefaultImage
            url={note.lastUpdated.user.profileImgUrl}
            isCompanyLogo={false}
            isProfileImage={true}
            profileName={`${note.lastUpdated.user.firstName?.charAt(
              0
            )} ${note.lastUpdated.user.lastName?.charAt(0)}`}
          />
        </div>
        <span>{formatDateToString(noteDate, 'DD.MM.YYYY')}</span> |{' '}
        <span>
          {noteDate.getHours()}:{noteDate.getSeconds()}
        </span>
      </div>
    </li>
  );
}

export default NoteItem;
