import React from 'react';
import { DefaultImage } from '@brightsource/brightsource-ui-lib';

import { Talent } from 'types/talent';

import './talentCardHeader.scss';
import { Link } from 'react-router-dom';
import { TalentItemActions } from '../TalentItemCard';

interface TalentCardHeaderProps {
  talent: Talent;
}

function TalentCardHeader({ talent }: TalentCardHeaderProps) {
  const currentJob = talent.employment?.find((employment) => employment.isCurrent);
  const talentLocation =
    talent.location?.city && talent.location?.country
      ? `${talent.location.city}, ${talent.location.country}`
      : null;

  return (
    <div className="talent-card-header">
      <div className="talent-card-header-picture">
        <DefaultImage url={talent.profileImgUrl} isProfileImage={true} isCompanyLogo={false} />
      </div>
      <div className="talent-card-header-data">
        <h1>
          <Link to={`/talent/${talent.id}`}>
            {talent.firstName} {talent.lastName}
          </Link>
        </h1>
        <ul className="talent-card-header-data-job-location">
          {currentJob ? (
            <li>
              {currentJob.title.value} at {currentJob.organization.value}
            </li>
          ) : null}
          {talentLocation ? <li>{talentLocation}</li> : null}
        </ul>
        {talent.jobPreferences ? (
          <ul className="talent-card-header-data-preferences">
            {talent.jobPreferences?.desiredPositionTypes?.[0] ? (
              <li>{talent.jobPreferences?.desiredPositionTypes?.[0].value}</li>
            ) : null}
            {talent.jobPreferences?.desiredSalary ? (
              <li>
                {talent.jobPreferences?.desiredSalary.currencySymbol}
                {talent.jobPreferences?.desiredSalary.amount}
              </li>
            ) : null}
            {talent.jobPreferences?.desiredSeniorityLevel ? (
              <li>{talent.jobPreferences?.desiredSeniorityLevel.value}</li>
            ) : null}
          </ul>
        ) : null}
        <TalentItemActions pipelineStatus={talent.process?.pipelineStatus} />
      </div>
      <div className="talent-card-header-menu"></div>
    </div>
  );
}

export default TalentCardHeader;
