import { Button } from '@blueprintjs/core';
import { CameraIcon, TrashIcon } from 'components/svg';
import { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { PostJobCompanyData } from 'types/post-job';
import { NewCompanyStepProps } from './NewCompany';

export interface NewCompanyImageProps extends NewCompanyStepProps {
  preview: string;
  onImageChange: (value: File | null) => void;
}

function NewCompanyImage(props: NewCompanyImageProps) {
  const { handleSubmit } = useForm<PostJobCompanyData>();
  const { formId, formData, preview, onSubmit, onImageChange } = props;

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) return;

    URL.revokeObjectURL(preview);
    onImageChange(event.target.files[0]);
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      {preview && <img src={preview} alt="" />}

      <div className="new-company-upload">
        {!preview && (
          <span>
            <CameraIcon />
          </span>
        )}

        <input
          hidden
          id="companyImage"
          type="file"
          accept="image/*"
          value=""
          onChange={handleImageChange}
        />

        <label htmlFor="companyImage">Choose Image</label>
      </div>

      {preview && <Button minimal icon={<TrashIcon />} onClick={() => onImageChange(null)} />}
    </form>
  );
}

export default NewCompanyImage;
