import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DropDownMenu } from '@brightsource/brightsource-ui-lib';

import { SortMenuIcon } from 'components/svg';
import { Talent } from 'types/talent';
import { Note } from 'types/common';
import NoteItem from './NoteItem';
import AddEditNoteModal from './AddEditNoteModal';

import './notesTab.scss';

export function NotesTab({ talent }: { talent: Talent }) {
  const { t } = useTranslation();
  const [notesList, setNotesList] = useState<Note[] | undefined>(
    sortNotesList(talent.process?.notes)
  );
  const [isEditAddNoteModalOpen, setIsEditAddNoteModalOpen] = useState<boolean>(false);
  const [currentNoteIndex, setCurrentNoteIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    setNotesList(sortNotesList(talent.process?.notes));
  }, []);

  function sortNotesList(notes: Note[] | undefined) {
    return notes?.length
      ? notes.sort((a: Note, b: Note) => {
          let returnValue: 0 | 1 | -1 = 0;

          if (a.pinned && !b.pinned) {
            returnValue = -1;
          } else if (!a.pinned && b.pinned) {
            returnValue = 1;
          } else if ((a.pinned && b.pinned) || (!a.pinned && !b.pinned)) {
            returnValue = 0;

            if (a.lastUpdated.date > b.lastUpdated.date) {
              returnValue = 1;
            }
          }

          return returnValue;
        })
      : [];
  }

  function deleteNote(index: number) {
    console.log('TODO-POST: delete note');
  }

  function pinNote(index: number) {
    console.log('TODO-POST: pin note');
  }

  return (
    <div className="talent-card-tab notes-tab">
      <div className="talent-card-tab-head">
        <h3 className="talent-card-tab-head-title">{t('talent.profile.notes.title', 'Notes')}</h3>
      </div>
      <div className="notes-header">
        <div className="sort-menu">
          <DropDownMenu
            items={[
              {
                text: 'date',
                onClick: () => console.log('sort by date'),
                active: true
              }
            ]}
            position="bottom"
            triggerElement={
              <a
                className="sort-options-menu-trigger"
                role="button"
                key={'sort-options-menu-trigger'}
              >
                <SortMenuIcon />
                {t('talent.profile.notes.sort', 'Sort by')}
              </a>
            }
          />
          <span className="selected-sort-option">(date)</span>
        </div>
        <Button
          onClick={() => {
            setCurrentNoteIndex(undefined);
            setIsEditAddNoteModalOpen(true);
          }}
        >
          + {t('talent.profile.notes.add-note-button', 'Add Note')}
        </Button>
      </div>
      <ul>
        {notesList?.length ? (
          notesList.map((note, index) => {
            return (
              <NoteItem
                key={`note-item-${index}`}
                noteIndex={index}
                note={note}
                onEdit={(idx) => {
                  setCurrentNoteIndex(idx);
                  setIsEditAddNoteModalOpen(true);
                }}
                deleteNote={deleteNote}
                pinNote={pinNote}
              />
            );
          })
        ) : (
          <li>
            <p>{t('talent.profile.notes.no-results', 'No notes to show')}</p>
          </li>
        )}
      </ul>
      {isEditAddNoteModalOpen ? (
        <AddEditNoteModal
          title={
            currentNoteIndex === undefined
              ? t('talent.profile.notes.add-note-title', 'Add Note')
              : t('talent.profile.notes.edit-note-title', 'Edit Note')
          }
          note={
            notesList?.length && currentNoteIndex != undefined
              ? notesList[currentNoteIndex]
              : undefined
          }
          isOpen={isEditAddNoteModalOpen}
          onClose={() => setIsEditAddNoteModalOpen(false)}
        />
      ) : null}
    </div>
  );
}
