import { PostJobResumeChannel } from 'types/post-job';

// FIXED VALUE LIST
export const WORKSPACE_TYPES = ['On-site', 'Remote', 'Hybrid'];
export const JOB_TYPES = ['Full-time', 'Part-time', 'Contract', 'Internship', 'Temporary'];
export const CURRENCIES = ['USD', 'EUR', 'GBP', 'CAD', 'AUD', 'JPY', 'CNY', 'CHF'];
export const SALARY_TYPES = ['Monthly', 'Hourly', 'Yearly'];

export const JOB_FUNCTIONS = [
  'Accounting/Auditing',
  'Administrative',
  'Advertising',
  'Business Analyst',
  'Community & Social Services',
  'Consulting',
  'Creative/Design',
  'Customer Service',
  'Education',
  'Engineering',
  'Finance',
  'Healthcare Services',
  'Human Resources',
  'Information Technology',
  'Legal',
  'Marketing',
  'Media & Communications',
  'Military & Protective Services',
  'Operations',
  'Product Management',
  'Project/Program Management',
  'Quality Assurance',
  'Real Estate',
  'Research',
  'Sales',
  'Sciences',
  'Supply Chain'
];

export const SENIORITY_LEVELS = [
  'Entry level',
  'Associate',
  'Mid-Senior level',
  'Director',
  'Executive'
];

export const RESUME_CHANNEL_LIST = [
  { id: 1, value: PostJobResumeChannel.EMAIL },
  { id: 2, value: PostJobResumeChannel.SMS }
];

export const POST_JOB_STEPS = [
  {
    title: 'Unlock Your Business for Top Talent!',
    description: "Create a free business account and let's get started!",
    percentage: 0
  },
  {
    title: 'Find Your Company in Our Database!',
    description:
      "Locate your company in our database to start posting jobs quickly. Let's get things rolling!",
    percentage: 20
  },
  {
    title: 'Easy Basic Job Details!',
    description:
      "Provide basic job details, and we'll handle the rest. Your job posting will be up in no time!",
    percentage: 40
  },
  {
    title: 'Enhance Your Job Listing with Major Properties!',
    description: 'Level up your job posting! Add major job properties to stand out.',
    percentage: 60
  },
  {
    title: 'Craft a Captivating Job Description and Start Receiving Resumes!',
    description:
      "You're almost there! Describe your job. Sit back and let the resumes roll in. Your dream team awaits!",
    percentage: 80
  }
];

export const BUILD_COMPANY_STEPS = [
  {
    title: 'About your organization',
    percentage: 20
  },
  {
    title: 'Edit your company profile',
    percentage: 25
  },
  {
    title: 'Edit your company profile',
    percentage: 30
  },
  {
    title: 'Edit your company profile',
    percentage: 35
  }
];
