import { Button } from '@brightsource/brightsource-ui-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from 'components/form';
import { usePostJob } from 'hooks/usePostJob';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

export interface VerifyEmailProps {}

function VerifyEmail() {
  const { onVerifyUser } = usePostJob();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<{ answerCode: string }>({
    resolver: yupResolver(
      yup.object().shape({
        answerCode: yup
          .string()
          .required('You must enter the verification code')
          .length(6, 'Verification code is required to have 6 characters')
      })
    )
  });

  const handleFormSubmit = async (data: { answerCode: string }) => {
    await onVerifyUser(data.answerCode);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <h6 className="verification-title">Verify your email</h6>

      <p className="verification-description grey-color">
        We sent you an email, please follow the instruction with the email to complete verification
      </p>

      <InputField
        name="answerCode"
        control={control}
        className="verification-input"
        placeholder="Verification Code"
      />

      <div className="verification-button-group">
        <Link className="verification-button resend" to="/post-job/verify" target="_blank">
          Resend Verification
        </Link>

        <Button type="submit" classNames="verification-button">
          Verify Email
        </Button>
      </div>

      <p className="verification-description">
        if you did not receive an email and your email was entered correctly, contact&nbsp;
        <a className="verification-link" href="#">
          team@brightsource.com
        </a>
      </p>
    </form>
  );
}

export default VerifyEmail;
