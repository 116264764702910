export enum PostJobStep {
  CREATE_ACCOUNT = 1,
  FIND_COMPANY,
  JOB_BASIC,
  JOB_ADVANCE,
  JOB_DESCRIPTION,
  JOB_REVIEW
}

// USER
export interface PostJobUserData {
  email: string;
  firstName: string;
  lastName: string;
  password: string;

  phone: {
    number: string;
    countryCode: string;
  };
}

export interface PostJobUserValidate {
  email: string;
  captchaToken: string;
}

export interface PostJobUserVerify {
  email: string;
  code: string;
}

export enum PostJobVerificationModal {
  VERIFY_EMAIL = 'verify-email',
  CONGRATS = 'congratulations',
  RESEND_CODE = 'resend-code',
  EXISTING_USER = 'existing-user'
}

export interface PostJobItem {
  id: number | string;
  value: string;
}

// JOB POSTING
export interface PostJobData {
  jobTitle: string;
  location: string;
  jobFunction: PostJobItem;
  workspaceType: PostJobItem;
  jobType: PostJobItem;

  skills?: PostJobItem[];
  seniorityLevel: PostJobItem;
  maxSalary?: number;
  salaryType?: PostJobItem;
  currency?: PostJobItem;

  description: string;
  resumeChannel: {
    channel: PostJobResumeChannel;
    value: string;
  };
}

export interface PostJobPayload {
  userId: number | string;
  companyId: number | string;
  jobData: PostJobData;
}

export enum PostJobResumeChannel {
  EMAIL = 'By Email',
  SMS = 'By SMS'
}

// COMPANY
export interface PostJobCompanyData {
  id: number | string;
  name: string;
  industries: string;
  // location: CompanyLocation;
  location: string;
  logoUrl?: string; // logo
  websiteAddress?: string;
  organizationSize?: PostJobOrganizationSize;
  establishYear?: string;
  companyInformation?: string;
  specialties?: string[];
}

export interface CompanyLocation {
  country: string;
  city: string;
  state?: string;
  street?: string;
}

// MEMBERS AND PREFERENCES
export interface PostJobMember {
  id: number | string;
  name: string;
  email: string;
  role: string;
}

export interface PostJobPreferences {
  discreet: boolean;
  positionCode: string;
}

// FIXED VALUE LIST
export enum PostJobOrganizationSize {
  COMPANY_SIZE_UNSPECIFIED = '1-10 employees',
  MINI = '11-50 employees',
  SMALL = '51-200 employees',
  S_MEDIUM = '201-500 employees',
  MEDIUM = '501-1,000 employees',
  BIG = '1,001-5,000 employees',
  BIGGER = '5,001-10,000 employees',
  GIANT = '10,001+ employees'
}
