import { useEffect, useState } from 'react';
import { useIsMobile, TabsList, TabId, TabObject } from '@brightsource/brightsource-ui-lib';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Company } from '../../../types/company';
import { ArrowRightIcon } from 'components/svg';
import CompanySettingsHeader from './CompanySettingsHeader';
import AboutTab from './tabs/AboutTab';
import ContactTab from './tabs/ContactTab';
import DetailsTab from './tabs/DetailsTab';
import LocationTab from './tabs/LocationTab';
import SocialTab from './tabs/SocialTab';

export interface CompanySettingsCardProps {
  company?: Company;
  companyStatus?: string;
  toggleNavbar: () => void;
}

export function CompanySettingsCard({
  company,
  toggleNavbar,
  companyStatus
}: CompanySettingsCardProps) {
  const { t } = useTranslation();
  const isMobile: boolean = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTabId, setSelectedTabId] = useState<TabId>('about');

  useEffect(() => {
    const tabId = searchParams.get('tab');
    setSelectedTabId(tabId ? tabId : 'about');
  }, [searchParams]);

  const tabItems: TabObject[] = [
    {
      id: 'about',
      title: t('settings.company.tabs.about.tab-title', 'About'),
      panel: <AboutTab company={company} />
    },
    {
      id: 'contact',
      title: t('settings.company.tabs.contact.tab-title', 'Contact'),
      panel: <ContactTab company={company} />
    },
    {
      id: 'social',
      title: t('settings.company.tabs.social.tab-title', 'Social'),
      panel: <SocialTab company={company} />
    },
    {
      id: 'location',
      title: t('settings.company.tabs.location.tab-title', 'Location'),
      panel: <LocationTab company={company} />
    },
    {
      id: 'details',
      title: t('settings.company.tabs.details.tab-title', 'Details'),
      panel: <DetailsTab company={company} />
    }
  ];

  return (
    <div className="settings-card company-card">
      {isMobile ? (
        <a className="settings-navbar-toggler" role={'button'} onClick={() => toggleNavbar()}>
          <ArrowRightIcon />
        </a>
      ) : null}
      <CompanySettingsHeader company={company} companyStatus={companyStatus} />
      <TabsList
        id="company-tabs"
        className="settings-tabs"
        selectedTabId={selectedTabId}
        onChange={(newTabId: TabId) => {
          setSelectedTabId(newTabId);
          setSearchParams({ tab: `${newTabId}` });
        }}
        tabs={tabItems}
      />
    </div>
  );
}
