import React from 'react';
import { t } from 'i18next';

import { ToggleButton } from '@brightsource/brightsource-ui-lib';
import { User } from '../../../../types/user';

export interface NotificationsTabProps {
  user?: User;
}

function NotificationsTab({ user }: NotificationsTabProps) {
  function updateNotificationsData(key: string, value: boolean) {
    //TODO: implement update
    console.log('update: ' + key + ' with: ' + value);
  }

  return (
    <div className="settings-card-tab">
      <div className="settings-card-tab-inner">
        <div className="row">
          <ToggleButton
            label={
              t('settings.profile.tabs.notifications.system-notifications', 'System reminders') ||
              ''
            }
            checked={user?.emailSettings?.notifications}
            onChange={(event) => {
              updateNotificationsData('email.notifications', event.target.checked);
            }}
          />
        </div>
        <div className="row">
          <ToggleButton
            label={
              t(
                'settings.profile.tabs.notifications.candidate-got-competitor-offer',
                'Update me if a candidate advances with competitors'
              ) || ''
            }
            checked={user?.emailSettings?.candidateGotCompetitorOffer}
            onChange={(event) => {
              updateNotificationsData('email.candidate_got_offer', event.target.checked);
            }}
          />
        </div>
        <div className="row">
          <ToggleButton
            label={
              t(
                'settings.profile.tabs.notifications.candidate-withdrawn',
                'Get updates when candidates remove their application'
              ) || ''
            }
            checked={user?.emailSettings?.candidateWithdrawn}
            onChange={(event) => {
              updateNotificationsData('email.candidate_withdrawn', event.target.checked);
            }}
          />
        </div>
        <div className="row">
          <ToggleButton
            label={
              t(
                'settings.profile.tabs.notifications.daily-summary',
                'Send me daily summary email'
              ) || ''
            }
            checked={user?.emailSettings?.dailySummary}
            onChange={(event) => {
              updateNotificationsData('email.dailySummary', event.target.checked);
            }}
          />
        </div>
        <div className="row">
          <ToggleButton
            label={
              t(
                'settings.profile.tabs.notifications.weekly-summary',
                'Send me weekly summary email'
              ) || ''
            }
            checked={user?.emailSettings?.weeklySummary}
            onChange={(event) => {
              updateNotificationsData('email.weeklySummary', event.target.checked);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default NotificationsTab;
