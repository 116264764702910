import React from 'react';

import { useTranslation } from 'react-i18next';
import { DefaultImage, formatDateToString } from '@brightsource/brightsource-ui-lib';

import { Talent } from 'types/talent';

function ExperienceCard({ talent }: { talent: Talent }) {
  const { t } = useTranslation();

  return (
    <div className="talent-card-tab profile-tab experience-card">
      <div className="talent-card-tab-head">
        <h3 className="talent-card-tab-head-title">
          {t('talent.profile.employment', 'Experience')}
        </h3>
      </div>
      <ul>
        {talent.employment?.length
          ? talent.employment?.map((item, index) => (
              <li key={`talent-panel-experience-employment-item-${index}`}>
                <div className="experience-inner">
                  <div className="experience-image">
                    <DefaultImage
                      isProfileImage={false}
                      isCompanyLogo={true}
                      url={item.organization.img}
                    />
                  </div>
                  <div className="experience-data">
                    <h4>
                      {item.title.value} {t('talent.profile.employment-card-at', 'at')}{' '}
                      {item.organization.value}
                    </h4>
                    <span>
                      {item.startDate ? formatDateToString(item.startDate, 'MMM, YYYY') : null} -{' '}
                      {item.isCurrent && !Boolean(item.endDate)
                        ? 'present'
                        : item.endDate
                        ? formatDateToString(item.endDate, 'MMM, YYYY')
                        : null}
                    </span>
                  </div>
                </div>
                <p className="experience-description">{item.description}</p>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

export default ExperienceCard;
