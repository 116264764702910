import { Button } from '@blueprintjs/core';
import cn from 'classnames';
import { usePostJob } from 'hooks/usePostJob';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { PostJobStep } from 'types/post-job';

import './postJobNavigation.scss';

export interface PostJobNavigationProps {
  form?: string;
  disabledBack?: boolean;
  disabledNext?: boolean;
  onNext?: () => void;
  onBack?: () => void;
}

export function PostJobNavigation({
  form,
  disabledBack,
  disabledNext,
  onNext,
  onBack
}: PostJobNavigationProps) {
  const { pathname } = useLocation();
  const { stepIndex, isPosting, onPostJob, onSaveAsDraft } = usePostJob();

  const isReviewMode = stepIndex === PostJobStep.JOB_REVIEW;
  const isNewMode = pathname === '/post-job/new';

  const formId = useMemo(() => {
    if (stepIndex === PostJobStep.CREATE_ACCOUNT) return 'postJobUser';
    if (stepIndex === PostJobStep.FIND_COMPANY) return 'postJobCompany';
    if (stepIndex === PostJobStep.JOB_BASIC) return 'postJobBasic';
    if (stepIndex === PostJobStep.JOB_ADVANCE) return 'postJobAdvance';
    if (stepIndex === PostJobStep.JOB_DESCRIPTION) return 'postJobDescription';
  }, [stepIndex]);

  return (
    <div className={cn('post-job-navigation', { 'is-review': isReviewMode || isNewMode })}>
      <Button minimal className="outlined back" disabled={disabledBack} onClick={onBack}>
        Back
      </Button>

      {!isReviewMode && !isNewMode && (
        <Button
          minimal
          className="contained next"
          disabled={disabledNext}
          form={form ?? formId}
          type="submit"
          onClick={onNext}
        >
          Next
        </Button>
      )}

      {(isReviewMode || isNewMode) && (
        <div className="review-button-group">
          <Button minimal className="outlined save-as-draft" onClick={onSaveAsDraft}>
            Save as Draft
          </Button>

          <Button
            minimal
            className="contained post-job"
            disabled={isPosting}
            onClick={!isNewMode ? onPostJob : undefined}
            form={isNewMode ? 'postJobNew' : undefined}
            type={isNewMode ? 'submit' : 'button'}
          >
            Post Job
          </Button>
        </div>
      )}
    </div>
  );
}
