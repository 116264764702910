import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ToastWrapper() {
  return (
    <ToastContainer
      className="toast-service-container"
      position="bottom-left"
      newestOnTop
      // autoClose={5000}
      // hideProgressBar={false}
      // closeOnClick
      // rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover
      // role="alert"
    />
  );
}
