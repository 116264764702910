import { TextArea, TextAreaProps } from '@brightsource/brightsource-ui-lib';
import { Control, useController } from 'react-hook-form';

import './textAreaField.scss';

export interface TextAreaFieldProps extends TextAreaProps {
  name: string;
  control: Control<any>;
}

export function TextAreaField({ name, control, ...rest }: TextAreaFieldProps) {
  const {
    field: { ref, value, onChange, onBlur },
    fieldState: { error }
  } = useController({
    name,
    control
  });

  return (
    <TextArea
      className="textarea-field"
      name={name}
      inputRef={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error?.message}
      {...rest}
    />
  );
}
