import { LinkedinIcon, MediumIcon, TwitterIcon } from 'components/svg';

function VerificationFooter() {
  return (
    <div className="verification-footer">
      <p>
        Streamline your employee recruitment with Brightsource - the innovative IT company offering
        a comprehensive platform for finding top talent quickly and efficiently.
      </p>

      <div className="verification-socials">
        <a href="#">
          <LinkedinIcon />
        </a>
        <a href="#">
          <MediumIcon />
        </a>
        <a href="#">
          <TwitterIcon />
        </a>
      </div>

      <span>94 Yigal Alon St. , Tel Aviv, IL</span>
    </div>
  );
}

export default VerificationFooter;
