import { PartialSearchResponse } from 'types/common';
import { JobsPipelineResponse } from 'types/job';
import axiosClient from './axiosClient';

const jobApi = {
  getAll(): Promise<JobsPipelineResponse> {
    return axiosClient.post(`/jobs-pipeline`);
  },

  searchByTitle(term: string): Promise<PartialSearchResponse> {
    return axiosClient.get(`/job-partial-search-by-title`, { params: { term } });
  }
};

export default jobApi;
