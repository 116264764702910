import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Loader } from '@brightsource/brightsource-ui-lib';

import { useTalent } from 'hooks/useTalent';

import './talentPage.scss';
import { TalentCard } from 'components/talent';

function TalentPage() {
  const { talentId } = useParams();
  const { data: talent, status } = useTalent(talentId);

  return (
    <main className="talent-page">
      <div className="talent-page-wrapper">
        {talent?.data && status === 'success' ? <TalentCard talent={talent.data} /> : null}
        {status === 'loading' ? <Loader /> : null}
        {status === 'error' ? <div>Error, please refresh or try again later.</div> : null}
      </div>
    </main>
  );
}

export default TalentPage;
