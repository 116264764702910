import { Progressbar } from '@brightsource/brightsource-ui-lib';
import AssistantAvatar from 'assets/images/assistant-avatar.png';
import { PostJobNavigation } from 'components/post-job';
import NewCompanyDescription from 'components/post-job/PostJobCompany/NewCompanyDescription';
import NewCompanyDetails from 'components/post-job/PostJobCompany/NewCompanyDetails';
import NewCompanyImage from 'components/post-job/PostJobCompany/NewCompanyImage';
import NewCompanyName from 'components/post-job/PostJobCompany/NewCompanyName';
import { BUILD_COMPANY_STEPS } from 'constants/postJob';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useCreateCompany, usePostJob } from 'hooks/usePostJob';
import { useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { storage } from 'services/firebase/firebase.service';
import { PostJobCompanyData } from 'types/post-job';

import './postJobCompany.scss';

export interface NewCompanyStepProps {
  formId: string;
  formData: PostJobCompanyData | null;
  onSubmit: (payload: PostJobCompanyData) => void;
}

// build your company page
function NewCompany() {
  const navigate = useNavigate();
  const { userData, updateCompanyData } = usePostJob();
  const { createNewCompany } = useCreateCompany();

  const [stepIndex, setStepIndex] = useState(0);
  const [newCompany, setNewCompany] = useState<PostJobCompanyData | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  const preview = useMemo(() => (image ? URL.createObjectURL(image) : ''), [image]);
  const stepData = useMemo(() => BUILD_COMPANY_STEPS[stepIndex], [stepIndex]);

  const form = useMemo(() => {
    if (stepIndex === 0) return 'newCompanyName';
    if (stepIndex === 1) return 'newCompanyImage';
    if (stepIndex === 2) return 'newCompanyDetails';
    if (stepIndex === 3) return 'newCompanyDescription';
  }, [stepIndex]);

  const handleBack = () => {
    if (stepIndex === 0) {
      navigate('/post-job');
    } else {
      setStepIndex((step) => step - 1);
    }
  };

  const uploadImageToFirebase = () => {
    if (!image) return;

    const imageRef = ref(storage, `/images/${image.name}`);
    return uploadBytes(imageRef, image).then((response) => getDownloadURL(response.ref));
  };

  const handleSubmit = async (payload: PostJobCompanyData) => {
    setNewCompany({ ...newCompany, ...payload });

    // on create new company
    if (stepIndex === BUILD_COMPANY_STEPS.length - 1) {
      setUploading(true);

      try {
        const logoUrl = await uploadImageToFirebase();
        const companyData: PostJobCompanyData = {
          ...newCompany,
          ...payload,
          logoUrl,
          id: Date.now()
        };

        createNewCompany(companyData);
        updateCompanyData(companyData);
        navigate('/post-job');
        URL.revokeObjectURL(preview);
      } catch (error) {
        console.error(error);
        toast.error('Failed to upload company image');
      }

      setUploading(false);
    } else {
      setStepIndex((step) => step + 1);
    }
  };

  if (!userData) return <Navigate to="/post-job" />;

  return (
    <div className="post-job">
      <div className="post-job-wrapper">
        <div className="post-job-progress">
          <Progressbar value={stepData.percentage} />
        </div>

        <div className="post-job-content">
          <div className="post-job-assistant">
            <img src={AssistantAvatar} alt="" />
          </div>

          <h1 className="post-job-title">{stepData.title}</h1>

          <div className="new-company">
            {stepIndex === 0 && (
              <NewCompanyName
                formId="newCompanyName"
                formData={newCompany}
                onSubmit={handleSubmit}
              />
            )}

            {stepIndex === 1 && (
              <NewCompanyImage
                formId="newCompanyImage"
                formData={newCompany}
                onSubmit={handleSubmit}
                preview={preview}
                onImageChange={setImage}
              />
            )}

            {stepIndex === 2 && (
              <NewCompanyDetails
                formId="newCompanyDetails"
                formData={newCompany}
                onSubmit={handleSubmit}
              />
            )}

            {stepIndex === 3 && (
              <NewCompanyDescription
                formId="newCompanyDescription"
                formData={newCompany}
                onSubmit={handleSubmit}
              />
            )}
          </div>
        </div>

        <PostJobNavigation form={form} onBack={handleBack} disabledNext={uploading} />
      </div>
    </div>
  );
}

export default NewCompany;
