import { MultiSelect, SelectItem, TagsCollection } from '@brightsource/brightsource-ui-lib';
import { usePostJob } from 'hooks/usePostJob';
import { useEffect, useMemo, useState } from 'react';
import EditableContainer from './EditableContainer';

function EditableSkills() {
  const { jobData, updateJobData } = usePostJob();
  const [skillList, setSkillList] = useState<SelectItem[]>([]);

  const skills = useMemo(() => {
    if (!jobData) return [];
    return jobData.skills?.map((skill) => ({ elId: skill.id, value: skill.value })) ?? [];
  }, [jobData]);

  useEffect(() => {
    setSkillList(skills);
  }, [skills]);

  const handleCancel = () => {
    setSkillList(skills);
  };

  const handleSave = () => {
    if (!jobData) return;

    updateJobData({
      ...jobData,
      skills: skillList.map((skill) => ({ id: skill.elId, value: skill.value }))
    });
  };

  const renderContent = (isEditMode: boolean) => {
    if (!isEditMode)
      return skills.length ? (
        <TagsCollection items={skills} removable={false} />
      ) : (
        <span className="editable-value">None</span>
      );

    return (
      <MultiSelect
        className="post-job-multiselect"
        placeholder="Add..."
        items={[
          { elId: 1, value: 'Javascript' },
          { elId: 2, value: 'React' },
          { elId: 3, value: 'HTML' },
          { elId: 4, value: 'CSS' },
          { elId: 5, value: 'Git' },
          { elId: 6, value: 'MongoDB' },
          { elId: 7, value: 'Agile Development' },
          { elId: 8, value: 'Development (CI/CD)' },
          { elId: 9, value: 'Angular' }
        ]}
        selectedItems={skillList}
        onItemSelect={(item) => setSkillList([...skillList, item])}
        onRemove={(item) => setSkillList(skillList.filter((skill) => skill.elId !== item.elId))}
        // error={isSubmitted && skillList.length < 5 ? 'Must choose at least 5 skills' : undefined}
      />
    );
  };

  return (
    <EditableContainer
      label="Skills"
      onSave={handleSave}
      onCancel={handleCancel}
      renderContent={renderContent}
    />
  );
}

export default EditableSkills;
