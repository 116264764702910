import { AutoCompleteItem } from 'components/common';
import { PostJobCompanyData } from 'types/post-job';

export const mapToAutoCompleteList = (list: string[]): AutoCompleteItem[] => {
  return list.map((item, idx) => ({ id: idx, value: item }));
};

export const mapToAutoCompleteItem = (company: PostJobCompanyData): AutoCompleteItem => ({
  id: company.id,
  value: company.name,
  label: company.websiteAddress,
  icon: company.logoUrl
});

export const mapToCompany = (item: AutoCompleteItem): PostJobCompanyData => ({
  id: item.id,
  name: item.value,
  websiteAddress: item.label,
  logoUrl: item.icon,
  industries: '',
  location: ''
});
