import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsList, TabId, TabObject } from '@brightsource/brightsource-ui-lib';

import { Talent } from 'types/talent';

import './talentCard.scss';
import { CVTab, NotesTab, ProfileTab, SummaryTab, TagsTab } from '../TalentTabs';
import TalentCardHeader from './TalentCardHeader';

export interface TalentCardProps {
  talent: Talent;
  onClose?: () => void;
}

export function TalentCard({ talent }: TalentCardProps) {
  const { t } = useTranslation();
  const [selectedTabId, setSelectedTabId] = useState<TabId>('profile');

  const tabItems: TabObject[] = [
    {
      id: 'profile',
      title: t('talent.tabs.profile', 'Profile'),
      panel: <ProfileTab talent={talent} />
    },
    {
      id: 'cv',
      title: t('talent.tabs.CV', 'CV'),
      panel: <CVTab cvId={talent.cvId} />
    },
    {
      id: 'summary',
      title: t('talent.tabs.summary', 'Summary'),
      panel: <SummaryTab talent={talent} />
    },
    {
      id: 'notes',
      title: t('talent.tabs.notes', 'Notes'),
      panel: <NotesTab talent={talent} />
    },
    {
      id: 'tags',
      title: t('talent.tabs.tags', 'Tags'),
      panel: <TagsTab talent={talent} />
    }
  ];

  const handleTabChange = (newTabId: TabId) => {
    setSelectedTabId(newTabId);
  };

  return (
    <div className="talent-card">
      <TalentCardHeader talent={talent} />
      <TabsList
        id="profile-tabs"
        className="settings-tabs"
        selectedTabId={selectedTabId}
        onChange={handleTabChange}
        tabs={tabItems}
      />
    </div>
  );
}
