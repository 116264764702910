import { POST_JOB_STEPS } from 'constants/postJob';
import { PostJobContext, PostJobContextProps } from 'context/PostJobContext';
import { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import postJobApi from 'services/api/postJobApi';
import {
  PostJobCompanyData,
  PostJobData,
  PostJobMember,
  PostJobPayload,
  PostJobPreferences,
  PostJobStep,
  PostJobUserData,
  PostJobUserValidate,
  PostJobVerificationModal
} from 'types/post-job';

export function usePostJob() {
  const navigate = useNavigate();
  const { state, initialState, updatePostJobState } = useContext(
    PostJobContext
  ) as PostJobContextProps;

  const [codeSent, setCodeSent] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  const { stepIndex, userData, jobData: currentJobData, companyData } = state;

  const stepData = useMemo(() => POST_JOB_STEPS[stepIndex - 1], [stepIndex]);

  const updateStepIndex = (stepIndex: PostJobStep) => {
    updatePostJobState({ stepIndex });
  };

  const updateUserData = (userData: PostJobUserData | null) => {
    updatePostJobState({ userData });
  };

  const updateCompanyData = (companyData: PostJobCompanyData | null) => {
    updatePostJobState({ companyData });
  };

  const updateJobData = (jobData: PostJobData | null) => {
    const newJobData = currentJobData ? { ...currentJobData, ...jobData } : jobData;
    updatePostJobState({ jobData: newJobData });
  };

  const updateVerificationModal = (verificationModal: PostJobVerificationModal | null) => {
    updatePostJobState({ verificationModal });
  };

  const updateJobMembers = (jobMembers: PostJobMember[]) => {
    updatePostJobState({ jobMembers });
  };

  const updateJobPreferences = (jobPreferences: PostJobPreferences) => {
    updatePostJobState({ jobPreferences });
  };

  const onValidateUser = async (payload: PostJobUserValidate) => {
    try {
      !codeSent && (await postJobApi.validateAccount(payload));
      updateVerificationModal(PostJobVerificationModal.VERIFY_EMAIL);
      setCodeSent(true);
    } catch (error) {
      console.log('Failed to validate user', error);
    }
  };

  const onVerifyUser = async (code: string) => {
    if (!userData) return;

    try {
      const response = await postJobApi.verifyAccount({ email: userData.email, code });
      updateVerificationModal(PostJobVerificationModal.CONGRATS);
      console.log(response);
    } catch (error) {
      console.log('Failed to verify code', error);
    }
  };

  const onCongratsContinue = () => {
    // updatePostJobState({ stepIndex: stepIndex + 1, verificationModal: null });
    updateStepIndex(PostJobStep.FIND_COMPANY);
    updateVerificationModal(null);
  };

  const onPostJob = async () => {
    if (!companyData || !currentJobData) return;

    const data: PostJobPayload = {
      userId: 'user24',
      companyId: companyData.id,
      jobData: currentJobData
    };

    setIsPosting(true);

    try {
      await postJobApi.create(data);
      toast.success('successful job posting');
      navigate('/post-job/congrats');
      onResetState();
    } catch (error) {
      console.log('Failed to post a job', error);
    }

    setIsPosting(false);
  };

  const onSaveAsDraft = () => {
    toast.info('draft saved');
    console.log({ userData, companyData, currentJobData });
  };

  // reset all fields, except userData
  const onResetState = () => {
    updatePostJobState({ ...initialState, userData });
  };

  return {
    ...state,
    stepData,
    isPosting,

    updateStepIndex,
    updateUserData,
    updateCompanyData,
    updateJobData,
    updateVerificationModal,
    updateJobPreferences,
    updateJobMembers,

    onValidateUser,
    onVerifyUser,
    onCongratsContinue,

    onPostJob,
    onSaveAsDraft,
    onResetState
  };
}

export const useResendCode = (userEmail?: string) => {
  return useQuery('resendCode', () => postJobApi.resendCode(userEmail), {
    enabled: !!userEmail
  });
};

export function useCompanies() {
  return useQuery('companies', postJobApi.getCompanies);
}

export function useCreateCompany() {
  const client = useQueryClient();
  const { data: companies } = useCompanies();

  const { mutate: createNewCompany } = useMutation({
    mutationFn: postJobApi.createCompany,
    onMutate: (newCompany) => {
      client.setQueryData('companies', companies ? [...companies, newCompany] : [newCompany]);
    }
  });

  return { createNewCompany };
}
