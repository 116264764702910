import React from 'react';
import { Trans } from 'react-i18next';

import { BroadcastIcon, DotIcon } from 'components/svg';

import './status.scss';

interface StatusProps {
  status?: string;
}

export function Status({ status }: StatusProps) {
  switch (status) {
    case 'active':
      return (
        <span className="status status-active">
          <BroadcastIcon />
          <Trans i18nKey={'common.statuses.active'}>Active</Trans>
        </span>
      );
    case 'pending':
      return (
        <span className="status status-pending">
          <BroadcastIcon /> <Trans i18nKey={'common.statuses.pending'}>Pending</Trans>
        </span>
      );
    case 'deleted':
      return (
        <span className="status status-deleted">
          <BroadcastIcon /> <Trans i18nKey={'common.statuses.deleted'}>Deleted</Trans>
        </span>
      );
    case 'revoke':
      return (
        <span className="status status-revoke">
          <BroadcastIcon /> <Trans i18nKey={'common.statuses.revoke'}>Revoke</Trans>
        </span>
      );
    default:
      return <span></span>;
  }
}
