import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Suggest, useDebouncedState } from '@brightsource/brightsource-ui-lib';

import { MetadataItem } from 'types/common';
import { useSearchJobByTitle } from 'hooks/useJob';

function FiltersPanel() {
  const { t } = useTranslation();
  const [jobSearchTerm, setJobSearchTerm, debouncedJobSearchTerm] = useDebouncedState('', 200);
  // const [selectedJob, setSelectedJob] = useState<MetadataItem | undefined>(
  //   filters.job ? { elId: filters.job.id, value: filters.job.title } : undefined
  // );
  const [selectedJob, setSelectedJob] = useState<MetadataItem | undefined>(undefined);
  const { data: jobsOptions } = useSearchJobByTitle(debouncedJobSearchTerm);

  return (
    <div className="jobs-pipeline-filters-panel-inner">
      <div className="filters-search-section">
        <a className="filters-reset-btn" role="button" onClick={() => console.log('reset filters')}>
          {t('talent-search.filters.reset-button', 'Reset')}
        </a>
        <Suggest
          items={jobsOptions?.data.results}
          controlledQuery={jobSearchTerm}
          hasControlledQuery={true}
          placeholder={t('talent-search.filters.search-job-title', 'Search job title') || ''}
          selectedItem={selectedJob}
          label={t('talent-search.filters.search-job-title', 'Search job title') || ''}
          hiddenLabel={true}
          allowReset={true}
          onControlledQueryChange={(q: string) => setJobSearchTerm(q)}
          onItemSelect={(selected: MetadataItem) => {
            setSelectedJob(selected);
            setJobSearchTerm(selected.value);
            // onFiltersChange({ ...filters, job: { id: `${selected.elId}`, title: selected.value } });
          }}
          onReset={() => {
            setJobSearchTerm('');
            setSelectedJob(undefined);
            // let updatedFilters = { ...filters };
            // delete updatedFilters.job;
            // onFiltersChange(updatedFilters);
          }}
        />
      </div>
      <div className="filters-section"></div>
    </div>
  );
}

export default FiltersPanel;
