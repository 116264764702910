import { usePostJob } from 'hooks/usePostJob';
import { Navigate } from 'react-router';

function PostJobCongrats() {
  const { userData } = usePostJob();

  if (!userData) return <Navigate to="/post-job" />;

  return (
    <div className="post-job-congrats">
      <h1>Congratulations</h1>
      <p>You have successfully posted a job 🎉</p>

      <div className="data"></div>
    </div>
  );
}

export default PostJobCongrats;
