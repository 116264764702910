import { MultiSelect, SelectItem } from '@brightsource/brightsource-ui-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoComplete } from 'components/common';
import { InputField, TextAreaField } from 'components/form';
import { PostJobNavigation } from 'components/post-job';
import PostJobPreference from 'components/post-job/PostJobPreferences';
import PostJobTeam from 'components/post-job/PostJobTeam';
import {
  CURRENCIES,
  JOB_FUNCTIONS,
  JOB_TYPES,
  RESUME_CHANNEL_LIST,
  SALARY_TYPES,
  SENIORITY_LEVELS,
  WORKSPACE_TYPES
} from 'constants/postJob';
import { useCompanies } from 'hooks/usePostJob';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import postJobApi from 'services/api/postJobApi';
import {
  PostJobData,
  PostJobItem,
  PostJobMember,
  PostJobPreferences,
  PostJobResumeChannel
} from 'types/post-job';
import { mapToAutoCompleteItem, mapToAutoCompleteList } from 'utils/postJob';
import { formSchema } from 'utils/validation';
import * as yup from 'yup';
import { Divider } from '.';

function PostJobNew() {
  const [company, setCompany] = useState<PostJobItem | null>(null);

  const [jobFunction, setJobFunction] = useState<PostJobItem | null>(null);
  const [workspaceType, setWorkSpaceType] = useState<PostJobItem | null>(null);
  const [jobType, setJobType] = useState<PostJobItem | null>(null);

  const [skills, setSkills] = useState<SelectItem[]>([]);
  const [salaryType, setSalaryType] = useState<PostJobItem | null>(null);
  const [currency, setCurrency] = useState<PostJobItem | null>(null);
  const [seniorityLevel, setSeniorityLevel] = useState<PostJobItem | null>(null);
  const [channel, setChannel] = useState<PostJobResumeChannel>(PostJobResumeChannel.EMAIL);

  const [members, setMembers] = useState<PostJobMember[]>([]);
  const [postJobPreferences, setPostJobPreferences] = useState<PostJobPreferences | null>(null);

  const { data: companyList, error, isLoading } = useCompanies();

  const schema = yup.object().shape({
    jobTitle: yup.string().required('Missing job title'),
    location: yup.string().required('Missing location'),
    maxSalary: yup.string().required('Missing max salary'),
    // jobDescription: yup.string().required('Missing Job Description'),
    resumeChannel: yup.object().shape({
      value: channel === PostJobResumeChannel.EMAIL ? formSchema.email : formSchema.phoneNumber
    })
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitted }
  } = useForm<PostJobData>({
    resolver: yupResolver(schema)
  });

  const handleFormSubmit = async (payload: PostJobData) => {
    if (!company || !jobFunction || !workspaceType || !jobType || !seniorityLevel) return;

    const jobData: PostJobData = {
      ...payload,
      jobFunction,
      workspaceType,
      jobType,
      seniorityLevel,
      salaryType: salaryType ?? undefined,
      currency: currency ?? undefined,
      resumeChannel: { ...payload.resumeChannel, channel },
      skills: skills.map((skill) => ({ id: skill.elId, value: skill.value }))
    };

    await postJobApi.create({
      userId: 'user24',
      companyId: company.id,
      jobData
    });
  };

  return (
    <form id="postJobNew" className="post-job-new" onSubmit={handleSubmit(handleFormSubmit)}>
      <h1 className="post-job-title">Create A New Job</h1>

      <Divider />

      <h6 className="post-job-heading">Job Details</h6>

      <div className="new-job-item">
        <AutoComplete
          label="Company Name"
          list={companyList ? companyList.map(mapToAutoCompleteItem) : []}
          selectedItem={company}
          updateSelectedItem={setCompany}
          error={isSubmitted && !company ? 'Missing company name' : undefined}
        />
        <InputField
          name="jobTitle"
          label="Job Title"
          placeholder="Enter job title"
          control={control}
        />
        <InputField
          name="location"
          label="Location"
          placeholder="Enter Location"
          control={control}
        />
        <AutoComplete
          label="Job Function"
          placeholder="Enter Job function"
          list={mapToAutoCompleteList(JOB_FUNCTIONS)}
          selectedItem={jobFunction}
          updateSelectedItem={setJobFunction}
          error={isSubmitted && !jobFunction ? 'Missing Job Function' : undefined}
        />
        <AutoComplete
          label="Workspace Type"
          placeholder="On site"
          list={mapToAutoCompleteList(WORKSPACE_TYPES)}
          selectedItem={workspaceType}
          updateSelectedItem={setWorkSpaceType}
          error={isSubmitted && !workspaceType ? 'Missing Workspace Type' : undefined}
        />
        <AutoComplete
          label="Job Type"
          placeholder="Full time"
          list={mapToAutoCompleteList(JOB_TYPES)}
          selectedItem={jobType}
          updateSelectedItem={setJobType}
          error={isSubmitted && !jobType ? 'Missing Job Type' : undefined}
        />
      </div>

      <Divider />

      <div className="new-job-item">
        <MultiSelect
          className="post-job-multiselect"
          label="Required skills (at least 5)"
          placeholder="Add"
          items={[
            { elId: 1, value: 'Javascript' },
            { elId: 2, value: 'React' },
            { elId: 3, value: 'HTML' },
            { elId: 4, value: 'CSS' },
            { elId: 5, value: 'Git' },
            { elId: 6, value: 'MongoDB' },
            { elId: 7, value: 'Agile Development' },
            { elId: 8, value: 'Development (CI/CD)' },
            { elId: 9, value: 'Angular' }
          ]}
          selectedItems={skills}
          onItemSelect={(item) => setSkills([...skills, item])}
          onRemove={(item) => setSkills(skills.filter((skill) => skill.elId !== item.elId))}
          error={isSubmitted && skills.length < 5 ? 'Must choose at least 5 skills' : undefined}
        />

        <AutoComplete
          label="Seniority Level"
          placeholder="Senior"
          list={mapToAutoCompleteList(SENIORITY_LEVELS)}
          selectedItem={seniorityLevel}
          updateSelectedItem={setSeniorityLevel}
          error={isSubmitted && !seniorityLevel ? 'Missing seniority level' : undefined}
        />

        <div className="row">
          <InputField name="maxSalary" label="Max Salary" control={control} type="number" />

          <AutoComplete
            label="Salary Type"
            placeholder=""
            list={mapToAutoCompleteList(SALARY_TYPES)}
            selectedItem={salaryType}
            updateSelectedItem={setSalaryType}
            error={isSubmitted && !salaryType ? 'Missing salary type' : undefined}
          />

          <AutoComplete
            label="Currency"
            placeholder=""
            list={mapToAutoCompleteList(CURRENCIES)}
            selectedItem={currency}
            updateSelectedItem={setCurrency}
            error={isSubmitted && !currency ? 'Missing currency' : undefined}
          />
        </div>
      </div>

      <Divider />

      <div className="new-job-item">
        <TextAreaField
          name="jobDescription"
          label="Job Description"
          placeholder="Write a detailed and specific description that will sound attractive to the right potential candidates"
          control={control}
          rows={6}
        />
      </div>

      <Divider />

      <div className="new-job-item resume-channel">
        <div>
          <label>How would you like to receiver your applicants</label>

          <div className="row">
            <AutoComplete
              placeholder={channel}
              list={RESUME_CHANNEL_LIST}
              selectedItem={RESUME_CHANNEL_LIST.find((field) => field.value === channel) ?? null}
              updateSelectedItem={(item) => item && setChannel(item.value as PostJobResumeChannel)}
              error={isSubmitted && !channel ? 'Missing Method ' : undefined}
            />

            <InputField
              type={channel === 'By Email' ? 'email' : 'number'}
              name="resumeChannel.value"
              placeholder={channel === 'By Email' ? 'Enter email' : 'Enter phone number'}
              control={control}
            />
          </div>
        </div>
      </div>

      <Divider />

      <PostJobTeam jobMembers={members} setMembers={setMembers} />

      <Divider />

      <PostJobPreference
        postJobPreferences={postJobPreferences}
        setPostJobPreferences={setPostJobPreferences}
      />

      <PostJobNavigation disabledBack={true} disabledNext={isSubmitting} />
    </form>
  );
}

export default PostJobNew;
