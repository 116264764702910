import axiosClient from './axiosClient';
import { CVObject } from 'types/common';

type GetCvByIdResp = { data: CVObject };

const cvApi = {
  getById(cvId?: string): Promise<GetCvByIdResp> {
    return axiosClient.get(`/cv/${cvId}`);
  }
};

export default cvApi;
