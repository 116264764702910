import { useQuery } from 'react-query';
import cvApi from 'services/api/cvApi';

export function useCV(cvId?: string) {
  return useQuery({
    enabled: cvId !== undefined,
    queryKey: ['cv', cvId],
    cacheTime: 60 * 60 * 1000, //60 minutes
    retry: false,
    queryFn: () => cvApi.getById(cvId)
  });
}
