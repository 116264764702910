import React from 'react';

import { EditableControlValue } from '@brightsource/brightsource-ui-lib';
import { Company } from '../../../../types/company';

interface DetailsTabProps {
	company?: Company;
}

function DetailsTab({ company }: DetailsTabProps) {
	return (
		<div className='settings-card-tab'>
			<div className='settings-card-tab-inner'>
				<div className='row'>
					<EditableControlValue
						label='Lifecycle Stage'
						value={company?.lifeCycleStage?.value || ''}
						onEdit={() => console.log('edit Lifecycle Stage')}
					/>
				</div>
				<div className='row'>
					<EditableControlValue
						label='Market Type'
						value={company?.marketType?.value || ''}
						onEdit={() => console.log('edit Market Type')}
					/>
				</div>
				<div className='row'>
					<EditableControlValue
						label='Business Stage'
						value={company?.businessStage?.value || ''}
						onEdit={() => console.log('edit Business Stage')}
					/>
				</div>
				<div className='row'>
					<EditableControlValue
						label='Business Model'
						value={
							company?.businessModel
								? company.businessModel
										.map((businessModel, index) => {
											return `${businessModel.value}${
												index !== company.businessModel!.length - 1 ? ', ' : '.'
											}`;
										})
										.join('')
								: ''
						}
						onEdit={() => console.log('edit Business Model')}
					/>
				</div>
			</div>
		</div>
	);
}

export default DetailsTab;
