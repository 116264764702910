import { Dialog, DialogBody, DialogProps } from '@blueprintjs/core';
import { PostJobVerificationModal } from 'types/post-job';
import Congratulations from './Congratulations';
import ExistingUser from './ExistingUser';
import ResendCode from './ResendCode';
import VerifyEmail from './VerifyEmail';

import './postJobVerification.scss';

export interface PostJobVerificationProps extends DialogProps {
  modal: PostJobVerificationModal;
}

export function PostJobVerification({ modal, ...props }: PostJobVerificationProps) {
  const className = ['post-job-verification', modal].join(' ');

  if (!modal) return null;

  return (
    <Dialog {...props} className={className}>
      <DialogBody useOverflowScrollContainer={false}>
        {modal === PostJobVerificationModal.VERIFY_EMAIL && <VerifyEmail />}
        {modal === PostJobVerificationModal.CONGRATS && <Congratulations />}
        {modal === PostJobVerificationModal.RESEND_CODE && <ResendCode />}
        {modal === PostJobVerificationModal.EXISTING_USER && <ExistingUser />}
      </DialogBody>
    </Dialog>
  );
}
