import { MultiSelect, SelectItem } from '@brightsource/brightsource-ui-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoComplete } from 'components/common';
import { InputField } from 'components/form';
import { CURRENCIES, SALARY_TYPES, SENIORITY_LEVELS } from 'constants/postJob';
import { usePostJob } from 'hooks/usePostJob';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PostJobData, PostJobItem, PostJobStep } from 'types/post-job';
import { mapToAutoCompleteList } from 'utils/postJob';
import * as yup from 'yup';

import './postJobAdvance.scss';

const schema = yup.object().shape({
  maxSalary: yup.number().required('Missing max salary').typeError('Please enter a number')
});

export function PostJobAdvance() {
  const [skillList, setSkillList] = useState<SelectItem[]>([]);
  const [seniorityLevel, setSeniorityLevel] = useState<PostJobItem | null>(null);
  const [salaryType, setSalaryType] = useState<PostJobItem | null>(null);
  const [currency, setCurrency] = useState<PostJobItem | null>(null);

  const { stepIndex, jobData, updateJobData, updateStepIndex } = usePostJob();

  useEffect(() => {
    if (!jobData) return;

    setSeniorityLevel(jobData.seniorityLevel);
    setSalaryType(jobData.salaryType ?? null);
    setCurrency(jobData.currency ?? null);
    setSkillList(jobData.skills?.map((skill) => ({ elId: skill.id, value: skill.value })) ?? []);
  }, [jobData]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitted }
  } = useForm<PostJobData>({
    defaultValues: jobData ?? undefined,
    resolver: yupResolver(schema)
  });

  const handleFormSubmit = (payload: PostJobData) => {
    if (!salaryType || !currency || !seniorityLevel || skillList.length < 5) return;

    updateJobData({
      ...payload,
      seniorityLevel,
      salaryType,
      currency,
      skills: skillList.map((skill) => ({ id: skill.elId, value: skill.value }))
    });

    updateStepIndex(PostJobStep.JOB_DESCRIPTION);
  };

  return (
    <form
      id="postJobAdvance"
      className="post-job-advance"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <MultiSelect
        className="post-job-multiselect"
        label="Required skills (at least 5)"
        placeholder="Add..."
        items={[
          { elId: 1, value: 'Javascript' },
          { elId: 2, value: 'React' },
          { elId: 3, value: 'HTML' },
          { elId: 4, value: 'CSS' },
          { elId: 5, value: 'Git' },
          { elId: 6, value: 'MongoDB' },
          { elId: 7, value: 'Agile Development' },
          { elId: 8, value: 'Development (CI/CD)' },
          { elId: 9, value: 'Angular' }
        ]}
        selectedItems={skillList}
        onItemSelect={(item) => setSkillList([...skillList, item])}
        onRemove={(item) => setSkillList(skillList.filter((skill) => skill.elId !== item.elId))}
        error={isSubmitted && skillList.length < 5 ? 'Must choose at least 5 skills' : undefined}
      />

      <AutoComplete
        label="Seniority Level"
        placeholder="Senior"
        list={mapToAutoCompleteList(SENIORITY_LEVELS)}
        selectedItem={seniorityLevel}
        updateSelectedItem={setSeniorityLevel}
        error={isSubmitted && !seniorityLevel ? 'Missing seniority level' : undefined}
      />

      <div className="salary">
        <InputField
          name="maxSalary"
          type="number"
          label="Max Salary"
          control={control}
          placeholder="eg. 25000"
          min="0"
        />

        <AutoComplete
          label="Salary Type"
          placeholder="Monthly"
          list={mapToAutoCompleteList(SALARY_TYPES)}
          selectedItem={salaryType}
          updateSelectedItem={setSalaryType}
          error={isSubmitted && !salaryType ? 'Missing salary type' : undefined}
        />

        <AutoComplete
          label="Currency"
          placeholder="Dollar (US)"
          list={mapToAutoCompleteList(CURRENCIES)}
          selectedItem={currency}
          updateSelectedItem={setCurrency}
          error={isSubmitted && !currency ? 'Missing currency' : undefined}
        />
      </div>
    </form>
  );
}
