import { TextAreaField } from 'components/form';
import { useForm } from 'react-hook-form';
import { PostJobCompanyData } from 'types/post-job';
import { NewCompanyStepProps } from './NewCompany';

function NewCompanyDescription({ formId, formData, onSubmit }: NewCompanyStepProps) {
  const { control, handleSubmit } = useForm<PostJobCompanyData>({
    defaultValues: formData ?? undefined
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <TextAreaField
        name="companyInformation"
        label="Company information"
        placeholder="Enter your company information"
        control={control}
        rows={6}
      />
    </form>
  );
}

export default NewCompanyDescription;
