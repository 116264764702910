import { Button, Collapse, Divider } from '@blueprintjs/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutoComplete, AutoCompleteItem } from 'components/common';
import { InputField } from 'components/form';
import { AccountIcon, LockIcon, UnlockIcon } from 'components/svg';
import { usePostJob } from 'hooks/usePostJob';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formSchema } from 'utils/validation';
import * as yup from 'yup';
import EditableContainer from './EditableContainer';

const ROLE_LIST = [
  { id: 1, value: 'Hiring manager' },
  { id: 2, value: 'Recruiter' },
  { id: 3, value: 'Coordinator' }
];

function EditableTeam() {
  const { jobMembers, updateJobMembers } = usePostJob();

  const [isOpen, setIsOpen] = useState(true);
  const [memberList, setMemberList] = useState(jobMembers);
  const [selectedRole, setSelectedRole] = useState<AutoCompleteItem | null>(null);

  const onChangeRole = (item: AutoCompleteItem | null, memberId: number | string) => {
    if (!item) return;

    const newMemberList = memberList.map((member) => {
      if (member.id === memberId) return { ...member, role: item.value };
      return member;
    });

    setMemberList(newMemberList);
  };

  const onRemoveMember = (memberId: number | string) => {
    const newMemberList = memberList.filter((member) => member.id !== memberId);
    setMemberList(newMemberList);
  };

  const handleCancel = () => {
    setMemberList(jobMembers);
  };

  const handleSave = () => {
    updateJobMembers(memberList);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitted }
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: formSchema.name,
        email: formSchema.email
      })
    )
  });

  const handleFormSubmit = (data: any) => {
    if (!selectedRole) return;

    const newMember = {
      ...data,
      id: Math.floor(Math.random() * 100),
      role: selectedRole.value
    };

    setMemberList([newMember, ...memberList]);
    reset();
  };

  const renderContent = (isEditMode: boolean) => {
    return (
      <div className="editable-team">
        {isEditMode && (
          <form className="editable-row" onSubmit={handleSubmit(handleFormSubmit)}>
            <InputField label="Name" name="name" control={control} placeholder="Hau" />

            <InputField
              label="Email"
              name="email"
              control={control}
              placeholder="hau@brightsource.com"
            />

            <AutoComplete
              label="Role"
              list={ROLE_LIST}
              selectedItem={selectedRole}
              updateSelectedItem={setSelectedRole}
              placeholder="Recruiter"
              error={isSubmitted && !selectedRole ? 'Missing role' : undefined}
            />

            <Button minimal type="submit" className="editable-team-add">
              Add
            </Button>
          </form>
        )}

        {isEditMode && <Divider />}

        {memberList.map((member) => (
          <div key={member.id} className="editable-row">
            <span className="editable-team-field">
              <AccountIcon /> {member.name}
            </span>

            <span className="editable-team-field">{member.email}</span>

            {isEditMode ? (
              <AutoComplete
                list={ROLE_LIST}
                selectedItem={{ id: member.id, value: member.role }}
                updateSelectedItem={(item) => onChangeRole(item, member.id)}
                onRemove={() => onRemoveMember(member.id)}
                disabledSearch
                placeholder=""
              />
            ) : (
              <span className="editable-team-field">{member.role}</span>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="post-job-toggle">
        <h6 className="post-job-heading">
          Team
          {isOpen ? <UnlockIcon /> : <LockIcon />}
        </h6>

        <Button
          minimal
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsOpen((x) => !x)}
        />
      </div>

      <Collapse isOpen={isOpen} keepChildrenMounted>
        <EditableContainer
          renderContent={renderContent}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      </Collapse>
    </React.Fragment>
  );
}

export default EditableTeam;
