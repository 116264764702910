import { ReactNode, createContext, useReducer } from 'react';
import {
  PostJobCompanyData,
  PostJobData,
  PostJobMember,
  PostJobPreferences,
  PostJobStep,
  PostJobUserData,
  PostJobVerificationModal
} from 'types/post-job';

export interface PostJobState {
  stepIndex: PostJobStep;
  verificationModal: PostJobVerificationModal | null;
  userData: PostJobUserData | null;
  companyData: PostJobCompanyData | null;
  jobData: PostJobData | null;

  jobMembers: PostJobMember[];
  jobPreferences: PostJobPreferences;
}

const initialState: PostJobState = {
  stepIndex: PostJobStep.CREATE_ACCOUNT,
  verificationModal: null,
  userData: null,
  companyData: null,
  jobData: null,

  jobPreferences: { discreet: false, positionCode: '' },
  jobMembers: [
    { id: 1, name: 'Hau Le', email: 'hau@gmail.com', role: 'Hiring manager' },
    { id: 2, name: 'Hau Le', email: 'hau@gmail.com', role: 'Recruiter' },
    { id: 3, name: 'Hau Le', email: 'hau@gmail.com', role: 'Coordinator' }
  ]
};

export interface PostJobContextProps {
  state: PostJobState;
  initialState: PostJobState;
  updatePostJobState: (newState: Partial<PostJobState>) => void;
}

export const PostJobContext = createContext<PostJobContextProps | null>(null);

export enum PostJobActionType {
  UPDATE_STATE = 'UPDATE_POST_JOB_STATE'
}

export type PostJobAction =
  // | { type: PostJobActionType.UPDATE_ACCOUNT; postJobAccount: PostJobAccount | null }
  { type: PostJobActionType.UPDATE_STATE; newState: Partial<PostJobState> | null };

const reducer = (state: PostJobState, action: PostJobAction) => {
  switch (action.type) {
    case PostJobActionType.UPDATE_STATE:
      return { ...state, ...action.newState };

    default:
      throw new Error('Invalid action');
  }
};

const PostJobProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updatePostJobState = (newState: Partial<PostJobState>) => {
    const action: PostJobAction = {
      type: PostJobActionType.UPDATE_STATE,
      newState
    };

    dispatch(action);
  };

  const value = { state, initialState, updatePostJobState };

  return <PostJobContext.Provider value={value}>{children}</PostJobContext.Provider>;
};

export default PostJobProvider;
