import { Company } from 'types/company';
import axiosClient from './axiosClient';

type getCompanyResponse = { data: Company };

const companyApi = {
  getById(id: string | undefined): Promise<getCompanyResponse> {
    return typeof id === 'undefined'
      ? Promise.reject(new Error('Invalid id'))
      : axiosClient.get(`/company/${id}`);
  },

  getAll(): Promise<Company[]> {
    return axiosClient.get('/companies');
  },

  create() {},
  update() {},
  delete() {}
};

export default companyApi;
