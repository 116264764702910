import React, { useState } from 'react';
import { CheckboxTag, Suggest, useDebouncedState } from '@brightsource/brightsource-ui-lib';
import { useTranslation } from 'react-i18next';

import { useMetadata } from 'hooks/useMetadata';
import { useSearchJobByTitle } from 'hooks/useJob';
import { useSearchTalentByName } from 'hooks/useTalent';
import { MetadataItem } from 'types/common';
import { TalentSearchFilters } from 'pages/TalentList';

interface FiltersPanelProps {
  filters: TalentSearchFilters;
  pipelineStatusesCounter?: { elId: number; value: string; count: number; checked: boolean }[];
  onFiltersChange: (filters: TalentSearchFilters) => void;
}

function FiltersPannel({ filters, pipelineStatusesCounter, onFiltersChange }: FiltersPanelProps) {
  const { t } = useTranslation();
  const { data: metadata, error, status } = useMetadata();

  const [talentSearchTerm, setTalentSearchTerm, debouncedTalentSearchTerm] = useDebouncedState(
    '',
    200
  );
  const [selectedTalent, setSelectedTalent] = useState<MetadataItem | undefined>(
    filters.talent ? { elId: filters.talent.id, value: filters.talent.name } : undefined
  );

  const [jobSearchTerm, setJobSearchTerm, debouncedJobSearchTerm] = useDebouncedState('', 200);
  const [selectedJob, setSelectedJob] = useState<MetadataItem | undefined>(
    filters.job ? { elId: filters.job.id, value: filters.job.title } : undefined
  );

  const { data: talentsOptions } = useSearchTalentByName(debouncedTalentSearchTerm);
  const { data: jobsOptions } = useSearchJobByTitle(debouncedJobSearchTerm);

  function handlePipelineStatusChange(statusId: number, isChecked: boolean) {
    let updatedFilters = { ...filters };
    if (isChecked) {
      updatedFilters.pipelineStatuses = filters.pipelineStatuses?.filter(
        (pipelineStatus) => pipelineStatus !== statusId
      );
    } else {
      if (updatedFilters.pipelineStatuses) {
        updatedFilters.pipelineStatuses?.push(statusId);
      } else {
        updatedFilters.pipelineStatuses = [statusId];
      }
    }
    onFiltersChange(updatedFilters);
  }

  return (
    <div className="talents-filters-panel-inner">
      <div className="filters-search-section">
        <a
          className="filters-reset-btn"
          role="button"
          onClick={() => onFiltersChange({ pipelineStatuses: [] })}
        >
          {t('talent-search.filters.reset-button', 'Reset')}
        </a>
        <Suggest
          items={talentsOptions?.data.results}
          controlledQuery={talentSearchTerm}
          hasControlledQuery={true}
          placeholder={t('talent-search.filters.search-talent', 'Search candidate') || ''}
          selectedItem={selectedTalent}
          label={t('talent-search.filters.search-talent', 'Search candidate') || ''}
          hiddenLabel={true}
          allowReset={true}
          onControlledQueryChange={(q: string) => setTalentSearchTerm(q)}
          onItemSelect={(selected: MetadataItem) => {
            setSelectedTalent(selected);
            setTalentSearchTerm(selected.value);
            onFiltersChange({
              ...filters,
              talent: { id: `${selected.elId}`, name: `${selected.value}` }
            });
          }}
          onReset={() => {
            setTalentSearchTerm('');
            setSelectedTalent(undefined);
            let updatedFilters = { ...filters };
            delete updatedFilters.talent;
            onFiltersChange(updatedFilters);
          }}
        />
        <Suggest
          items={jobsOptions?.data.results}
          controlledQuery={jobSearchTerm}
          hasControlledQuery={true}
          placeholder={t('talent-search.filters.search-job-title', 'Search job title') || ''}
          selectedItem={selectedJob}
          label={t('talent-search.filters.search-job-title', 'Search job title') || ''}
          hiddenLabel={true}
          allowReset={true}
          onControlledQueryChange={(q: string) => setJobSearchTerm(q)}
          onItemSelect={(selected: MetadataItem) => {
            setSelectedJob(selected);
            setJobSearchTerm(selected.value);
            onFiltersChange({ ...filters, job: { id: `${selected.elId}`, title: selected.value } });
          }}
          onReset={() => {
            setJobSearchTerm('');
            setSelectedJob(undefined);
            let updatedFilters = { ...filters };
            delete updatedFilters.job;
            onFiltersChange(updatedFilters);
          }}
        />
      </div>
      <div className="filters-section">
        {metadata
          ? metadata.data.pipelineStatuses.map((pipelineStatus) => {
              const pipelineStatusCounter = pipelineStatusesCounter?.find(
                (counter) => counter.elId === pipelineStatus.elId
              );
              const isStatusChecked = Boolean(
                filters.pipelineStatuses?.find((status) => status === pipelineStatus.elId)
              );

              return (
                <CheckboxTag
                  key={`pipeline-status-${pipelineStatus.elId}`}
                  label={pipelineStatus.value}
                  tagValue={pipelineStatusCounter?.count || 0}
                  checked={isStatusChecked}
                  onChange={() => handlePipelineStatusChange(+pipelineStatus.elId, isStatusChecked)}
                />
              );
            })
          : null}
      </div>
    </div>
  );
}

export default FiltersPannel;
