import { FocusStyleManager } from '@blueprintjs/core';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { MainLayout } from 'components/layout';
import { PrivateRoute } from 'components/wrapper';

import NewCompany from 'components/post-job/PostJobCompany/NewCompany';
import JobPipelinePage from 'pages/JobPipeline';
import NotFoundPage from 'pages/NotFound';
import PostJobPage from 'pages/PostJob';
import PostJobCongrats from 'pages/PostJob/PostJobCongrats';
import PostJobNew from 'pages/PostJob/PostJobNew';
import SettingsPage from 'pages/Settings';
import TalentPage from 'pages/Talent';
import TalentListPage from 'pages/TalentList';

import './styles/app.scss';

FocusStyleManager.onlyShowFocusOnTabs();

function App() {
  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Navigate to={'/talents'} />} />

          <Route path="settings">
            <Route index element={<Navigate to={'/settings/my-profile'} />} />
            <Route path="*" element={<PrivateRoute element={<SettingsPage />} />} />
          </Route>

          <Route path="talents">
            <Route index element={<PrivateRoute element={<TalentListPage />} />} />
            <Route path=":talentId" element={<PrivateRoute element={<TalentPage />} />} />
          </Route>

          <Route path="jobs" element={<PrivateRoute element={<JobPipelinePage />} />} />

          <Route path="post-job">
            <Route index element={<PostJobPage />} />
            <Route path="new" element={<PostJobNew />} />
            <Route path="new-company" element={<NewCompany />} />
            <Route path="congrats" element={<PostJobCongrats />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;
