import { TalentSearchFilters } from 'pages/TalentList';
import { useQuery } from 'react-query';
import talentApi from 'services/api/talentApi';

export function useTalent(talentId?: string) {
  return useQuery({
    queryKey: ['talent', talentId],
    queryFn: () => talentApi.getById(talentId),
    enabled: Boolean(talentId)
  });
}

export function useSearchTalent(filters: TalentSearchFilters, page: number, perPage: number) {
  return useQuery({
    queryKey: ['searchTalent', filters, page],
    cacheTime: 1 * 60 * 1000, //1 minutes
    refetchInterval: 1 * 60 * 1000, //5 minutes
    queryFn: () => talentApi.search(filters, page, perPage),
    retry: false
  });
}

export function useSearchTalentByName(term: string) {
  return useQuery({
    queryKey: ['searchTalentByName', term],
    cacheTime: 1 * 60 * 1000, //1 minutes
    queryFn: () => talentApi.searchByName(term),
    enabled: Boolean(term)
  });
}
