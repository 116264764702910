import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCV } from 'hooks/useCV';

import './cvTab.scss';

export function CVTab({ cvId }: { cvId?: string }) {
  const { t } = useTranslation();
  const { data: cvData, error, status } = useCV(cvId);

  const docs = [
    {
      uri: 'https://firebasestorage.googleapis.com/v0/b/workspace-dev-c6287.appspot.com/o/cv-test%2Fcv-test.docx?alt=media&token=56918283-c388-45f9-be34-06a54ca96a15'
    },
    {
      uri: 'https://firebasestorage.googleapis.com/v0/b/workspace-dev-c6287.appspot.com/o/cv-test%2FGal%20Efraty%20CV%202021.pdf?alt=media&token=1f6dec71-4560-4e8f-a4bc-cc63181afdb1'
    }
  ];

  return (
    <div className="talent-card-tab cv-tab">
      <div className="talent-card-tab-head">
        <h3 className="talent-card-tab-head-title">{t('talent.cv', 'CV')}</h3>
      </div>
      <div className="cv-wrapper">
        {cvId && cvData?.data.file ? (
          <div dangerouslySetInnerHTML={{ __html: cvData.data.file }}></div>
        ) : (
          <div>No cv</div>
        )}
      </div>
    </div>
  );
}
