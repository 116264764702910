import { Loader, PaginationComponent } from '@brightsource/brightsource-ui-lib';
import { JobPipelineFilters, JobPipelineItem, JobPipelineSubHeader } from 'components/job';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useJobList } from 'hooks/useJob';

import './jobPipelinePage.scss';

function JobPipelinePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(getInitialCurrentPage());
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);

  const { data: jobsPipelineResp, status, error } = useJobList();

  function getInitialCurrentPage() {
    const initialPage = searchParams.get('page');
    if (initialPage) {
      return +initialPage;
    } else {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
      return 1;
    }
  }

  function onPageChange(page: number) {
    setCurrentPage(page);
    searchParams.set('page', `${page}`);
    setSearchParams(searchParams);
  }

  function toggleFiltersPanel() {
    setIsFiltersPanelOpen((prev) => !prev);
  }

  const jobsPipelineMemoList = useMemo(
    () =>
      jobsPipelineResp?.data.meta.totalResults && jobsPipelineResp.data.jobsPipeline.length
        ? jobsPipelineResp.data.jobsPipeline.map((jobPipeline, index) => {
            return (
              <JobPipelineItem
                key={`job-pipeline-${index}`}
                jobPipeline={jobPipeline}
                isJobSelected={selectedJobs.includes(jobPipeline.job.id)}
                onJobSelect={(jobId: string) => {
                  if (selectedJobs?.includes(jobId)) {
                    setSelectedJobs(selectedJobs.filter((job) => job !== jobId));
                  } else {
                    setSelectedJobs([...selectedJobs, jobId]);
                  }
                }}
              />
            );
          })
        : null,
    [jobsPipelineResp?.data.jobsPipeline, selectedJobs]
  );

  return (
    <main className="jobs-pipeline-page">
      <div className="jobs-pipeline-wrapper">
        <JobPipelineSubHeader
          isFiltersPanelOpen={isFiltersPanelOpen}
          toggleFiltersPanel={toggleFiltersPanel}
          jobsCount={jobsPipelineResp?.data.meta.totalResults}
          selectedJobs={selectedJobs}
        />
        <div className="jobs-pipeline-inner">
          <JobPipelineFilters
            isFiltersPanelOpen={isFiltersPanelOpen}
            onFiltersPannelClose={toggleFiltersPanel}
          />
          <div className="jobs-pipeline-list-container">
            <ul className="jobs-pipeline-list">
              {error ? <li>An error occured</li> : null}
              {status === 'loading' ? <Loader /> : null}
              {status !== 'loading' && !error && jobsPipelineResp?.data.meta.totalResults === 0 ? (
                <li>No jobs found</li>
              ) : null}
              {jobsPipelineMemoList}
            </ul>
            {jobsPipelineResp?.data.meta.totalResults ? (
              <PaginationComponent
                current={currentPage}
                onPageChange={onPageChange}
                total={jobsPipelineResp?.data.meta.totalPages || 0}
              />
            ) : null}
          </div>
        </div>
      </div>
    </main>
  );
}

export default JobPipelinePage;
