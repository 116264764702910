import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import { useIsMobile } from '@brightsource/brightsource-ui-lib';

import { ArrowLeftIcon, AvatarIcon, AvatarMultiIcon, BriefcaseIcon } from 'components/svg';
import { ReactRouterDomNavData } from '../../../types/common';

import './settingsNavbar.scss';

export interface SettingsNavbarProps {
  isNavbarOpen: Boolean;
  toggleNavbar: () => void;
}

export function SettingsNavbar({ isNavbarOpen, toggleNavbar }: SettingsNavbarProps) {
  const isMobile = useIsMobile();

  function isActiveClassName(navData: ReactRouterDomNavData) {
    return navData.isActive ? 'nav-link active-nav-link' : 'nav-link';
  }

  return (
    <aside
      className={`settings-navbar ${isMobile ? 'mobile-settings-navbar' : null} ${
        isNavbarOpen ? 'open-settings-navbar' : null
      }`}
    >
      {isMobile ? (
        <a className="toggle-settings-navbar-btn" role={'button'} onClick={() => toggleNavbar()}>
          <ArrowLeftIcon /> {t('settings.nav.close-btn', 'Close')}
        </a>
      ) : null}
      <ul>
        <li>
          <NavLink
            to="/settings/my-profile"
            className={isActiveClassName}
            onClick={() => toggleNavbar()}
          >
            <AvatarIcon />
            {t('settings.nav.profile', 'My Profile')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/settings/user-management"
            className={isActiveClassName}
            onClick={() => toggleNavbar()}
          >
            <AvatarMultiIcon />
            {t('settings.nav.user-mng', 'User Management')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/settings/company-profile"
            className={isActiveClassName}
            onClick={() => toggleNavbar()}
          >
            <BriefcaseIcon />
            {t('settings.nav.company', 'Company Profile')}
          </NavLink>
        </li>
      </ul>
    </aside>
  );
}
