import React, { useState } from 'react';
import { t } from 'i18next';
import { ModalDialog, Button, Input, Select } from '@brightsource/brightsource-ui-lib';

import { Company } from 'types/company';
import { MetadataItem } from 'types/common';
import { useMetadata } from 'hooks/useMetadata';

interface InviteUserModalProps {
  isOpen: boolean;
  closeModal: () => void;
  company: Company;
}

function InviteUserModal({ isOpen, closeModal, company }: InviteUserModalProps) {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const [role, setRole] = useState<MetadataItem>();
  const [roleError, setRoleError] = useState<string>('');

  const { data: metadata, error, status } = useMetadata();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (validateForm()) {
      //TODO: implement POST invitation
    }
  }

  function validateForm() {
    let isValid = true;

    //validate email
    if (!email) {
      isValid = false;

      setEmailError(t('forms.validations.required', 'Required') || '');
    }

    //validate role
    if (!role) {
      isValid = false;

      setRoleError(t('forms.validations.required', 'Required') || '');
    }

    return isValid;
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      title={
        t('settings.users-management.filters.invite.invite-btn', 'Invite People To Join') || ''
      }
      onClose={() => closeModal()}
      className="invite-user-modal"
    >
      <form className={'modal-form'} onSubmit={handleSubmit}>
        <div className="row">
          <Input
            label={t('settings.users-management.filters.invite.email-label', 'Email') || ''}
            type="text"
            placeholder={
              t('settings.users-management.filters.invite.email-placeholder', 'Username') || ''
            }
            blockedPlaceholder={`@${company.domain}`}
            blockedPlaceholderSide={'right'}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            error={emailError}
            allowReset={true}
            onReset={() => {
              setEmail('');
              setEmailError('');
            }}
          />
        </div>
        <div className="row">
          <Select
            items={metadata?.data.userRoles || []}
            label={t('settings.users-management.filters.invite.role-label', 'Role') || ''}
            selectedItem={role}
            placeholder={
              t('settings.users-management.filters.invite.role-placeholder', 'Select...') || ''
            }
            onItemSelect={(item) => {
              setRole(item);
              setRoleError('');
            }}
            error={roleError}
          />
        </div>
        <div className={'modal-form-actions'}>
          <Button variant="secondary" onClick={() => closeModal()}>
            {t('settings.users-management.filters.invite.close', 'Cancel') || ''}
          </Button>
          <Button variant="primary" type="submit">
            {t('settings.users-management.filters.invite.submit', 'Save') || ''}
          </Button>
        </div>
      </form>
    </ModalDialog>
  );
}

export default InviteUserModal;
