import { DefaultImage, DropDownMenu, useIsMobile } from '@brightsource/brightsource-ui-lib';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import HeaderLogo from 'assets/images/logo-with-text.png';
import { useCurrentUser } from 'hooks/useUser';
import NavList from './NavList';

import './header.scss';

export function Header() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile: boolean = useIsMobile();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { data: userData, error, status } = useCurrentUser();

  function toggleNavList(): void {
    setIsMobileNavOpen((prevState: boolean) => !prevState);
    document?.querySelector('body')?.classList?.toggle('sidebar-open');
  }

  const menuItems = [
    {
      text: t('layout.header.user-menu.settings', 'Account Settings'),
      onClick: () => navigate('settings')
    },
    {
      text: t('layout.header.user-menu.logout', 'Log Out'),
      onClick: () => console.log('//TODO: logout')
    }
  ];

  const triggerMenuElement = (
    <a role={'button'} className="user-menu-trigger">
      <DefaultImage
        url={userData?.data.profileImgUrl}
        profileName={`${userData?.data.firstName?.charAt(0)}
				${userData?.data.lastName?.charAt(0)}`}
        alt={'usr'}
        isProfileImage={true}
        isCompanyLogo={false}
      />
    </a>
  );

  return (
    <>
      <header className="main-header">
        <div className="main-header-inner">
          {isMobile && userData ? (
            <a
              className={`toggle-header-btn ${isMobileNavOpen ? 'active' : ''}`}
              role={'button'}
              onClick={() => toggleNavList()}
            >
              <span></span>
              <span></span>
              <span></span>
            </a>
          ) : null}

          <div className="navbar">
            <div className="header-logo">
              <Link to="/">
                <img src={HeaderLogo} alt="header-logo" />
              </Link>
            </div>
            {isMobile ? null : (
              <NavList
                isOpen={isMobileNavOpen}
                toggleNavList={toggleNavList}
                hasUser={!!userData}
              />
            )}
          </div>
          <div className="header-actions">
            {!isMobile && !pathname?.includes('search-talents') && !!userData ? (
              <div className="searchbox-wrapper">
                {/* <SearchboxContainer
									renderFrom={'header'}
									initialLocation={
										userLocation
											? locations.find(location => location.elId == userLocation)
											: null
									}
								/> */}
              </div>
            ) : null}

            {userData && (
              <Link to="post-job" className="post-job-button">
                Post A Job
              </Link>
            )}

            {!!userData ? (
              <div className="user-menu">
                <DropDownMenu
                  triggerElement={triggerMenuElement}
                  items={menuItems}
                  position="bottom-left"
                />
              </div>
            ) : null}
          </div>
        </div>
      </header>
      {isMobile ? (
        <NavList isOpen={isMobileNavOpen} toggleNavList={toggleNavList} hasUser={!!userData} />
      ) : null}
    </>
  );
}
