import { Talent } from 'types/talent';
import axiosClient from './axiosClient';

import { PartialSearchResponse } from 'types/common';
import { TalentSearchFilters } from 'pages/TalentList';

type getTalentResponse = { data: Talent };

type searchTalentResponse = {
  data: {
    pipelineStatusesCounter: { elId: number; value: string; count: number; checked: boolean }[];
    page: number;
    totalPages: number;
    perPage: number;
    talents: Talent[];
    totalTalents: number;
  };
};

const talentApi = {
  getById(talentId?: string): Promise<getTalentResponse> {
    return axiosClient.get(`/talent/${talentId}`);
  },

  search(
    filters: TalentSearchFilters,
    page: number,
    perPage: number
  ): Promise<searchTalentResponse> {
    return axiosClient.post(`/search-talents`, { filters, page, perPage });
  },

  searchByName(term: string): Promise<PartialSearchResponse> {
    return axiosClient.get(`/talent-partial-search-by-name`, { params: { term: term } });
  },

  getAll() {},
  create() {},
  update() {},
  delete() {}
};

export default talentApi;
