import React from 'react';
import { Drawer } from '@blueprintjs/core';
import { Loader, useIsMobile } from '@brightsource/brightsource-ui-lib';

import { Talent } from 'types/talent';
import TalentNavPanel from './TalentNavPanel';
import { useTalent } from 'hooks/useTalent';

import './talentPanel.scss';
import { TalentCard } from '../TalentCard';

export interface TalentPanelPerops {
  isOpen: boolean;
  talentId?: string | undefined;
  onClose: () => void;
  totalTalents?: number;
  goNextTalent: () => void;
  goPrevTalent: () => void;
}

export function TalentPanel({
  isOpen,
  talentId,
  onClose,
  totalTalents,
  goNextTalent,
  goPrevTalent
}: TalentPanelPerops) {
  const isMobile: boolean = useIsMobile();
  const { data: talent, status } = useTalent(talentId);

  return (
    <Drawer
      isOpen={isOpen}
      className={'talent-panel'}
      onClose={onClose}
      size={isMobile ? '100%' : '95%'}
      canOutsideClickClose={true}
      position={'right'}
      transitionDuration={500}
      portalClassName={'talent-panel-portal'}
      backdropClassName={'talent-panel-backdrop'}
    >
      {status === 'loading' ? <Loader /> : null}

      {talent?.data ? (
        <>
          <TalentNavPanel
            onClose={onClose}
            talent={talent.data}
            totalTalents={totalTalents}
            goNextTalent={goNextTalent}
            goPrevTalent={goPrevTalent}
          />
          <TalentCard talent={talent.data} />
        </>
      ) : (
        <div>Please select candidate to view</div>
      )}
    </Drawer>
  );
}
