import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '@brightsource/brightsource-ui-lib';

import { ArrowRightIcon, FiltersIcon } from 'components/svg';

import './subheaderPanel.scss';

export interface SubheaderPanelProps {
  talentsCount?: number;
  isFiltersPanelOpen: boolean;
  toggleFiltersPanel: () => void;
  selectedTalents?: string[];
}

export function SubheaderPanel({
  talentsCount = 0,
  isFiltersPanelOpen,
  toggleFiltersPanel,
  selectedTalents
}: SubheaderPanelProps) {
  const { t } = useTranslation();
  const isMobile: boolean = useIsMobile();

  return (
    <div className="subheader-pannel">
      <div>
        <a
          role={'button'}
          className={`toggle-filters-panel-button ${isFiltersPanelOpen ? 'open' : null}`}
          onClick={() => toggleFiltersPanel()}
        >
          {isMobile ? <FiltersIcon /> : <ArrowRightIcon />}
        </a>
        <span className="talents-count">
          {talentsCount} {t('talent-search.candidates-count', 'Candidates')}
        </span>
      </div>
      {selectedTalents?.length ? (
        <div className="bulk-talents">
          {selectedTalents.length} {t('talent-search.bulk', 'selected')}
        </div>
      ) : null}
    </div>
  );
}
