import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, CheckBox, formatDateToString } from '@brightsource/brightsource-ui-lib';

import { JobPipeline } from 'types/job';
import { StatusIcon } from 'components/svg';

import './jobPipelineItem.scss';

export interface JobPipelineItemProps {
  jobPipeline: JobPipeline;
  onJobSelect: (jobId: string) => void;
  isJobSelected: boolean;
}

export function JobPipelineItem({ jobPipeline, onJobSelect, isJobSelected }: JobPipelineItemProps) {
  const { t } = useTranslation();

  const jobLocation: string | undefined =
    jobPipeline.job?.location?.city && jobPipeline.job?.location?.country
      ? `${jobPipeline.job.location.city}, ${jobPipeline.job.location.country}`
      : '';

  return (
    <li className="job-pipeline-item">
      <div className="checkbox-wrapper">
        <CheckBox checked={isJobSelected} onChange={() => onJobSelect(jobPipeline.job.id)} />
      </div>
      <div className="job-pipeline-item-inner">
        <div className="job-data">
          <div className="job-data-inner">
            <h3>
              <Link to={`/job/${jobPipeline.job.id}`}>{jobPipeline.job.title}</Link>
            </h3>
            <ul>
              <li className="location">{jobLocation}</li>
              <li className="date">
                {formatDateToString(jobPipeline.job.datePosted, 'MMM D, YYYY')}
              </li>
              <li className={`status status-${jobPipeline.job.status}`}>
                <StatusIcon />
                {jobPipeline.job.status} {t('jobs-pipeline.item.position-status', 'Position')}
              </li>
            </ul>
          </div>
          <div className="job-data-actions">
            <Link className="reusable-button secondary-button" to={`/job/${jobPipeline.job.id}`}>
              {t('jobs-pipeline.item.edit-button', 'Edit Job')}
            </Link>
            <Button variant="secondary">Status</Button>
          </div>
        </div>
        <div className="job-pipeline">
          <ul className="pipeline-section">
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.leads}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.leeds', 'Leeds')}</p>
            </li>
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.new}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.new', 'New')}</p>
            </li>
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.details}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.details', 'Details')}</p>
            </li>
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.inProgress}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.in-progress', 'In-Progress')}</p>
            </li>
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.interview}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.interview', 'Interview')}</p>
            </li>
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.offers}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.offers', 'Offers')}</p>
            </li>
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.hired}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.hired', 'Hired')}</p>
            </li>
          </ul>
          <ul className="pipeline-section">
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.declined}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.declined', 'Declined')}</p>
            </li>
            <li>
              <p className="pipeline-number">{jobPipeline.pipeline.withdrawn}</p>
              <p className="pipeline-type">{t('jobs-pipeline.item.withdrawn', 'Withdrawn')}</p>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
}
