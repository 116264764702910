import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile, DropDownMenu } from '@brightsource/brightsource-ui-lib';
import { ArrowRightIcon, FiltersIcon, SortMenuIcon } from 'components/svg';

import './jobPipelineSubHeader.scss';

export interface JobPipelineSubHeaderProps {
  isFiltersPanelOpen: boolean;
  toggleFiltersPanel: () => void;
  jobsCount?: number;
  selectedJobs?: string[];
}

export function JobPipelineSubHeader({
  isFiltersPanelOpen,
  toggleFiltersPanel,
  jobsCount,
  selectedJobs
}: JobPipelineSubHeaderProps) {
  const isMobile: boolean = useIsMobile();
  const { t } = useTranslation();

  return (
    <div className="jobs-pipeline-subheader">
      <div>
        <a
          role={'button'}
          className={`toggle-filters-panel-button ${isFiltersPanelOpen ? 'open' : null}`}
          onClick={() => toggleFiltersPanel()}
        >
          {isMobile ? <FiltersIcon /> : <ArrowRightIcon />}
        </a>
        {jobsCount != undefined ? (
          <span className="jobs-count">
            {jobsCount} {t('jobs-pipeline.subheader.jobs-count', 'Jobs')}
          </span>
        ) : null}
        <div className="sort-menu">
          <DropDownMenu
            items={[
              {
                text: 'date',
                onClick: () => console.log('sort by date'),
                active: true
              }
            ]}
            position="bottom"
            triggerElement={
              <a
                className="sort-options-menu-trigger"
                role="button"
                key={'sort-options-menu-trigger'}
              >
                <SortMenuIcon />
                {t('jobs-pipeline.subheader.sort', 'Sort by')}
              </a>
            }
          />
          <span className="selected-sort-option">(date)</span>
        </div>
      </div>
      <div>
        {selectedJobs?.length ? (
          <span>
            {selectedJobs.length} {t('jobs-pipeline.subheader.selected-jobs', 'Selected')}
          </span>
        ) : null}
      </div>
    </div>
  );
}
