import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useIsMobile, TabsList, TabId, TabObject } from '@brightsource/brightsource-ui-lib';

import { ArrowRightIcon } from 'components/svg';
import { User } from 'types/user';
import PersonalSettingsHeader from './PersonalSettingsHeader';
import LocaleTab from './tabs/LocaleTab';
import NotificationsTab from './tabs/NotificationsTab';
import PasswordTab from './tabs/PasswordTab';
import PersonalInfoTab from './tabs/PersonalInfoTab';

import './personalSettingsCard.scss';

export interface PersonalSettingsCardProps {
  user?: User;
  toggleNavbar: () => void;
  userStatus?: string;
}

export function PersonalSettingsCard({
  user,
  userStatus,
  toggleNavbar
}: PersonalSettingsCardProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTabId, setSelectedTabId] = useState<TabId>('personal-info');

  useEffect(() => {
    const tabId = searchParams.get('tab');
    setSelectedTabId(tabId ? tabId : 'personal-info');
  }, [searchParams]);

  const handleTabChange = (newTabId: TabId) => {
    setSelectedTabId(newTabId);
    setSearchParams({ tab: `${newTabId}` });
  };

  const tabItems: TabObject[] = [
    {
      id: 'personal-info',
      title: t('settings.profile.tabs.personal.tab-title', 'Personal Info'),
      panel: <PersonalInfoTab user={user} />
    },

    {
      id: 'password',
      title: t('settings.profile.tabs.password.tab-title', 'Password'),
      panel: <PasswordTab userEmail={user?.email} />
    },
    {
      id: 'notifications',
      title: t('settings.profile.tabs.notifications.tab-title', 'Notifications'),
      panel: <NotificationsTab user={user} />
    },
    {
      id: 'locale',
      title: t('settings.profile.tabs.locale.tab-title', 'Language and Region'),
      panel: <LocaleTab user={user} />
    }
  ];

  return (
    <div className="settings-card profile-card">
      {isMobile ? (
        <a className="settings-navbar-toggler" role={'button'} onClick={() => toggleNavbar()}>
          <ArrowRightIcon />
        </a>
      ) : null}
      <PersonalSettingsHeader user={user} userStatus={userStatus} />

      <TabsList
        id="profile-tabs"
        className="settings-tabs"
        selectedTabId={selectedTabId}
        onChange={handleTabChange}
        tabs={tabItems}
      />
    </div>
  );
}
