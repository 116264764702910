import Logo from 'assets/images/logo-with-text.png';
import React from 'react';
import { useQuery } from 'react-query';
import postJobApi from 'services/api/postJobApi';
import VerificationFooter from './VerificationFooter';

function ResendCode() {
  // temp
  const email = 'hau@brightsource.com';

  const { data, error, isLoading } = useQuery('resendCode', () => postJobApi.resendCode(email), {
    enabled: !!email
  });

  return (
    <React.Fragment>
      <img className="verification-logo" src={Logo} alt="" />
      <h6 className="verification-title">Verify your email</h6>

      <p className="verification-description multi-line">
        <span>
          Dear [User],
          <br />
          Thank you for signing up with Brightsource.com! We're thrilled to have you as a member of
          our community. To ensure the security of your account and to provide you with the best
          possible experience on our platform, we need to verify your email address.
        </span>
        <span>
          Please click or paste in the following code to verify your email address:&nbsp;
          {data ? <a className="verification-link">{data.code}</a> : '------'}
          <br />
          Once you've clicked on the link, you'll be directed to a page confirming that your email
          has been verified. If you encounter any issues during the verification process, please
          contact our support team at support@brightsource.com
        </span>

        <span>
          Thank you for taking the time to complete the email verification process. We look forward
          to seeing you on Brightsource.com soon!
        </span>
        <span>
          Best regards,
          <br />
          The Brightsource.com Team
        </span>
      </p>

      <VerificationFooter />
    </React.Fragment>
  );
}

export default ResendCode;
