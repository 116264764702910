import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalDialog, useIsMobile } from '@brightsource/brightsource-ui-lib';

import { FiltersIcon } from 'components/svg';
import FiltersPannel from './FiltersPannel';

import './talentSearchFilters.scss';
import { TalentSearchFilters } from 'pages/TalentList';

export interface TalentSearchFiltersProps {
  isFiltersPanelOpen: boolean;
  onFiltersPannelClose: () => void;
  pipelineStatusesCounter?: { elId: number; value: string; count: number; checked: boolean }[];
  filters: TalentSearchFilters;
  onFiltersChange: (filters: TalentSearchFilters) => void;
}

export function TalentSearchFiltersPanel({
  isFiltersPanelOpen,
  onFiltersPannelClose,
  pipelineStatusesCounter,
  filters,
  onFiltersChange
}: TalentSearchFiltersProps) {
  const isMobile: boolean = useIsMobile();
  const { t } = useTranslation();

  return isMobile ? (
    <ModalDialog
      isOpen={isFiltersPanelOpen}
      onClose={() => onFiltersPannelClose()}
      title={t('talent-search.filters.modal-title', 'Filters') || ''}
      titleIcon={<FiltersIcon />}
      className={'talent-search-filters-modal'}
    >
      <FiltersPannel
        filters={filters}
        pipelineStatusesCounter={pipelineStatusesCounter}
        onFiltersChange={onFiltersChange}
      />
    </ModalDialog>
  ) : (
    <div className={`talent-search-filters-panel ${isFiltersPanelOpen ? 'open' : null}`}>
      <FiltersPannel
        filters={filters}
        pipelineStatusesCounter={pipelineStatusesCounter}
        onFiltersChange={onFiltersChange}
      />
    </div>
  );
}
