import React, { useState } from 'react';
import { t } from 'i18next';
import {
  Button,
  MultiSelectCheckboxForm,
  MiniSearchbox,
  useIsMobile
} from '@brightsource/brightsource-ui-lib';

import { Company } from 'types/company';
import { ArrowRightIcon } from 'components/svg';
import { userManagementFilters } from '..';
import InviteUserModal from './InviteUserModal';
import { useMetadata } from 'hooks/useMetadata';

import './userManagementFiltersPanel.scss';

interface UserManagementFiltersPanelProps {
  toggleNavbar: () => void;
  filters?: userManagementFilters;
  onFiltersChange: (filters: userManagementFilters) => void;
  company?: Company;
}

function UserManagementFiltersPanel({
  toggleNavbar,
  filters,
  onFiltersChange,
  company
}: UserManagementFiltersPanelProps) {
  const isMobile: boolean = useIsMobile();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

  const { data: metadata, error, status } = useMetadata();

  return (
    <div className="user-management-filters-panel">
      {isMobile ? (
        <a className="settings-navbar-toggler" role={'button'} onClick={() => toggleNavbar()}>
          <ArrowRightIcon />
        </a>
      ) : null}
      <div className="select-group">
        <MultiSelectCheckboxForm
          items={metadata?.data.userRoles || []}
          showCount={true}
          filterable={false}
          internalSearchPlaceholder={
            t(
              'settings.users-management.filters.role.internal-search-placeholder',
              'Search Role'
            ) || ''
          }
          placeholder={
            t('settings.users-management.filters.role.placeholder', 'Filter by role') || ''
          }
          allowClearAll={true}
          submitBtnText={t('settings.users-management.filters.role.submit', 'Show Results') || ''}
          closeBtnText={t('settings.users-management.filters.role.close', 'Cancel') || ''}
          selectedItems={filters?.roles || []}
          handleSubmit={(selectedItems) => {
            let updatedFilters: userManagementFilters = { ...filters };
            updatedFilters.roles = selectedItems;
            onFiltersChange(updatedFilters);
          }}
        />
        <MultiSelectCheckboxForm
          items={metadata?.data.userStatuses || []}
          showCount={true}
          filterable={false}
          internalSearchPlaceholder={
            t(
              'settings.users-management.filters.status.internal-search-placeholder',
              'Search status'
            ) || ''
          }
          placeholder={
            t('settings.users-management.filters.status.placeholder', '') || 'Filter by status'
          }
          allowClearAll={true}
          submitBtnText={t('settings.users-management.filters.status.submit', 'Show Results') || ''}
          closeBtnText={t('settings.users-management.filters.status.close', 'Cancel') || ''}
          selectedItems={filters?.statuses || []}
          handleSubmit={(selectedItems) => {
            let updatedFilters: userManagementFilters = { ...filters };
            updatedFilters.statuses = selectedItems;
            onFiltersChange(updatedFilters);
          }}
        />
      </div>
      <div className="search-group">
        <MiniSearchbox
          controlledComponent={false}
          allowClearAll={true}
          placeholder={
            t('settings.users-management.filters.searchbox.placeholder', 'Search by name') || ''
          }
          executeSearch={(searchTerm) => {
            let updatedFilters: userManagementFilters = { ...filters };
            updatedFilters.searchTerm = searchTerm;
            onFiltersChange(updatedFilters);
          }}
        />
        <Button
          type="button"
          role="Button"
          onClick={() => setIsInviteModalOpen((prev) => !prev)}
          isLoading={!!!company}
        >
          {t('settings.users-management.filters.invite.invite-btn', 'Invite')}
        </Button>
      </div>
      {company ? (
        <InviteUserModal
          isOpen={isInviteModalOpen}
          closeModal={() => setIsInviteModalOpen((prev) => !prev)}
          company={company}
        />
      ) : null}
    </div>
  );
}

export default UserManagementFiltersPanel;
