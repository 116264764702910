import React from 'react';
import { EditableControlValue } from '@brightsource/brightsource-ui-lib';

import { User } from '../../../../types/user';

export interface LocaleTabProps {
	user?: User;
}

function LocaleTab({ user }: LocaleTabProps) {
	return (
		<div className='settings-card-tab'>
			<div className='settings-card-tab-inner'>
				<div className='row'>
					<EditableControlValue
						label='Languages'
						value={
							user?.languages?.length
								? user.languages
										.map((lang, index) => {
											return `${lang.value}${
												lang.level ? ' (' + lang.level + ')' : ''
											}${index !== user.languages!.length - 1 ? ', ' : '.'}`;
										})
										.join('')
								: ''
						}
						onEdit={() => console.log('Edit Languages')}
					/>
				</div>
				<div className='row'>
					<EditableControlValue
						label='Region'
						value={user?.location?.country || ''}
						onEdit={() => console.log('Edit region')}
					/>
				</div>
			</div>
		</div>
	);
}

export default LocaleTab;
