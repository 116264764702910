import { yupResolver } from '@hookform/resolvers/yup';
import { AutoComplete, AutoCompleteItem } from 'components/common';
import { InputField } from 'components/form';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { PostJobCompanyData, PostJobOrganizationSize } from 'types/post-job';
import * as yup from 'yup';
import { NewCompanyStepProps } from './NewCompany';

const schema = yup.object().shape({
  location: yup.string().required('Missing location'),
  websiteAddress: yup.string().url('Please enter a valid link')
});

function NewCompanyDetails({ formId, formData, onSubmit }: NewCompanyStepProps) {
  const [industry, setIndustry] = useState<AutoCompleteItem | null>(null);
  const [organizationSize, setOrganizationSize] = useState<AutoCompleteItem | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isSubmitted }
  } = useForm<PostJobCompanyData>({
    defaultValues: formData ?? undefined,
    resolver: yupResolver(schema)
  });

  const handleFormSubmit = (payload: PostJobCompanyData) => {
    if(!industry) return

    const data: PostJobCompanyData = {
      ...payload,
      industries: industry?.value ?? '',
      organizationSize: organizationSize?.value as PostJobOrganizationSize | undefined
    };

    onSubmit(data);
  };

  return (
    <form id={formId} onSubmit={handleSubmit(handleFormSubmit)} >
      <InputField label="Location" name="location" control={control} />
      
      <AutoComplete
        label="Organization size"
        list={Object.values(PostJobOrganizationSize).map((value, index) => ({ id: index, value }))}
        selectedItem={organizationSize}
        updateSelectedItem={setOrganizationSize}
        placeholder="2-10"
      />

      <InputField
        label="Website"
        name="websiteAddress"
        control={control}
        placeholder="https://brightsource.com"
      />

      <AutoComplete
        label="Industries"
        list={[
          { id: 1, value: 'Industry 1' },
          { id: 2, value: 'Industry 2' },
          { id: 3, value: 'Industry 3' }
        ]}
        selectedItem={industry}
        updateSelectedItem={setIndustry}
        placeholder=""
        error={isSubmitted && !industry ? 'Missing company industry' : undefined}
      />
    </form>
  );
}

export default NewCompanyDetails;
