import React, { useState } from 'react';
import { DefaultImage, Loader } from '@brightsource/brightsource-ui-lib';

import { Company } from 'types/company';

interface CompanySettingsHeaderProps {
  company?: Company;
  companyStatus?: string;
}

function CompanySettingsHeader({ company, companyStatus }: CompanySettingsHeaderProps) {
  const [isEditImgModalOpen, setIsEditImgModalOpen] = useState<boolean>(false);

  return (
    <div className="settings-card-header profile-header">
      <div className="profile-picture">
        {companyStatus === 'loading' ? (
          <Loader />
        ) : (
          <a role={'button'} onClick={() => setIsEditImgModalOpen((prev: boolean) => !prev)}>
            <DefaultImage
              url={company?.logoUrl}
              isProfileImage={false}
              isCompanyLogo={true}
              alt="company"
            />
          </a>
        )}
      </div>
    </div>
  );
}

export default CompanySettingsHeader;
