import { Button } from '@brightsource/brightsource-ui-lib';
import Logo from 'assets/images/logo-with-text.png';
import React from 'react';
import VerificationFooter from './VerificationFooter';

function ExistingUser() {
  return (
    <React.Fragment>
      <img className="verification-logo" src={Logo} alt="" />
      <h6 className="verification-title">Just a little more step</h6>

      <p className="verification-description multi-line">
        <span>
          Dear [User],
          <br />
          We're thrilled to inform you that you've successfully signed up for Brightsource using
          your current email address! As a member of our community, you'll have access to a wide
          range of resources, tools, and networking opportunities designed to help you grow and
          succeed.
        </span>

        <span>
          To complete your sign-up process and fully activate your account, simply follow the link
          below and provide us with a few more details. It should take no more than a few minutes,
          and we promise it will be worth your while!
        </span>

        <span>Simply click on the link below to continue with the sign-up process:</span>
      </p>

      <Button classNames="verification-button center">Continue with your Application</Button>

      <p className="verification-description multi-line">
        <span>
          If you have any questions or need assistance with the sign-up process, please don't
          hesitate to contact our support team at [Insert Support Email Here]. We're here to help
          you every step of the way.
        </span>

        <span>
          Thank you for choosing Brightsource as your partner in success. We look forward to working
          with you.
        </span>

        <span>
          Best regards,
          <br />
          The Brightsource Team
        </span>
      </p>

      <VerificationFooter />
    </React.Fragment>
  );
}

export default ExistingUser;
