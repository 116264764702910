import { Button, Input } from '@brightsource/brightsource-ui-lib';
import { t } from 'i18next';
import React, { useState } from 'react';
import { formValidation } from 'utils/validation';
// import { doSignInWithEmailAndPassword, doPasswordUpdate } from '../../../../services/auth.service';

export interface PasswordTabProps {
  userEmail?: string;
}

function PasswordTab({ userEmail }: PasswordTabProps) {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [oldPasswordError, setOldPasswordError] = useState<string>('');

  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');

  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
  const [newPasswordConfirmationError, setNewPasswordConfirmationError] = useState<string>('');

  const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(false);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (validateForm()) {
      saveNewPassword();
    }
  }

  function validateForm(): boolean {
    let isValid = true;

    //oldPassword
    if (!formValidation.required(oldPassword)) {
      setOldPasswordError(t('forms.validations.required', 'Required') || '');
      isValid = false;
    }

    //newPassword
    if (!isPasswordValid(newPassword)) {
      setNewPasswordError('Invalid new password');
      isValid = false;
    }
    if (!formValidation.required(newPassword)) {
      setNewPasswordError(t('forms.validations.required', 'Required') || '');
      isValid = false;
    }

    //newPasswordConfirmation
    if (!isPasswordValid(newPasswordConfirmation)) {
      setNewPasswordConfirmationError(t('auth.password.invalid', 'Invalid password') || '');
      isValid = false;
    }

    if (newPasswordConfirmation !== newPassword) {
      setNewPasswordConfirmationError(t('auth.password.no-match', "Passwords don't match") || '');
      isValid = false;
    }
    if (!formValidation.required(newPasswordConfirmation)) {
      setNewPasswordConfirmationError(t('forms.validations.required', 'Required') || '');
      isValid = false;
    }

    return isValid;
  }

  function saveNewPassword() {
    if (arePasswordsMatch && isNewPasswordValid) {
      //TODO: implement!
      // doSignInWithEmailAndPassword(userEmail, oldPassword)
      // 	.then(resp => {
      // 		doChangePassword(newPassword);
      // 		console.log('Successfull sign in', resp);
      // 	})
      // 	.catch(error => {
      // 		console.log('error in doSignInWithEmailAndPassword', error);
      // 		toastFactory(eToastTypes.ERROR, t('auth.password.old-password-incorrect', 'Old password is incorrect, please try again.') || '');
      // 	});
    }
  }

  function doChangePassword(password: string) {
    //TODO: implement!
    // doPasswordUpdate(password)
    // 	.then(() => {
    // 		setNewPassword('');
    // 		setNewPasswordConfirmation('');
    // 		toastFactory(eToastTypes.SUCCESS, 'Password has changed successfully');
    // 	})
    // 	.catch(error => {
    // 		console.log('Error in doPasswordUpdate', error);
    // 		toastFactory(eToastTypes.ERROR, 'Unable to change password, please try again later.');
    // 	});
  }

  const arePasswordsMatch: boolean =
    newPassword === newPasswordConfirmation && !!newPassword && !!newPasswordConfirmation;

  function isPasswordValid(password: string) {
    if (
      password &&
      password.length >= 8 &&
      formValidation.validPasswordOneUpperCaseLetter(password) &&
      formValidation.validPasswordOneLowerCaseLetter(password) &&
      formValidation.validPasswordOneNumber(password) &&
      formValidation.validPasswordSpecialCharacter(password)
    ) {
      return true;
    }

    return false;
  }

  function onNewPasswordChange(password: string) {
    setNewPassword(password);

    if (isPasswordValid(password)) {
      setIsNewPasswordValid(true);
    } else {
      setIsNewPasswordValid(false);
    }
  }

  function renderValidatePasswordPanel() {
    return (
      <div className="validatePasswordPanel">
        <ul>
          <li className={`${newPassword?.length >= 8 ? 'valid' : ''}`}>
            {t('auth.password.min-chars', 'Must be at least 8 characters long')}
          </li>
          <li
            className={`${
              formValidation.validPasswordOneLowerCaseLetter(newPassword) ? 'valid' : ''
            }`}
          >
            {t('auth.password.min-lowercase', 'At least 1 lowercase letters')}
          </li>
          <li
            className={`${
              formValidation.validPasswordOneUpperCaseLetter(newPassword) ? 'valid' : ''
            }`}
          >
            {t('auth.password.min-uppercase', 'At least 1 uppercase letters')}
          </li>
          <li className={`${formValidation.validPasswordOneNumber(newPassword) ? 'valid' : ''}`}>
            {t('auth.password.min-number', 'At least 1 number')}
          </li>
          <li
            className={`${
              formValidation.validPasswordSpecialCharacter(newPassword) ? 'valid' : ''
            }`}
          >
            {t('auth.password.min-special', 'At least one special character')}
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="settings-card-tab">
      <div className="settings-card-tab-inner">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <Input
              label={t('auth.password.old-pass-label', 'Old Password') || ''}
              placeholder={t('auth.password.old-pass-placeholder', 'Enter old password') || ''}
              type="password"
              value={oldPassword}
              onChange={(e) => {
                setOldPasswordError('');
                setOldPassword(e.target.value);
              }}
              allowReset={true}
              onReset={() => {
                setOldPasswordError('');
                setOldPassword('');
              }}
              error={oldPasswordError}
            />
          </div>
          <div className="row">
            <Input
              type="password"
              label={t('auth.password.new-pass-label', 'New Password') || ''}
              placeholder={t('auth.password.new-pass-placeholder', 'Enter new password') || ''}
              disabled={!oldPassword}
              value={newPassword}
              onChange={(e) => {
                setNewPasswordError('');
                onNewPasswordChange(e.target.value);
              }}
              allowReset={true}
              onReset={() => {
                setNewPasswordError('');
                setNewPassword('');
              }}
              error={newPasswordError}
            />
            {renderValidatePasswordPanel()}
          </div>
          <div className="row">
            <Input
              type="password"
              label={t('auth.password.conf-pass-label', 'Confirm Password') || ''}
              placeholder={t('auth.password.conf-pass-placeholder', 'Confirm new password') || ''}
              disabled={!oldPassword || !newPassword}
              value={newPasswordConfirmation}
              onChange={(e) => {
                setNewPasswordConfirmationError('');
                setNewPasswordConfirmation(e.target.value);
              }}
              allowReset={true}
              onReset={() => {
                setNewPasswordConfirmationError('');
                setNewPasswordConfirmation('');
              }}
              error={newPasswordConfirmationError}
            />
          </div>
          <div className="row form-actions">
            <Button variant="primary" type="submit">
              {t('auth.password.save', 'Save')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PasswordTab;
