import { EditableText } from '@blueprintjs/core';
import { AutoComplete } from 'components/common';
import { RESUME_CHANNEL_LIST } from 'constants/postJob';
import { usePostJob } from 'hooks/usePostJob';
import { useMemo, useState } from 'react';
import { PostJobResumeChannel } from 'types/post-job';
import EditableContainer from './EditableContainer';

function EditableResumeChannel() {
  const { jobData, updateJobData } = usePostJob();

  const [resumeChannel, resumeChannelValue] = useMemo(() => {
    if (!jobData) return [PostJobResumeChannel.EMAIL, ''];
    return [jobData.resumeChannel.channel, jobData.resumeChannel.value];
  }, [jobData]);

  const [channel, setChannel] = useState(resumeChannel);
  const [value, setValue] = useState(resumeChannelValue);

  const handleCancel = () => {
    setChannel(resumeChannel);
    setValue(resumeChannelValue);
  };

  const handleSave = () => {
    if (!jobData) return;

    updateJobData({
      ...jobData,
      resumeChannel: { channel, value }
    });
  };

  const renderContent = (isEditMode: boolean) => (
    <div className="editable-row">
      {isEditMode ? (
        <AutoComplete
          list={RESUME_CHANNEL_LIST}
          selectedItem={RESUME_CHANNEL_LIST.find((field) => field.value === channel) ?? null}
          updateSelectedItem={(item) => item && setChannel(item.value as PostJobResumeChannel)}
          disabledSearch
        />
      ) : (
        <span className="editable-value">{resumeChannel}</span>
      )}

      <EditableText
        className="editable-value resume-channel-value"
        value={value}
        onChange={setValue}
        disabled={!isEditMode}
        isEditing={isEditMode}
        placeholder={isEditMode || Boolean(resumeChannelValue) ? '' : 'None'}
      />
    </div>
  );

  return (
    <EditableContainer
      label="How would you like to receive your applicants"
      onSave={handleSave}
      onCancel={handleCancel}
      renderContent={renderContent}
    />
  );
}

export default EditableResumeChannel;
