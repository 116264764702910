import { Icon, InputGroup, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import cn from 'classnames';
import { CreateIcon } from 'components/svg';
import { CSSProperties, ChangeEvent, FocusEvent, useMemo, useState } from 'react';

import './autoComplete.scss';

export interface AutoCompleteItem {
  id: number | string;
  value: string;
  label?: string;
  icon?: string;
}

export interface AutoCompleteProps {
  list: AutoCompleteItem[];
  selectedItem: AutoCompleteItem | null;
  updateSelectedItem: (item: AutoCompleteItem | null) => void;

  label?: string;
  hideIcon?: boolean;
  disabledSearch?: boolean;
  placeholder?: string;
  error?: string;
  onRemove?: () => void;
  onCreate?: () => void;
}

export function AutoComplete(props: AutoCompleteProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const {
    list,
    selectedItem,
    updateSelectedItem,
    label = '',
    hideIcon = false,
    disabledSearch = false,
    placeholder = 'Search by name',
    error,
    onRemove,
    onCreate
  } = props;

  const showIcon = !hideIcon && list.some((item) => !!item.icon);

  // filter by name
  const filteredList = useMemo(
    () =>
      list.filter((item) => {
        const name = item.value.toUpperCase();
        return Boolean(name) && name.includes(searchValue.toUpperCase());
      }),
    [searchValue, list]
  );

  const hasError = useMemo(() => !!error && !isOpen, [error, isOpen]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabledSearch) return;
    !event.target.value && updateSelectedItem(null);
    setSearchValue(event.target.value);
  };

  const onDismissSearchInput = () => {
    setSearchValue('');
    setIsOpen(false);
  };

  const onSelectItem = (item: AutoCompleteItem) => {
    updateSelectedItem(item);
    onDismissSearchInput();
  };

  const handleRemove = () => {
    onRemove && onRemove();
    onDismissSearchInput();
  };

  const handleSearchBlur = (event: FocusEvent<HTMLInputElement>) => {
    // check if select a menu item
    if (event.relatedTarget && event.relatedTarget.role === 'menuitem') return;

    onDismissSearchInput();
  };

  const getItemIcon = (item: AutoCompleteItem, isSmall = false) => {
    if (!showIcon) return;

    const style: CSSProperties = {
      width: isSmall ? 24 : 30,
      height: isSmall ? 24 : 30,
      borderRadius: 8,
      objectFit: 'cover'
    };

    if (!item.icon) return <div style={{ ...style, backgroundColor: '#c7c7c7' }} />;
    return <img src={item.icon} alt={item.value} style={style} />;
  };

  return (
    <div className="autocomplete">
      {label && <span className="autocomplete-label">{label}</span>}

      <Popover2
        fill
        isOpen={isOpen}
        className="autocomplete-input"
        popoverClassName="autocomplete-popover"
        placement="bottom-start"
        matchTargetWidth
        autoFocus={false}
        usePortal={false}
        modifiers={{
          arrow: { enabled: false },
          offset: {
            enabled: true,
            options: { offset: [0, 10] }
          }
        }}
        content={
          <Menu className="autocomplete-list">
            {onCreate && (
              <MenuItem
                text="Create new company"
                className="autocomplete-item create"
                onClick={onCreate}
                icon={<CreateIcon />}
              />
            )}

            {filteredList.map((item) => (
              <MenuItem
                key={item.id}
                text={item.value}
                label={item.label}
                className="autocomplete-item"
                icon={getItemIcon(item)}
                onClick={() => onSelectItem(item)}
              />
            ))}

            {onRemove && (
              <MenuItem text="Remove" className="autocomplete-item remove" onClick={handleRemove} />
            )}
          </Menu>
        }
      >
        <div className={cn('autocomplete-input-container', { 'has-error': hasError })}>
          <InputGroup
            value={searchValue || (selectedItem ? selectedItem.value : '')}
            onChange={handleSearchChange}
            onBlur={handleSearchBlur}
            onFocus={() => setIsOpen(true)}
            leftElement={selectedItem ? getItemIcon(selectedItem, true) : undefined}
            rightElement={<Icon icon="chevron-down" onClick={() => setIsOpen((x) => !x)} />}
            placeholder={placeholder}
            style={{
              caretColor: disabledSearch ? 'transparent' : 'auto'
            }}
          />

          {hasError && <p className="autocomplete-input-error">{error}</p>}
        </div>
      </Popover2>
    </div>
  );
}
