import React from 'react';
import { t } from 'i18next';
import { EditableControlValue, formatDateToString } from '@brightsource/brightsource-ui-lib';

import { User } from '../../../../types/user';

export interface PersonalInfoTabProps {
  user?: User;
}

function PersonalInfoTab({ user }: PersonalInfoTabProps) {
  const userLocation: string =
    user?.location?.city && user?.location?.country
      ? `${user.location.city}, ${user.location.country}`
      : '';

  const userPhone: string = `${user?.phone?.dialCode ? user.phone.dialCode : ''}${
    user?.phone?.number ? user.phone.number : ''
  }`;

  return (
    <div className="settings-card-tab">
      <div className="settings-card-tab-inner">
        <div className="row">
          <EditableControlValue
            label={t('settings.profile.tabs.personal.first-name', 'First name')}
            value={user?.firstName || ''}
            onEdit={() => console.log('Edit firstName')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.profile.tabs.personal.last-name', 'Last name')}
            value={user?.lastName || ''}
            onEdit={() => console.log('Edit lastName')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.profile.tabs.personal.title', 'Title')}
            value={user?.title?.value || ''}
            onEdit={() => console.log('Edit title')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.profile.tabs.personal.current-location', 'My Current Location')}
            value={userLocation || ''}
            onEdit={() => console.log('Edit current location')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.profile.tabs.personal.email', 'Email')}
            value={user?.email || ''}
            onEdit={() => console.log('Edit email')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.profile.tabs.personal.phone', 'Phone Number')}
            value={userPhone || ''}
            onEdit={() => console.log('Edit Phone')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.profile.tabs.personal.b-day', 'Date of Birth')}
            value={user?.birthDate ? formatDateToString(user.birthDate, 'DD/MM/YYYY') : ''}
            onEdit={() => console.log('Edit bday')}
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalInfoTab;
