import * as yup from 'yup';

export const formValidation = {
  required: (str: string) => !!str.length,

  validPasswordOneUpperCaseLetter: (password: string) => {
    return password.match(/[A-Z]/);
  },

  validPasswordOneLowerCaseLetter: (password: string) => {
    return password.match(/[a-z]/);
  },

  validPasswordOneNumber: (password: string) => {
    return password.match(/[0-9]/);
  },

  validPasswordSpecialCharacter: (password: string) => {
    return password.match(/[!@#$%^&*(),.?":{}|<>]/g);
  },

  validName: (validMessage: string, requiredMessage?: string) =>
    yup
      .string()
      .required(requiredMessage)
      .test('is-valid', validMessage, (str) => {
        const pattern = /^[a-z ]+$/i;
        return str.trim().length > 0 && pattern.test(str);
      })
};

export const formSchema = {
  name: formValidation.validName('Please enter a valid name', 'Missing name'),
  firstName: formValidation.validName('Please enter a valid first name', 'Missing first name'),
  lastName: formValidation.validName('Please enter a valid last name', 'Missing last name'),

  email: yup
    .string()
    .required('Missing email')
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Please enter a valid email address'),

  phoneNumber: yup
    .number()
    .required('Missing phone number')
    .test('', 'Please enter a valid phone number', (number) =>
      /^[0-9]{7,15}$/.test(number.toString())
    )
    .typeError('Please enter a number'),

  phone: yup.object().shape({
    countryCode: yup.string().required('Missing phone country code'),
    number: yup
      .number()
      .required('Missing phone number')
      .test('', 'Please enter a valid phone number', (number) =>
        /^[0-9]{7,15}$/.test(number.toString())
      )
      .typeError('Please enter a number')
  }),

  password: yup
    .string()
    .required('Missing password')
    .min(8, 'Password must be at least 8 characters')
    .matches(/(?=.*[a-z])/, 'Password must contain at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Password must contain at least 1 uppercase letter')
    .matches(/(?=.*[0-9])/, 'Password must contain at least 1 digit')
    .matches(/(?=.*[!@#$%^&*])/, 'Password must contain at least 1 special character')
};
