import { MetadataObject } from 'types/common';
import axiosClient from './axiosClient';

type getMetadataResponse = { data: MetadataObject };

const metadataApi = {
  getAll(): Promise<getMetadataResponse> {
    return axiosClient.get('/metadata');
  },

  getById() {},
  create() {},
  update() {},
  delete() {}
};

export default metadataApi;
