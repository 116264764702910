import { Icon } from '@blueprintjs/core';
import { CheckBox } from '@brightsource/brightsource-ui-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { InputField, PhoneInputField } from 'components/form';
import { VARS } from 'constants/config';
import { usePostJob } from 'hooks/usePostJob';
import { PostJobUserData } from 'types/post-job';
import { formSchema } from 'utils/validation';

import './postJobUser.scss';

const schema = yup.object().shape({
  firstName: formSchema.firstName,
  lastName: formSchema.lastName,
  email: formSchema.email,
  phone: formSchema.phone,
  password: formSchema.password
});

export function PostJobUser() {
  const { userData, updateUserData, onValidateUser } = usePostJob();

  const [token, setToken] = useState<string | null>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (!captchaRef.current) return;
    captchaRef.current.reset();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitted }
  } = useForm<PostJobUserData>({
    defaultValues: userData ?? {
      phone: {
        countryCode: 'il',
        number: ''
      }
    },
    resolver: yupResolver(schema)
  });

  const handleVerifyCaptcha = async (event?: any) => {
    if (!captchaRef.current) return;

    if (event.target.checked) {
      const captchaToken = await captchaRef.current.executeAsync();
      setToken(captchaToken);
    } else {
      captchaRef.current.reset();
      setToken(null);
    }
  };

  const handleFormSubmit = async (payload: PostJobUserData) => {
    if (!token) return;

    await onValidateUser({ email: payload.email, captchaToken: token });
    updateUserData(payload);
  };

  return (
    <form id="postJobUser" className="post-job-user" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row">
        <InputField
          name="firstName"
          label="First Name"
          placeholder="Your first name"
          control={control}
        />

        <InputField
          name="lastName"
          label="Last Name"
          placeholder="Your last name"
          control={control}
        />
      </div>

      <InputField
        name="email"
        type="email"
        label="Email"
        placeholder="Work email"
        control={control}
      />

      <PhoneInputField
        phoneNumberName="phone.number"
        phoneCountryCodeName="phone.countryCode"
        control={control}
      />

      <InputField
        name="password"
        type="password"
        label="Password"
        placeholder="Enter password"
        control={control}
      />

      <ReCAPTCHA ref={captchaRef} size="invisible" sitekey={VARS.testSiteKey} />

      <div className={`re-captcha ${isSubmitted && !token ? 'has-error' : ''}`}>
        <CheckBox
          checked={Boolean(token)}
          label={`I’m not a robot.`}
          onChange={handleVerifyCaptcha}
        />
        <span className={`circle-icon ${!token ? 'active' : ''}`} />
        <Icon className={Boolean(token) ? 'active' : undefined} icon="small-tick" size={36} />
      </div>
    </form>
  );
}
