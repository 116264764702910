import React from 'react';
import { EditableControlValue } from '@brightsource/brightsource-ui-lib';

import { Company } from '../../../../types/company';

interface LocationTabProps {
	company?: Company;
}

function LocationTab({ company }: LocationTabProps) {
	return (
		<div className='settings-card-tab'>
			<div className='settings-card-tab-inner'>
				<div className='row'>
					<EditableControlValue
						label={'Location'}
						value={
							company?.locations?.length
								? company.locations
										.map((location, index) => {
											return `${location.city}, ${location.country}${
												index !== company.locations!.length - 1 ? ', ' : '.'
											}`;
										})
										.join('')
								: ''
						}
						onEdit={() => console.log('edit location')}
					/>
				</div>
			</div>
		</div>
	);
}

export default LocationTab;
