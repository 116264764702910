import { User } from 'types/user';
import axiosClient from './axiosClient';

type getCurrentUserResponse = { data: User };
type getCompanyUsersResponse = { data: { companyUsers: User[] } };

const userApi = {
  getCurrent(): Promise<getCurrentUserResponse> {
    return axiosClient.get('/user');
  },

  getByCompany(companyId: string | undefined): Promise<getCompanyUsersResponse> {
    return typeof companyId === 'undefined'
      ? Promise.reject(new Error('Invalid companyId'))
      : axiosClient.get(`/companyUsers/${companyId}`);
  },

  getAll() {},
  create() {},
  update() {},
  delete() {}
};

export default userApi;
