export const VARS = {
  serverUrl:
    process.env.REACT_APP_SERVER_URL ||
    'https://67e51887-fe09-4c5f-8adb-205b06889382.mock.pstmn.io/api',
  // demoToken: 'Bearer this-is-s-demo-bearer-token',
  demoToken:
    'eyJhbGciOiJSUzI1NiIsImtpZCI6IjVhNTA5ZjAxOWY3MGQ3NzlkODBmMTUyZDFhNWQzMzgxMWFiN2NlZjciLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiam9zaHVhIGNydXoiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGFsZW50LXRlc3QxIiwiYXVkIjoidGFsZW50LXRlc3QxIiwiYXV0aF90aW1lIjoxNjc1MjQ5NTAyLCJ1c2VyX2lkIjoiZlVKTEQxa091RGdZbmZGWEZBa1U2OFVwaFViMiIsInN1YiI6ImZVSkxEMWtPdURnWW5mRlhGQWtVNjhVcGhVYjIiLCJpYXQiOjE2NzU2MDM0MjEsImV4cCI6MTY3NTYwNzAyMSwiZW1haWwiOiJqb3NoaTExMjEyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJqb3NoaTExMjEyQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.POSFeDz15_htlJPfW8_vHrnB39I3TIdSNkcvVBi3hwKWtuLyjvukEB93IjaSbln4XG9SgyADWnUflb0SJcpCHE6GP0wCY1Vesi5rjjUceHX-P7ei2lnBl9fBNTarETkABqGd5pabPfpWeZQdK9r4UFEhDryJzRRDl2EQmdHwnwC8haBZ4vptDjYzSNhRtSYbMKPqI6sfiXgoX_wx9nRUIE79-70dVcCEgWwKcAONAjqU_WmmSHNeS2RXkN2kHLtbNfKs0hnZ5dMXLAYvCGz81iK8xWLXiKh-6k7n8hcD3YuBO-V-kLzpATYJsx5RIz8UV-tpwc41cdWi60LLdibaVQ',

  testSiteKey: '6Ld3AVcmAAAAAN22nqzICOEYrWwunIMecPM9DXWk'
};
