import React from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from '@brightsource/brightsource-ui-lib';

import { Talent } from 'types/talent';
import { useMetadata } from 'hooks/useMetadata';

export function TagsTab({ talent }: { talent: Talent }) {
  const { t } = useTranslation();
  const { data: metadata } = useMetadata();

  return (
    <div className="talent-card-tab">
      <div className="talent-card-tab-head">
        <h3 className="talent-card-tab-head-title">{t('talent.profile.tags', 'Tags')}</h3>
      </div>
      <div className="row">
        <MultiSelect
          selectedItems={talent.process?.recruiterTags || []}
          items={metadata?.data.recruiterTags || []}
          label={t('talent.profile.tags-label', 'Select Tags') || 'Select tags'}
          placeholder={t('talent.profile.tags-placeholder', 'select...') || 'select...'}
          hiddenLabel={false}
          allowClearAllTags={false}
          onRemove={(itam) => {
            //TODO: REMOVE
          }}
          onItemSelect={(item) => {
            //TODO: select
          }}
        />
      </div>
    </div>
  );
}
