import { Input, InputProps } from '@brightsource/brightsource-ui-lib';
import { Control, useController } from 'react-hook-form';

import './inputField.scss';

export interface InputFieldProps extends InputProps {
  name: string;
  control: Control<any>;
}

export function InputField({
  name,
  control,
  type = 'text',
  allowReset = false,
  ...rest
}: InputFieldProps) {
  const {
    field: { ref, value, onChange, onBlur },
    fieldState: { error }
  } = useController({
    name,
    control
  });

  const activePasswordType = Boolean(value) ? 'password' : 'text';

  return (
    <Input
      className="input-field"
      name={name}
      inputRef={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error?.message}
      type={type === 'password' ? activePasswordType : type}
      allowReset={allowReset}
      {...rest}
    />
  );
}
