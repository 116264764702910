import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DropDownMenu,
  formatDateToString,
  DefaultImage,
  CheckBox,
  Select,
  useIsMobile
} from '@brightsource/brightsource-ui-lib';
import { Link } from 'react-router-dom';

import { MetadataItem } from 'types/common';
import { Talent } from 'types/talent';
import { EllipsisIcon } from 'components/svg';
import { useMetadata } from 'hooks/useMetadata';

import './talentItemCard.scss';

export interface TalentItemCardProps {
  talent: Talent;
  onTalentSelect?: (talentId: string) => void;
  isTalentSelected?: boolean;
  onTalentClick: (talent: Talent) => void;
}

export function TalentItemCard({
  talent,
  isTalentSelected,
  onTalentSelect,
  onTalentClick
}: TalentItemCardProps) {
  const { t } = useTranslation();
  const isMobile: boolean = useIsMobile();
  const currentJob = talent.employment?.find((employment) => employment.isCurrent);
  const talentLocation =
    talent.location?.city && talent.location?.country
      ? `${talent.location.city}, ${talent.location.country}`
      : null;

  return isMobile ? (
    <li className="talent-item-card-mobile">
      <TalentImage profileImgUrl={talent.profileImgUrl} />
      <div className="talent-item-inner">
        <h3>
          <a role={'button'} onClick={() => onTalentClick(talent)}>
            {talent.firstName} {talent.lastName}
          </a>
        </h3>
        {currentJob ? (
          <React.Fragment>
            <p className="position-title">{currentJob.title.value}</p>
            <p className="company-name">{currentJob.organization.value}</p>
          </React.Fragment>
        ) : null}

        {talentLocation ? <p className="talent-location">{talentLocation}</p> : null}
        <TalentItemActions pipelineStatus={talent.process?.pipelineStatus} />
      </div>
      <TalentItemMenu />
    </li>
  ) : (
    <li className="talent-item-card">
      <div className="talent-item-checkbox">
        <CheckBox
          checked={isTalentSelected}
          onChange={(e) => onTalentSelect && onTalentSelect(talent.id)}
        />
      </div>
      <div className="talent-item-inner">
        <div className="talent-item-card-header">
          <div className="talent-header-data-wrapper">
            <TalentImage profileImgUrl={talent.profileImgUrl} />
            <div className="talent-personal-data">
              <h3>
                <a role={'button'} onClick={() => onTalentClick(talent)}>
                  {talent.firstName} {talent.lastName}
                </a>
              </h3>
              <ul>
                <li>{talent.availability?.value}</li>
                {talentLocation ? <li>{talentLocation}</li> : null}
              </ul>
              {talent.lastLoggedin ? (
                <p>
                  {t('talent-search.talent-card.updated', 'Last Updated')}:{' '}
                  {formatDateToString(talent.lastLoggedin, 'MMM DD, YYYY')}
                </p>
              ) : null}
            </div>
          </div>
          <TalentItemActions pipelineStatus={talent.process?.pipelineStatus} />
        </div>
        <div className="talent-body">
          <ul>
            {currentJob ? (
              <li>
                <div className="list-item-title">
                  {t('talent-search.talent-card.current-employment', 'Current Position')}
                </div>
                <div className="list-item-data">
                  <p>
                    {currentJob.title.value} {currentJob.title ? '|' : null}{' '}
                    {currentJob.organization.value}
                    {currentJob.startDate ? (
                      <span className="list-item-data-date">
                        {formatDateToString(currentJob.startDate, 'MM, YYYY')} -{' '}
                        {currentJob.endDate
                          ? formatDateToString(currentJob.endDate, 'MM, YYYY')
                          : 'present'}
                      </span>
                    ) : null}
                  </p>
                </div>
              </li>
            ) : null}
            {talent.education?.length ? (
              <li>
                <div className="list-item-title">
                  {t('talent-search.talent-card.education', 'Education')}
                </div>
                <div>
                  {talent.education.map((edu, index) => {
                    if (!edu.degreeType?.value) {
                      return '';
                    }

                    return (
                      <p key={`talent-${talent.id}-education}-${index}`}>
                        {edu.degreeType?.value}, {edu.degreeName?.value} | {edu.institution?.value}
                      </p>
                    );
                  })}
                </div>
              </li>
            ) : null}

            {talent.skills && talent.skills?.length > 0 ? (
              <li>
                <div className="list-item-title">
                  {t('talent-search.talent-card.skills', 'Skills')}
                </div>
                <div>
                  {talent.skills.map((skill, index) => {
                    return (
                      <React.Fragment key={`talent-${talent.id}-skill-${index}`}>
                        {skill.value}
                        {index !== talent.skills!.length - 1 ? ', ' : ''}
                      </React.Fragment>
                    );
                  })}
                </div>
              </li>
            ) : null}
            {talent.email || talent.phone ? (
              <li>
                <div className="list-item-title">
                  {t('talent-search.talent-card.contact', 'Contact')}
                </div>
                <div>
                  {talent.phone ? <p>{talent.phone.number}</p> : null}
                  {talent.email ? <p>{talent.email}</p> : null}
                </div>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <TalentItemMenu />
    </li>
  );
}

function TalentImage({ profileImgUrl }: { profileImgUrl?: string }) {
  return (
    <div className="talent-img">
      <DefaultImage url={profileImgUrl} isProfileImage={true} isCompanyLogo={false} />
    </div>
  );
}

export function TalentItemActions({ pipelineStatus }: { pipelineStatus?: MetadataItem }) {
  const { t } = useTranslation();
  const { data: metadata, error, status } = useMetadata();

  return (
    <div className="talent-actions">
      <Button variant="primary" onClick={() => console.log('Decline clicked')}>
        {t('talent-search.talent-card.decline', 'Decline')}
      </Button>
      {metadata?.data.pipelineStatuses ? (
        <Select
          onItemSelect={(item) => console.log('//TODO: change piprline status to ', item)}
          items={
            metadata?.data.pipelineStatuses.filter((item) => item.elId !== pipelineStatus?.elId) ||
            []
          }
          selectedItem={pipelineStatus || undefined}
          styleType={'solid'}
        />
      ) : null}
    </div>
  );
}

function TalentItemMenu() {
  return (
    <div className="talent-item-menu-container">
      <DropDownMenu
        triggerElement={
          <a className="talent-item-menu-trigger">
            <EllipsisIcon />
          </a>
        }
        items={[
          { text: 'todo1', onClick: () => console.log('todo1') },
          { text: 'todo2', onClick: () => console.log('todo2') }
        ]}
        position="bottom-right"
      />
    </div>
  );
}
