import React from 'react';
import { ModalDialog, useIsMobile } from '@brightsource/brightsource-ui-lib';
import { useTranslation } from 'react-i18next';

import { FiltersIcon } from 'components/svg';
import FiltersPanel from './FiltersPanel';

import './jobsPipelineFilters.scss';

interface JobPipelineFiltersProps {
  isFiltersPanelOpen: boolean;
  onFiltersPannelClose: () => void;
}

export function JobPipelineFilters({
  isFiltersPanelOpen,
  onFiltersPannelClose
}: JobPipelineFiltersProps) {
  const isMobile: boolean = useIsMobile();
  const { t } = useTranslation();

  return isMobile ? (
    <ModalDialog
      isOpen={isFiltersPanelOpen}
      onClose={() => onFiltersPannelClose()}
      title={t('jobs-pipeline.filters.modal-title', 'Filters') || ''}
      titleIcon={<FiltersIcon />}
      className={'jobs-pipeline-filters-modal'}
    >
      <FiltersPanel
      //   filters={filters}
      //   pipelineStatusesCounter={pipelineStatusesCounter}
      //   onFiltersChange={onFiltersChange}
      />
    </ModalDialog>
  ) : (
    <div className={`jobs-pipeline-filters-panel ${isFiltersPanelOpen ? 'open' : null}`}>
      <FiltersPanel
      //   filters={filters}
      //   pipelineStatusesCounter={pipelineStatusesCounter}
      //   onFiltersChange={onFiltersChange}
      />
    </div>
  );
}
