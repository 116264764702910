import React from 'react';
import { NavLink } from 'react-router-dom';
import { useIsMobile } from '@brightsource/brightsource-ui-lib';

import { ReactRouterDomNavData } from '../../../types/common';
import { useTranslation } from 'react-i18next';

interface NavListProps {
  isOpen: boolean;
  hasUser: boolean;
  toggleNavList: () => void;
}

function NavList({ isOpen, toggleNavList, hasUser }: NavListProps) {
  const isMobile: boolean = useIsMobile();
  const { t } = useTranslation();

  if (!hasUser) {
    return null;
  }

  function isActiveClassName(navData: ReactRouterDomNavData) {
    return navData.isActive ? 'nav-link active-nav-link' : 'nav-link';
  }

  return (
    <ul
      className={`header-nav-list ${isOpen ? 'open-nav-list' : ''} ${
        isMobile ? 'nav-list-mobile' : 'nav-list-desktop'
      }`}
    >
      <li className="nav-item">
        <NavLink to="/talents" className={isActiveClassName} onClick={() => toggleNavList()}>
          {t('layout.header.nav.candidates', 'Candidates')}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/jobs" className={isActiveClassName} onClick={() => toggleNavList()}>
          {t('layout.header.nav.jobs', 'Jobs')}
        </NavLink>
      </li>

      {/* {isMobile ? (
				<li className='nav-item'>
					<NavLink
						to='/search-talents'
						className={isActiveClassName}
						onClick={() => toggleNavList()}
					>
						Search
					</NavLink>
				</li>
			) : null} */}
    </ul>
  );
}

export default NavList;
