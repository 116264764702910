import { PhoneInput } from '@brightsource/brightsource-ui-lib';
import { Control, useController } from 'react-hook-form';

import './phoneInputField.scss';

export interface PhoneInputFieldProps {
  phoneNumberName: string;
  phoneCountryCodeName: string;
  control: Control<any>;
}

export function PhoneInputField({
  phoneNumberName,
  phoneCountryCodeName,
  control,
  ...rest
}: PhoneInputFieldProps) {
  const { field: phoneNumberField, fieldState: phoneNumberFieldState } = useController({
    name: phoneNumberName,
    control
  });

  const { field: phoneCountryCodeField, fieldState: phoneCountryCodeFieldState } = useController({
    name: phoneCountryCodeName,
    control
  });

  return (
    <PhoneInput
      className="phone-input-field"
      label="Phone Number"
      phoneNumber={phoneNumberField.value}
      onPhoneNumberChange={phoneNumberField.onChange}
      countryCode={phoneCountryCodeField.value}
      onCountryCodeChange={phoneCountryCodeField.onChange}
      error={phoneNumberFieldState.error?.message || phoneCountryCodeFieldState.error?.message}
      {...rest}
    />
  );
}
