import { AutoComplete } from 'components/common';
import { useCompanies, usePostJob } from 'hooks/usePostJob';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { PostJobStep } from 'types/post-job';
import { mapToAutoCompleteItem, mapToCompany } from 'utils/postJob';

import './postJobCompany.scss';

export function PostJobCompany() {
  const navigate = useNavigate();
  const { stepIndex, companyData, updateCompanyData, updateStepIndex } = usePostJob();
  const { data: companyList, error, isLoading } = useCompanies();
  const { formState, handleSubmit } = useForm();

  const handleFormSubmit = () => {
    if (!companyData) return;
    updateStepIndex(PostJobStep.JOB_BASIC);
  };

  const handleCreateClick = () => {
    navigate('/post-job/new-company');
  };

  return (
    <form
      id="postJobCompany"
      className="post-job-company"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <AutoComplete
        label="Company Name"
        list={companyList ? companyList.map(mapToAutoCompleteItem) : []}
        selectedItem={companyData ? mapToAutoCompleteItem(companyData) : null}
        updateSelectedItem={(item) => updateCompanyData(item ? mapToCompany(item) : null)}
        error={formState.isSubmitted && !companyData ? 'Missing company' : undefined}
        onCreate={handleCreateClick}
      />
    </form>
  );
}
