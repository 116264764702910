import { useQuery } from 'react-query';
import companyApi from 'services/api/companyApi';

export function useCompany(companyId: string | undefined) {
  return useQuery({
    queryKey: ['company', companyId],
    queryFn: () => companyApi.getById(companyId),
    enabled: Boolean(companyId)
  });
}
