import { useQuery } from 'react-query';

import jobApi from 'services/api/jobApi';

export function useJobList() {
  return useQuery({
    queryKey: ['jobs'],
    cacheTime: 20 * 60 * 1000,
    queryFn: () => jobApi.getAll()
    // enabled:
  });
}

export function useSearchJobByTitle(term: string) {
  return useQuery({
    queryKey: ['searchJob', term],
    cacheTime: 1 * 60 * 1000, //1 minutes
    queryFn: () => jobApi.searchByTitle(term),
    enabled: Boolean(term)
  });
}
