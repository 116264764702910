import { useQuery } from 'react-query';
import userApi from 'services/api/userApi';

export function useCurrentUser() {
  return useQuery({
    queryKey: ['currentUser'],
    queryFn: () => userApi.getCurrent()
  });
}

export function useUserByCompany(companyId: string | undefined) {
  return useQuery({
    queryKey: ['companyUsers', companyId],
    queryFn: () => userApi.getByCompany(companyId),
    enabled: Boolean(companyId)
  });
}
