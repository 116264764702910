import { EditableText } from '@blueprintjs/core';
import { TextArea } from '@brightsource/brightsource-ui-lib';
import { AutoComplete, AutoCompleteItem } from 'components/common';
import { useState } from 'react';
import EditableContainer from './EditableContainer';

import './postJobEditable.scss';

export interface PostJobEditableProps {
  label: string;
  text?: string;
  multiline?: boolean;
  item?: AutoCompleteItem | null;
  list?: AutoCompleteItem[];
  onSaveText?: (value: string) => void;
  onSaveItem?: (item: AutoCompleteItem | null) => void;
}

export function PostJobEditable(props: PostJobEditableProps) {
  const { label, text = '', multiline, item, list, onSaveText, onSaveItem } = props;

  const [value, setValue] = useState(text);
  const [selectedItem, setSelectedItem] = useState(item);

  const handleCancel = () => {
    setValue(text);
    setSelectedItem(item);
  };

  const handleSave = () => {
    if (selectedItem !== undefined) {
      onSaveItem?.(selectedItem);
    } else {
      onSaveText?.(value);
    }
  };

  const renderContent = (isEditMode: boolean) => {
    if (selectedItem !== undefined) {
      if (!isEditMode) return <p className="editable-value">{item ? item.value : 'None'}</p>;

      return (
        <AutoComplete
          list={list ?? []}
          selectedItem={selectedItem ?? null}
          updateSelectedItem={setSelectedItem}
        />
      );
    }

    if (multiline) {
      if (!isEditMode) return <p className="editable-value multiline">{text || 'None'}</p>;
      return <TextArea rows={6} value={value} onChange={(e) => setValue(e.target.value)} />;
    }

    return (
      <EditableText
        className="editable-value"
        value={value}
        onChange={setValue}
        disabled={!isEditMode}
        isEditing={isEditMode}
        placeholder={isEditMode || Boolean(text) ? '' : 'None'}
      />
    );
  };

  return (
    <EditableContainer
      label={label}
      onSave={handleSave}
      onCancel={handleCancel}
      renderContent={renderContent}
    />
  );
}
