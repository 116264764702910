import React, { useState } from 'react';
import { DefaultImage, Loader } from '@brightsource/brightsource-ui-lib';

import { User } from 'types/user';
import EditProfilePictureModal from '../EditProfilePictureModal';

export interface PersonalSettingsHeader {
  user?: User;
  userStatus?: string;
}

function PersonalSettingsHeader({ user, userStatus }: PersonalSettingsHeader) {
  const [isEditImgModalOpen, setIsEditImgModalOpen] = useState<boolean>(false);
  const [isUploadImageLoading, setIsUploadImageLoading] = useState<boolean>(false);

  async function uploadOrRemoveFile(file?: File) {
    if (file?.name) {
      const toBase64 = (file: File) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      const attachmentBase64 = await toBase64(file);
      const fileData = {
        attachmentName: file.name,
        attachmentBase64: attachmentBase64
      };

      //TODO: Post upload image
    } else {
      //TODO: remove profile picture
    }
  }

  return (
    <div className="settings-card-header profile-header">
      <div className="profile-picture">
        {userStatus === 'loading' ? (
          <Loader />
        ) : (
          <a role={'button'} onClick={() => setIsEditImgModalOpen((prev: boolean) => !prev)}>
            <DefaultImage
              url={user?.profileImgUrl}
              isProfileImage={true}
              alt="logged in user"
              profileName={`${user?.firstName?.charAt(0)} ${user?.lastName?.charAt(0)}`}
            />
          </a>
        )}
      </div>
      <EditProfilePictureModal
        isOpen={isEditImgModalOpen}
        onClose={() => setIsEditImgModalOpen((prev: boolean) => !prev)}
        currentImgUrl={user?.profileImgUrl || ''}
        setIsUploadImageLoading={setIsUploadImageLoading}
        onSave={uploadOrRemoveFile}
        isUploadImageLoading={isUploadImageLoading}
      />
    </div>
  );
}

export default PersonalSettingsHeader;
