import { useState } from 'react';
import { Route, Routes } from 'react-router';

import {
  CompanySettingsCard,
  PersonalSettingsCard,
  SettingsNavbar,
  UserManagementCard
} from 'components/settings';
import { useUserByCompany, useCurrentUser } from 'hooks/useUser';
import { useCompany } from 'hooks/useCompany';

import './settingsPage.scss';

function SettingsPage() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const { data: userData, error: userError, status: userStatus } = useCurrentUser();

  const {
    data: companyData,
    error: companyError,
    status: companyStatus
  } = useCompany(userData?.data.companyId);

  const {
    data: companyUsersData,
    error: companyUsersError,
    status: companyUsersStatus
  } = useUserByCompany(userData?.data.companyId);

  function toggleNavbar() {
    setIsNavbarOpen((prev) => !prev);
  }

  return (
    <main className="settings-page">
      <div className="settings-page-inner">
        <SettingsNavbar isNavbarOpen={isNavbarOpen} toggleNavbar={toggleNavbar} />
        <Routes>
          <Route
            path="my-profile"
            element={
              <PersonalSettingsCard
                toggleNavbar={toggleNavbar}
                user={userData?.data}
                userStatus={userStatus}
              />
            }
          />
          <Route
            path="user-management"
            element={
              <UserManagementCard
                toggleNavbar={toggleNavbar}
                companyUsers={companyUsersData?.data.companyUsers}
                company={companyData?.data}
                companyUsersRequestStaus={companyUsersStatus}
              />
            }
          />
          <Route
            path="company-profile"
            element={
              <CompanySettingsCard
                company={companyData?.data}
                companyStatus={companyStatus}
                toggleNavbar={toggleNavbar}
              />
            }
          />
        </Routes>
      </div>
    </main>
  );
}

export default SettingsPage;
