const AvatarIcon = ({ color = 'currentColor', height = '24', width = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AvatarMultiIcon = ({ color = 'currentColor', height = '24', width = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.15962 11.62C9.12962 11.62 9.10962 11.62 9.07962 11.62C9.02962 11.61 8.95962 11.61 8.89962 11.62C5.99962 11.53 3.80962 9.25 3.80962 6.44C3.80962 3.58 6.13962 1.25 8.99962 1.25C11.8596 1.25 14.1896 3.58 14.1896 6.44C14.1796 9.25 11.9796 11.53 9.18962 11.62C9.17962 11.62 9.16962 11.62 9.15962 11.62ZM8.99962 2.75C6.96962 2.75 5.30962 4.41 5.30962 6.44C5.30962 8.44 6.86962 10.05 8.85962 10.12C8.91962 10.11 9.04962 10.11 9.17962 10.12C11.1396 10.03 12.6796 8.42 12.6896 6.44C12.6896 4.41 11.0296 2.75 8.99962 2.75Z"
      fill={color}
    />
    <path
      d="M16.5396 11.75C16.5096 11.75 16.4796 11.75 16.4496 11.74C16.0396 11.78 15.6196 11.49 15.5796 11.08C15.5396 10.67 15.7896 10.3 16.1996 10.25C16.3196 10.24 16.4496 10.24 16.5596 10.24C18.0196 10.16 19.1596 8.96 19.1596 7.49C19.1596 5.97 17.9296 4.74 16.4096 4.74C15.9996 4.75 15.6596 4.41 15.6596 4C15.6596 3.59 15.9996 3.25 16.4096 3.25C18.7496 3.25 20.6596 5.16 20.6596 7.5C20.6596 9.8 18.8596 11.66 16.5696 11.75C16.5596 11.75 16.5496 11.75 16.5396 11.75Z"
      fill={color}
    />
    <path
      d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z"
      fill={color}
    />
    <path
      d="M18.3396 20.75C17.9896 20.75 17.6796 20.51 17.6096 20.15C17.5296 19.74 17.7896 19.35 18.1896 19.26C18.8196 19.13 19.3996 18.88 19.8496 18.53C20.4196 18.1 20.7296 17.56 20.7296 16.99C20.7296 16.42 20.4196 15.88 19.8596 15.46C19.4196 15.12 18.8696 14.88 18.2196 14.73C17.8196 14.64 17.5596 14.24 17.6496 13.83C17.7396 13.43 18.1396 13.17 18.5496 13.26C19.4096 13.45 20.1596 13.79 20.7696 14.26C21.6996 14.96 22.2296 15.95 22.2296 16.99C22.2296 18.03 21.6896 19.02 20.7596 19.73C20.1396 20.21 19.3596 20.56 18.4996 20.73C18.4396 20.75 18.3896 20.75 18.3396 20.75Z"
      fill={color}
    />
  </svg>
);

const BriefcaseIcon = ({ color = 'currentColor', height = '24', width = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 22.75H8C3.38 22.75 2.52 20.6 2.3 18.51L1.55 10.5C1.44 9.45001 1.41 7.90001 2.45 6.74001C3.35 5.74001 4.84 5.26001 7 5.26001H17C19.17 5.26001 20.66 5.75001 21.55 6.74001C22.59 7.90001 22.56 9.45001 22.45 10.51L21.7 18.5C21.48 20.6 20.62 22.75 16 22.75ZM7 6.75001C5.31 6.75001 4.15 7.08001 3.56 7.74001C3.07 8.28001 2.91 9.11001 3.04 10.35L3.79 18.36C3.96 19.94 4.39 21.25 8 21.25H16C19.6 21.25 20.04 19.94 20.21 18.35L20.96 10.36C21.09 9.11001 20.93 8.28001 20.44 7.74001C19.85 7.08001 18.69 6.75001 17 6.75001H7Z"
      fill={color}
    />
    <path
      d="M16 6.75C15.59 6.75 15.25 6.41 15.25 6V5.2C15.25 3.42 15.25 2.75 12.8 2.75H11.2C8.75 2.75 8.75 3.42 8.75 5.2V6C8.75 6.41 8.41 6.75 8 6.75C7.59 6.75 7.25 6.41 7.25 6V5.2C7.25 3.44 7.25 1.25 11.2 1.25H12.8C16.75 1.25 16.75 3.44 16.75 5.2V6C16.75 6.41 16.41 6.75 16 6.75Z"
      fill={color}
    />
    <path
      d="M12 16.75C9.25 16.75 9.25 15.05 9.25 14.03V13C9.25 11.59 9.59 11.25 11 11.25H13C14.41 11.25 14.75 11.59 14.75 13V14C14.75 15.04 14.75 16.75 12 16.75ZM10.75 12.75C10.75 12.83 10.75 12.92 10.75 13V14.03C10.75 15.06 10.75 15.25 12 15.25C13.25 15.25 13.25 15.09 13.25 14.02V13C13.25 12.92 13.25 12.83 13.25 12.75C13.17 12.75 13.08 12.75 13 12.75H11C10.92 12.75 10.83 12.75 10.75 12.75Z"
      fill={color}
    />
    <path
      d="M14 14.77C13.63 14.77 13.3 14.49 13.26 14.11C13.21 13.7 13.5 13.32 13.91 13.27C16.55 12.94 19.08 11.94 21.21 10.39C21.54 10.14 22.01 10.22 22.26 10.56C22.5 10.89 22.43 11.36 22.09 11.61C19.75 13.31 16.99 14.4 14.09 14.77C14.06 14.77 14.03 14.77 14 14.77Z"
      fill={color}
    />
    <path
      d="M10 14.78C9.97001 14.78 9.94001 14.78 9.91001 14.78C7.17001 14.47 4.50001 13.47 2.19001 11.89C1.85001 11.66 1.76001 11.19 1.99001 10.85C2.22001 10.51 2.69001 10.42 3.03001 10.65C5.14001 12.09 7.57001 13 10.07 13.29C10.48 13.34 10.78 13.71 10.73 14.12C10.7 14.5 10.38 14.78 10 14.78Z"
      fill={color}
    />
  </svg>
);

const AddImgIcon = ({ color = 'currentColor', height = '24', width = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16C14.4853 16 16.5 13.9853 16.5 11.5C16.5 9.01472 14.4853 7 12 7C9.51472 7 7.5 9.01472 7.5 11.5C7.5 13.9853 9.51472 16 12 16Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path
      d="M22.5 18.5H1.5V5.5H7.5L9.5 3.5H14.5L16.5 5.5H22.5V18.5Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path d="M9.5 11.5H14.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 14V9" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowRightIcon = ({ color = 'currentColor', height = '17', width = '9' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15.5L7.93182 8.66667L0.999999 1.83333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowLeftIcon = ({ color = 'currentColor', height = '17', width = '9' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.1665L1 7.6665L8 14.1665"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DotIcon = ({ color = 'currentColor', height = '11', width = '11' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10.5C7.76142 10.5 10 8.26142 10 5.5C10 2.73858 7.76142 0.5 5 0.5C2.23858 0.5 0 2.73858 0 5.5C0 8.26142 2.23858 10.5 5 10.5Z"
      fill={color}
    />
  </svg>
);

const BroadcastIcon = ({ color = 'currentColor', height = '24', width = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.10986 15.89C7.59887 15.3793 7.19355 14.7728 6.91699 14.1053C6.64043 13.4379 6.49805 12.7225 6.49805 12C6.49805 11.2775 6.64043 10.5621 6.91699 9.89465C7.19355 9.22719 7.59887 8.62074 8.10986 8.10999"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8901 8.10999C16.4011 8.62074 16.8064 9.22719 17.083 9.89465C17.3596 10.5621 17.502 11.2775 17.502 12C17.502 12.7225 17.3596 13.4379 17.083 14.1053C16.8064 14.7728 16.4011 15.3793 15.8901 15.89"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.27991 18.72C3.49924 16.9369 2.49902 14.52 2.49902 12C2.49902 9.48004 3.49924 7.06313 5.27991 5.28003"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7197 5.28003C20.5004 7.06313 21.5006 9.48004 21.5006 12C21.5006 14.52 20.5004 16.9369 18.7197 18.72"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const EllipsisIcon = ({ color = 'currentColor', height = '18', width = '3' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 1.5C3 0.671573 2.32843 -1.01779e-07 1.5 -6.55671e-08C0.671573 -2.93554e-08 -1.01779e-07 0.671573 -6.55671e-08 1.5C-2.93554e-08 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5Z"
      fill={color}
    />
    <path
      d="M10.5 1.5C10.5 0.671573 9.82843 -1.01779e-07 9 -6.55672e-08C8.17157 -2.93554e-08 7.5 0.671573 7.5 1.5C7.5 2.32843 8.17157 3 9 3C9.82843 3 10.5 2.32843 10.5 1.5Z"
      fill={color}
    />
    <path
      d="M18 1.5C18 0.671573 17.3284 -1.01779e-07 16.5 -6.55672e-08C15.6716 -2.93554e-08 15 0.671573 15 1.5C15 2.32843 15.6716 3 16.5 3C17.3284 3 18 2.32843 18 1.5Z"
      fill={color}
    />
  </svg>
);

const SortMenuIcon = ({ color = 'currentColor', height = '10', width = '10' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1H9" stroke={color} strokeLinecap="round" />
    <path d="M3 5H9" stroke={color} strokeLinecap="round" />
    <path d="M5 9H9" stroke={color} strokeLinecap="round" />
  </svg>
);

const FiltersIcon = ({ color = 'currentColor', height = '24', width = '26' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.4181 11.5H3.98303" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.10168 6.5H3.98303" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.10168 16.5H3.98303" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.18078 18.5C10.329 18.5 11.2599 17.6046 11.2599 16.5C11.2599 15.3954 10.329 14.5 9.18078 14.5C8.03253 14.5 7.10168 15.3954 7.10168 16.5C7.10168 17.6046 8.03253 18.5 9.18078 18.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4971 13.5C18.6454 13.5 19.5762 12.6046 19.5762 11.5C19.5762 10.3954 18.6454 9.5 17.4971 9.5C16.3489 9.5 15.418 10.3954 15.418 11.5C15.418 12.6046 16.3489 13.5 17.4971 13.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.18078 8.5C10.329 8.5 11.2599 7.60457 11.2599 6.5C11.2599 5.39543 10.329 4.5 9.18078 4.5C8.03253 4.5 7.10168 5.39543 7.10168 6.5C7.10168 7.60457 8.03253 8.5 9.18078 8.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.6553 11.5H19.5762" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.6554 6.5H11.2599" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.6554 16.5H11.2599" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const MailIcon = ({ w = 16, h = 14, color = 'currentColor' }) => (
  <svg width={w} height={h} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="13" rx="2.5" stroke={color} />
    <path
      d="M0.667969 2.66602L5.87998 7.87803C7.05156 9.0496 8.95105 9.0496 10.1226 7.87803L15.3346 2.66602"
      stroke={color}
    />
  </svg>
);

const PhoneIcon = ({ w = 12, h = 18, vw = 12, vh = 18, color = 'currentColor' }) => (
  <svg
    width={w}
    height={h}
    viewBox={`0 0 ${vw} ${vh}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="11" height="17" rx="2.5" stroke={color} />
    <path d="M5 2.5H7" stroke={color} />
    <path d="M0 14H11" stroke={color} />
  </svg>
);

const EditIcon = ({ color = 'currentColor', height = '17', width = '17' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.96364 12.0075C1.9022 12.0711 1.85873 12.1498 1.83767 12.2357L0.998958 15.6551C0.957116 15.8257 1.00785 16.0057 1.13262 16.1293C1.25738 16.253 1.43786 16.3021 1.60807 16.2587L4.89839 15.42C4.98828 15.3971 5.07003 15.3496 5.13448 15.2829L14.9519 5.12098C15.8157 4.22685 15.8035 2.80537 14.9244 1.92626C14.0235 1.0254 12.5591 1.03809 11.674 1.95444L1.96364 12.0075Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path d="M13.358 6.7065L10.2559 3.42188L13.358 6.7065Z" stroke={color} />
    <path d="M5.52597 14.8549L2.42383 11.5703L5.52597 14.8549Z" stroke={color} />
  </svg>
);

const TrashIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_116_6648)">
      <path
        d="M5.5 3.5H17.5V18.5C17.5 19.2956 17.1839 20.0587 16.6213 20.6213C16.0587 21.1839 15.2956 21.5 14.5 21.5H8.5C7.70435 21.5 6.94129 21.1839 6.37868 20.6213C5.81607 20.0587 5.5 19.2956 5.5 18.5V3.5Z"
        stroke="#FF0000"
        strokeLinejoin="round"
      />
      <path d="M11.5 6.5V18.5" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 6.5V18.5" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 6.5V18.5" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 3.5H19.5" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.5 1.5H12.5C13.0304 1.5 13.5391 1.71071 13.9142 2.08579C14.2893 2.46086 14.5 2.96957 14.5 3.5H8.5C8.5 2.96957 8.71071 2.46086 9.08579 2.08579C9.46086 1.71071 9.96957 1.5 10.5 1.5Z"
        stroke="#FF0000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_116_6648">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const CameraIcon = () => (
  <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3775 16C16.0263 16 18.1736 13.9853 18.1736 11.5C18.1736 9.01472 16.0263 7 13.3775 7C10.7286 7 8.58136 9.01472 8.58136 11.5C8.58136 13.9853 10.7286 16 13.3775 16Z"
      stroke="#00074F"
      strokeLinejoin="round"
    />
    <path
      d="M24.5684 18.5H2.18658V5.5H8.58138L10.713 3.5H16.042L18.1736 5.5H24.5684V18.5Z"
      stroke="#00074F"
      strokeLinejoin="round"
    />
    <path d="M10.713 11.5H16.042" stroke="#00074F" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3775 14V9" stroke="#00074F" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const PinIcon = ({
  color = 'currentColor',
  fillColor = 'currentColor',
  height = '17',
  width = '17'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.29434 17.0907L2.21737 23.2311" stroke={color} strokeWidth="1.00014" />
    <path
      d="M3.98725 12.9985L12.4299 21.2264C12.4299 21.2264 15.8021 17.7237 15.3604 14.5748L21.1581 10.6571L14.8396 4.47503C14.8396 4.47503 10.532 9.80387 10.496 9.86723C6.99091 9.30253 3.98725 12.9985 3.98725 12.9985Z"
      fill={fillColor}
      stroke={fillColor}
      strokeWidth="0.999613"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.04169 24.4141L1.61522 22.6204L2.84433 23.7893L1.04169 24.4141Z" fill={color} />
    <path
      d="M15.501 1.58545L14.3109 2.75635C13.9822 3.07968 13.9822 3.60391 14.3109 3.92725L21.4517 10.9527C21.7804 11.276 22.3132 11.276 22.6419 10.9527L23.832 9.78175C24.1607 9.45842 24.1607 8.93419 23.832 8.61085L16.6912 1.58545C16.3625 1.26211 15.8297 1.26211 15.501 1.58545Z"
      fill={fillColor}
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const StatusIcon = ({ color = 'currentColor', height = '24', width = '24' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.10986 15.89C7.59887 15.3793 7.19355 14.7728 6.91699 14.1053C6.64043 13.4379 6.49805 12.7225 6.49805 12C6.49805 11.2775 6.64043 10.5621 6.91699 9.89465C7.19355 9.22719 7.59887 8.62074 8.10986 8.10999"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8901 8.10999C16.4011 8.62074 16.8064 9.22719 17.083 9.89465C17.3596 10.5621 17.502 11.2775 17.502 12C17.502 12.7225 17.3596 13.4379 17.083 14.1053C16.8064 14.7728 16.4011 15.3793 15.8901 15.89"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.27991 18.72C3.49924 16.9369 2.49902 14.52 2.49902 12C2.49902 9.48004 3.49924 7.06313 5.27991 5.28003"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7197 5.28003C20.5004 7.06313 21.5006 9.48004 21.5006 12C21.5006 14.52 20.5004 16.9369 18.7197 18.72"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LinkedinIcon = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 0.790039H1.49531C0.670312 0.790039 0 1.46973 0 2.3041V20.276C0 21.1104 0.670312 21.79 1.49531 21.79H19.5C20.325 21.79 21 21.1104 21 20.276V2.3041C21 1.46973 20.325 0.790039 19.5 0.790039ZM6.34687 18.79H3.23438V8.76816H6.35156V18.79H6.34687ZM4.79062 7.39941C3.79219 7.39941 2.98594 6.58848 2.98594 5.59473C2.98594 4.60098 3.79219 3.79004 4.79062 3.79004C5.78437 3.79004 6.59531 4.60098 6.59531 5.59473C6.59531 6.59316 5.78906 7.39941 4.79062 7.39941ZM18.0141 18.79H14.9016V13.915C14.9016 12.7525 14.8781 11.2572 13.2844 11.2572C11.6625 11.2572 11.4141 12.5229 11.4141 13.8307V18.79H8.30156V8.76816H11.2875V10.1369H11.3297C11.7469 9.34941 12.7641 8.51973 14.2781 8.51973C17.4281 8.51973 18.0141 10.5963 18.0141 13.2963V18.79Z"
      fill="#6C6C6C"
    />
  </svg>
);

const MediumIcon = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0.790039V21.79H21V0.790039H0ZM17.4469 5.76348L16.3219 6.8416C16.2234 6.9166 16.1766 7.03848 16.1953 7.15566V15.0916C16.1766 15.2135 16.2234 15.3354 16.3219 15.4057L17.4234 16.4838V16.7229H11.8922V16.4932L13.0312 15.3869C13.1438 15.2744 13.1437 15.2416 13.1437 15.0729V8.65566L9.975 16.6994H9.54844L5.85938 8.65566V14.0463C5.82656 14.2713 5.90625 14.501 6.06562 14.665L7.54688 16.4604V16.6994H3.3375V16.4604L4.81875 14.665C4.97813 14.501 5.04844 14.2713 5.01094 14.0463V7.81191C5.02969 7.63848 4.96406 7.46973 4.83281 7.35254L3.51562 5.76348V5.52441H7.60781L10.7672 12.4619L13.5469 5.5291H17.4469V5.76348Z"
      fill="#6C6C6C"
    />
  </svg>
);

const TwitterIcon = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.533 5.40179C21.5482 5.61498 21.5482 5.82821 21.5482 6.0414C21.5482 12.5439 16.599 20.0363 7.5533 20.0363C4.76648 20.0363 2.17767 19.2292 0 17.8282C0.395953 17.8739 0.776625 17.8891 1.18781 17.8891C3.48727 17.8891 5.60405 17.1125 7.29441 15.7876C5.13197 15.7419 3.31978 14.3257 2.69541 12.3764C3 12.4221 3.30455 12.4525 3.62437 12.4525C4.06598 12.4525 4.50764 12.3916 4.91878 12.2851C2.66498 11.8282 0.974578 9.84849 0.974578 7.45763V7.39674C1.62937 7.76223 2.39086 7.99065 3.19791 8.02107C1.87303 7.1378 1.00505 5.63021 1.00505 3.92462C1.00505 3.01093 1.24866 2.17337 1.67508 1.4424C4.09641 4.42716 7.73602 6.37637 11.8172 6.5896C11.7411 6.22412 11.6954 5.84345 11.6954 5.46273C11.6954 2.75204 13.8883 0.543945 16.6141 0.543945C18.0304 0.543945 19.3095 1.13785 20.208 2.09724C21.3197 1.88405 22.3857 1.47287 23.3299 0.90943C22.9643 2.05159 22.1877 3.01098 21.1674 3.62007C22.1573 3.51352 23.1167 3.23935 23.9999 2.85868C23.33 3.83326 22.4924 4.70124 21.533 5.40179Z"
      fill="#6C6C6C"
    />
  </svg>
);

const PencilIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.96267 11.5075C1.90123 11.5711 1.85776 11.6498 1.83669 11.7357L0.997982 15.1551C0.95614 15.3257 1.00688 15.5057 1.13164 15.6293C1.2564 15.753 1.43689 15.8021 1.60709 15.7587L4.89741 14.92C4.9873 14.8971 5.06905 14.8496 5.1335 14.7829L14.9509 4.62098C15.8148 3.72685 15.8025 2.30537 14.9234 1.42626C14.0225 0.525397 12.5581 0.538093 11.673 1.45444L1.96267 11.5075Z"
      fill="#F8F8F8"
      stroke="#6C6C6C"
      strokeLinejoin="round"
    />
    <path d="M13.357 6.2065L10.2549 2.92188L13.357 6.2065Z" fill="#F8F8F8" stroke="#6C6C6C" />
    <path d="M5.525 14.3549L2.42285 11.0703L5.525 14.3549Z" fill="#F8F8F8" stroke="#6C6C6C" />
  </svg>
);

const LockIcon = () => (
  <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.12134 2.37866C7.55873 1.81605 6.79565 1.5 6 1.5C5.20435 1.5 4.44127 1.81605 3.87866 2.37866C3.31605 2.94127 3 3.70435 3 4.5V7.5H9V4.5C9 3.70435 8.68395 2.94127 8.12134 2.37866ZM10 7.55001V4.5C10 3.43913 9.57852 2.42172 8.82837 1.67157C8.07822 0.921424 7.06087 0.5 6 0.5C4.93913 0.5 3.92178 0.921424 3.17163 1.67157C2.42149 2.42172 2 3.43913 2 4.5V7.55001C0.85888 7.78164 0 8.79052 0 10V15C0 16.3807 1.11929 17.5 2.5 17.5H9.5C10.8807 17.5 12 16.3807 12 15V10C12 8.79052 11.1411 7.78164 10 7.55001ZM9.5 8.5H2.5C1.67157 8.5 1 9.17157 1 10V15C1 15.8284 1.67157 16.5 2.5 16.5H9.5C10.3284 16.5 11 15.8284 11 15V10C11 9.17157 10.3284 8.5 9.5 8.5Z"
      fill="#404040"
    />
  </svg>
);

const UnlockIcon = () => (
  <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.08398C6.79565 1.08398 7.55873 1.40004 8.12134 1.96265C8.68395 2.52526 9 3.28833 9 4.08398V5.08398H10V4.08398C10 3.02312 9.57851 2.0057 8.82837 1.25555C8.07822 0.505409 7.06087 0.0839844 6 0.0839844C4.93913 0.0839844 3.92178 0.505409 3.17163 1.25555C2.42149 2.0057 2 3.02312 2 4.08398V7.13399C0.85888 7.36563 0 8.37451 0 9.58398V14.584C0 15.9647 1.11929 17.084 2.5 17.084H9.5C10.8807 17.084 12 15.9647 12 14.584V9.58398C12 8.20327 10.8807 7.08398 9.5 7.08398H8H6H3V4.08398C3 3.28833 3.31605 2.52526 3.87866 1.96265C4.44127 1.40004 5.20435 1.08398 6 1.08398ZM2.5 8.08398C1.67157 8.08398 1 8.75556 1 9.58398V14.584C1 15.4124 1.67157 16.084 2.5 16.084H9.5C10.3284 16.084 11 15.4124 11 14.584V9.58398C11 8.75556 10.3284 8.08398 9.5 8.08398H8H2.5Z"
      fill="#404040"
    />
  </svg>
);

const AccountIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4853 3.51471C18.2188 1.24823 15.2053 0 12 0C8.79474 0 5.78119 1.24823 3.51471 3.51471C1.24823 5.78119 0 8.79474 0 12C0 15.2053 1.24823 18.2188 3.51471 20.4853C5.78119 22.7518 8.79474 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79474 22.7518 5.78119 20.4853 3.51471ZM5.20807 20.1233C5.60431 16.7139 8.53564 14.0815 12 14.0815C13.8263 14.0815 15.5436 14.7931 16.8354 16.0847C17.9268 17.1762 18.6151 18.6013 18.7921 20.1231C16.9519 21.6643 14.5825 22.5938 12 22.5938C9.41748 22.5938 7.04828 21.6645 5.20807 20.1233ZM12 12.6332C9.99042 12.6332 8.35529 10.998 8.35529 8.98846C8.35529 6.9787 9.99042 5.34375 12 5.34375C14.0096 5.34375 15.6447 6.9787 15.6447 8.98846C15.6447 10.998 14.0096 12.6332 12 12.6332ZM20.0024 18.9344C19.6441 17.4897 18.8969 16.1576 17.8297 15.0905C16.9655 14.2262 15.943 13.5778 14.8279 13.1715C16.1684 12.2624 17.051 10.7265 17.051 8.98846C17.051 6.20343 14.785 3.9375 12 3.9375C9.21497 3.9375 6.94904 6.20343 6.94904 8.98846C6.94904 10.7274 7.83234 12.2639 9.17395 13.1728C8.14801 13.5467 7.19861 14.1248 6.38177 14.8857C5.19891 15.9873 4.3775 17.3919 3.99664 18.9333C2.38367 17.0739 1.40625 14.649 1.40625 12C1.40625 6.15857 6.15857 1.40625 12 1.40625C17.8414 1.40625 22.5938 6.15857 22.5938 12C22.5938 14.6495 21.616 17.075 20.0024 18.9344Z"
      fill="#404040"
    />
  </svg>
);

const CreateIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 15.1905L11 6.80957" stroke="#00074F" strokeWidth="2" strokeLinecap="round" />
    <path d="M6.80948 11L15.1904 11" stroke="#00074F" strokeWidth="2" strokeLinecap="round" />
    <circle cx="11" cy="11" r="10" stroke="#00074F" strokeWidth="2" />
  </svg>
);

export {
  AddImgIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  AvatarIcon,
  AvatarMultiIcon,
  BriefcaseIcon,
  BroadcastIcon,
  DotIcon,
  EditIcon,
  EllipsisIcon,
  FiltersIcon,
  MailIcon,
  PinIcon,
  PhoneIcon,
  SortMenuIcon,
  StatusIcon,
  TrashIcon,
  LinkedinIcon,
  MediumIcon,
  TwitterIcon,
  PencilIcon,
  LockIcon,
  UnlockIcon,
  AccountIcon,
  CreateIcon,
  CameraIcon
};
