import { Company } from 'types/company';

export const parseCompanyData = (company: Company | null) => {
  const { locations, phone, yearFounded, size, ...rest } = company ?? {};

  return {
    ...rest,
    employeeSize: size?.value,
    locationText: locations && [locations[0].city, locations[0].country].join(', '),
    foundedYear: yearFounded && new Date(yearFounded).getFullYear(),
    phoneNumber: phone && [phone.dialCode, phone.number].join(' ')
  };
};

export const isBase64 = (str: string) => {
  if (str === '' || str.trim() === '') {
    return false;
  }
  try {
    return window.btoa(window.atob(str)) === str;
  } catch (err) {
    return false;
  }
};
