import React from 'react';
import { t } from 'i18next';

import { EditableControlValue } from '@brightsource/brightsource-ui-lib';
import { Company } from '../../../../types/company';

interface AboutTabProps {
  company?: Company;
}

function AboutTab({ company }: AboutTabProps) {
  return (
    <div className="settings-card-tab">
      <div className="settings-card-tab-inner">
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.about.name', 'Company name')}
            value={company?.name || ''}
            onEdit={() => console.log('edit company name')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.about.founded', 'Year Founded')}
            value={company?.yearFounded ? new Date(company.yearFounded).getFullYear() : ''}
            onEdit={() => console.log('edit company Year Founded')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.about.size', 'Organization size')}
            value={company?.size?.value || ''}
            onEdit={() => console.log('edit company Organization size')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.about.industries', 'Industries')}
            value={
              company?.industries?.length
                ? company.industries
                    .map((industry, index) => {
                      return `${industry.value}${
                        index !== company.industries!.length - 1 ? ', ' : '.'
                      }`;
                    })
                    .join('')
                : ''
            }
            onEdit={() => console.log('edit Industries')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.about.tags', 'Tags')}
            value={
              company?.tags
                ? company.tags
                    .map((tag, index) => {
                      return `${tag.value}${index !== company.tags!.length - 1 ? ', ' : '.'}`;
                    })
                    .join('')
                : ''
            }
            onEdit={() => console.log('edit Tags')}
          />
        </div>
        <div className="row">
          <EditableControlValue
            label={t('settings.company.tabs.about.description', 'Description')}
            value={company?.description || ''}
            onEdit={() => console.log('edit Description')}
          />
        </div>
      </div>
    </div>
  );
}

export default AboutTab;
