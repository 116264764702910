import { Button } from '@blueprintjs/core';
import cn from 'classnames';
import { PencilIcon } from 'components/svg';
import { ReactNode, useState } from 'react';

import './postJobEditable.scss';

export interface EditableContainerProps {
  label?: string;
  onSave: () => void;
  onCancel: () => void;
  renderContent: (isEditMode: boolean) => ReactNode;
}

function EditableContainer({ label, onSave, onCancel, renderContent }: EditableContainerProps) {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleCancelClick = () => {
    onCancel();
    setIsEditMode(false);
  };

  const handleSaveClick = () => {
    onSave();
    setIsEditMode(false);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  return (
    <div className={cn('post-job-editable', { 'edit-mode': isEditMode })}>
      {Boolean(label) && <label className="editable-label">{label}</label>}

      {renderContent(isEditMode)}

      {!isEditMode && (
        <Button className="editable-edit" minimal icon={<PencilIcon />} onClick={handleEditClick} />
      )}

      {isEditMode && (
        <div className="editable-action">
          <Button className="editable-cancel" minimal onClick={handleCancelClick}>
            Cancel
          </Button>

          <Button className="editable-save" minimal onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

export default EditableContainer;
