import { yupResolver } from '@hookform/resolvers/yup';
import { AutoComplete } from 'components/common';
import { InputField, TextAreaField } from 'components/form';
import { RESUME_CHANNEL_LIST } from 'constants/postJob';
import { usePostJob } from 'hooks/usePostJob';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { PostJobData, PostJobResumeChannel, PostJobStep } from 'types/post-job';
import { formSchema } from 'utils/validation';
import * as yup from 'yup';

import './postJobDescription.scss';

export function PostJobDescription() {
  const navigate = useNavigate();
  const [channel, setChannel] = useState<PostJobResumeChannel>(PostJobResumeChannel.EMAIL);
  const { jobData, updateJobData, updateStepIndex } = usePostJob();

  const isEmailChannel = useMemo(() => channel === PostJobResumeChannel.EMAIL, [channel]);

  const schema = yup.object().shape({
    description: yup.string().required('Missing job description'),
    resumeChannel: yup.object().shape({
      value: isEmailChannel ? formSchema.email : formSchema.phoneNumber
    })
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitted },
    resetField
  } = useForm<PostJobData>({
    defaultValues: jobData ?? undefined,
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    resetField('resumeChannel.value');
  }, [channel, resetField]);

  const handleFormSubmit = (payload: PostJobData) => {
    updateJobData({
      ...payload,
      resumeChannel: { ...payload.resumeChannel, channel }
    });

    updateStepIndex(PostJobStep.JOB_REVIEW);
  };

  return (
    <form
      id="postJobDescription"
      className="job-description-form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <TextAreaField
        name="description"
        label="Job Description"
        placeholder="Write a detailed and specific description that will sound attractive to the right potential candidates"
        control={control}
        rows={6}
      />

      <div>
        <label>How would you like to receiver your applicants</label>

        <div className="resume-channel">
          <AutoComplete
            list={RESUME_CHANNEL_LIST}
            selectedItem={RESUME_CHANNEL_LIST.find((field) => field.value === channel) ?? null}
            updateSelectedItem={(item) => item && setChannel(item.value as PostJobResumeChannel)}
            disabledSearch
          />

          <InputField
            type={isEmailChannel ? 'email' : 'number'}
            name="resumeChannel.value"
            control={control}
            placeholder={isEmailChannel ? 'Enter email' : 'Enter phone number'}
          />
        </div>
      </div>
    </form>
  );
}
